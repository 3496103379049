import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Form from '@rjsf/material-ui'
import classNames from 'classnames'

import { Box, Button, Paper, LinearProgress, Typography } from '@material-ui/core'

import apiService from '../../services/api'

import BreadcrumbGen from '../../components/Common/BreadcrumbGen'
import { schema, uiSchema } from '../../models/projects'

import widgets from '../../models/customWidgets'

import { fetchProjects } from '../../actions/projectActions'
import { fetchClients } from '../../actions/clientActions'
import { successNotification, errorNotification } from '../../actions/notificationActions'

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

const ProjectsForm = ({ classes, match, history }) => {
  const dispatch = useDispatch()
  const [projectData, setProjectData] = useState(null)

  const [finished, setFinished] = useState(false)
  const projectId = match.params.id

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const result = await apiService.get(null, `projects/${id}`)
        const projectData = result.data[0]

        if (projectData.client && projectData.client.name) {
          projectData.client = projectData.client.name
        }

        if (projectData.projectManager && projectData.projectManager.name) {
          projectData.projectManager = projectData.projectManager.name
        }

        setProjectData(projectData)
      } catch (error) {
        return history.push('/admin/projects')
      }
    }

    if (projectId) {
      fetchData(projectId)
    } else {
      return history.push('/admin/projects')
    }
  }, [projectId, history])

  const handleSubmit = async ({ formData }) => {
    // Only allow editing the key!
    const data = {
      key: formData.key,
    }

    try {
      await apiService.update(match.params.id, data, 'projects')
      dispatch(successNotification(`Updated projectData: ${formData.name}!`))
      dispatch(fetchProjects())

      // Hack-y, but just in case we update the client assigned to the project
      dispatch(fetchClients())

      setFinished(true)
    } catch (e) {
      dispatch(errorNotification(`There was a problem updating.`))
      console.error(e)
    }
  }

  const resourcePaths = [
    { name: 'Home', route: '/' },
    { name: 'Projects', route: '/admin/projects' },
    {
      name: `${match && match.params && match.params.id ? 'Edit projects: ' + match.params.id : 'Create'}`,
    },
  ]

  return (
    <>
      <BreadcrumbGen paths={resourcePaths} />

      <Paper className={classNames(classes.paper, 'projects__formContainer')}>
        <Typography variant="h5" gutterBottom className={classNames(classes.title, 'projects__formTitle')}>
          Edit Project
        </Typography>
        {finished && <Redirect to="/admin/projects" />}
        {schema && projectData ? (
          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={projectData}
            onSubmit={handleSubmit}
            widgets={widgets}
            className="projects__form"
          >
            <Box mt={2} className="projects__formActions">
              <Button
                variant="contained"
                color="default"
                className={classNames(classes.cancelButton, 'projects__formCancel')}
                onClick={() => setFinished(true)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit" className="projects__formSubmit">
                Submit
              </Button>
            </Box>
          </Form>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </>
  )
}

export default withRouter(withStyles(styles)(withSnackbar(ProjectsForm)))
