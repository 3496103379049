import {
  FETCH_RESOURCE_PERFORMANCE_BEGIN,
  FETCH_RESOURCE_PERFORMANCE_SUCCESS,
  FETCH_RESOURCE_PERFORMANCE_FAILURE,
  CREATE_RESOURCE_PERFORMANCE_BEGIN,
  CREATE_RESOURCE_PERFORMANCE_SUCCESS,
  CREATE_RESOURCE_PERFORMANCE_FAILURE,
} from '../../actions/resourcePerformanceActions'

import initialState from './initialState'

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESOURCE_PERFORMANCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_RESOURCE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.resourcePerformance,
      }

    case FETCH_RESOURCE_PERFORMANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }
    case CREATE_RESOURCE_PERFORMANCE_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case CREATE_RESOURCE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.payload.resourcePerformance],
      }

    case CREATE_RESOURCE_PERFORMANCE_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
