import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Form from '@rjsf/material-ui'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DialogContent from '@material-ui/core/DialogContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'

import Can from '../../components/Common/Can'
import Projects from '../../components/Projects'

import utils from '../../utils'

import { fetchProjects } from '../../actions/projectActions'
import { fetchMetricTypes } from '../../actions/metricTypesActions'

import widgets from '../../models/customWidgets'
import { createProjectMetric } from '../../actions/projectMetricsActions'

const styles = (theme) => ({
  dialogTitle: {
    paddingBottom: 0,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  centerActionButtons: {
    textAlign: 'center',
    paddingRight: 10,
  },
  formActions: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: 10,
  },
  container: {
    height: 'calc(100% - 65px)',
  },
})

const ProjectStatusReport = ({ classes }) => {
  const [project, setProject] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)

  const dispatch = useDispatch()
  const projects = useSelector((state) => state.projects.items.filter((p) => p.status === 'ACTIVE'))
  const projectsLoading = useSelector((state) => state.projects.loading)

  const metricTypes = useSelector((state) =>
    state.metricTypes.items.filter((mt) => mt.reference === 'project').sort(utils.sortProjectMetrics)
  )
  const metricTypesLoading = useSelector((state) => state.metricTypes.loading)

  const currentUser = useSelector((state) => state.currentUser)

  const schemaItems = metricTypes.reduce((acc, def) => {
    acc[def.id] = {
      type: 'string',
      title: def.name,
      default: '',
      order: def.order,
    }

    if (def.enum && def.enum.length) {
      acc[def.id].enum = def.enum
    }

    return acc
  }, {})

  const schema = {
    type: 'object',
    required: Object.keys(schemaItems),
    properties: {
      ...schemaItems,
    },
  }

  const uiSchema = metricTypes.reduce((acc, def) => {
    if (def.enum) {
      acc[def.id] = {
        'ui:widget': 'customButtonGroup',
      }
    }

    if (def.type === 'text') {
      acc[def.id] = {
        'ui:widget': 'customTextfield',
      }
    }

    return acc
  }, {})

  const orderedMetricTypes = metricTypes.sort((a, b) => {
    if (a.name < b.name) return 1
    if (a.name > b.name) return -1
    return 0
  })

  const orderedFields = orderedMetricTypes.reduce((acc, def) => {
    if (def.enum) {
      acc.unshift(def.id)
    } else {
      acc.push(def.id)
    }

    return acc
  }, [])

  uiSchema['ui:order'] = orderedFields

  useEffect(() => {
    if (!projects.length && !projectsLoading) {
      dispatch(fetchProjects())
    }
  }, [dispatch, projects.length, projectsLoading])

  useEffect(() => {
    if (!metricTypes.length && !metricTypesLoading) {
      dispatch(fetchMetricTypes('project'))
    }
  }, [dispatch, metricTypes.length, metricTypesLoading])

  const openDialog = (projectId) => {
    const filteredProject = projects.find((project) => project.id === projectId)

    setDialogOpen(true)
    setProject(filteredProject)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = ({ formData }) => {
    dispatch(createProjectMetric(formData, project))
    handleClose()
  }

  const transformErrors = (errors) => {
    const uniqueErrors = _.uniqBy(errors, 'property')
    return uniqueErrors.map((error) => {
      error.message = 'This field is required'
      return error
    })
  }

  return (
    <div className={classes.container}>
      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="project-status-dialog-title">
        <DialogTitle id="project-status-dialog-title" className={classes.dialogTitle}>
          Status report for: {project.name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            noHtml5Validate
            showErrorList={false}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            widgets={widgets}
            transformErrors={transformErrors}
          >
            <Box mt={2} className={classes.formActions}>
              <Button variant="contained" color="default" className={classes.cancelButton} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Form>
        </DialogContent>
      </Dialog>

      {!metricTypesLoading && !projectsLoading ? (
        <Projects
          title="Create Project Status Report"
          projects={projects}
          options={{
            pagination: false,
          }}
          hideFilters={{ status: true }}
          actionButtons={(projectId) => (
            <div className={classes.centerActionButtons}>
              <Can
                role={currentUser.role_name}
                perform="project-status:create"
                yes={() => (
                  <IconButton
                    className={classes.button}
                    aria-label="Add Project Report"
                    onClick={() => {
                      openDialog(projectId)
                    }}
                  >
                    <NoteAddIcon />
                  </IconButton>
                )}
                no={() => '-'}
              />
            </div>
          )}
        />
      ) : (
        <LinearProgress className={classes.progress} />
      )}
    </div>
  )
}

export default withStyles(styles)(withSnackbar(ProjectStatusReport))
