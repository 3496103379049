import _ from 'lodash'
import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'

import { Link, LinearProgress, Typography } from '@material-ui/core'

const exportHeaders = [
  {
    name: 'Name',
    download: true,
  },
  {
    name: 'accountId',
    download: true,
  },
  {
    name: 'username',
    download: true,
  },
  {
    name: 'email',
    download: true,
  },
  {
    name: 'Contract',
    download: true,
  },
  {
    name: 'ICA End Date',
    download: true,
  },
  {
    name: 'Status',
    download: true,
  },
  {
    name: 'Role Name',
    download: true,
  },
  {
    name: 'City',
    download: true,
  },
  {
    name: 'Country',
    download: true,
  },
]

const Resources = React.memo(
  ({ data: resources, actionButtons, title }) => {
    const options = {
      filterType: false,
      selectableRows: 'none',
      pagination: true,
      print: false,
      tableBodyHeight: 'calc(100vh - 220px)',
      viewColumns: true,
      downloadOptions: {
        filename: `resources-${moment().utc().format()}.csv`,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataToExport = data.map((d, index) => ({
          index,
          data: [
            d.data[4] || '', // Display Name
            d.data[1] || '', // Account ID
            d.data[3] || '', // User name
            d.data[2] || '', // Email
            d.data[5] || '', // Contract
            d.data[6] || '', // ICA
            (d.data[7] ? 'ACTIVE' : 'INACTIVE') || '', // status
            d.data[8] || '', // Role
            d.data[9] || '', // City
            d.data[10] || '', // Country
          ],
        }))

        return buildHead(exportHeaders) + buildBody(dataToExport)
      },
    }

    const columns = [
      {
        name: 'id',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: 'accountId',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: 'email',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: 'username',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        label: 'Name',
        name: 'fullName',
        options: {
          filter: false,
          sort: true,
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex) => {
            const val = resources[dataIndex]
            return (
              <Typography>
                <Link href={`mailto:${val.email}`} target="_blank" rel="noreferrer">
                  {val.fullName}
                </Link>
              </Typography>
            )
          },
        },
      },
      {
        label: 'Contract',
        name: 'type',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'ICA End Date',
        name: 'ica',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        label: 'Status',
        name: 'active',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value ? 'ACTIVE' : 'INACTIVE'),
        },
      },
      {
        label: 'Role Name',
        name: 'role',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        label: 'City',
        name: 'city',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Country',
        name: 'country',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Edit',
        name: 'id',
        options: {
          filter: false,
          sort: false,
          empty: true,
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const resourceId = tableMeta.rowData[0] ? tableMeta.rowData[0] : ''
            return actionButtons(resourceId)
          },
        },
      },
    ]

    return (
      <div className="resources">
        {resources && resources.length ? (
          <MUIDataTable
            title={title || 'Truepers List'}
            data={resources}
            columns={columns}
            options={options}
            className="resources__chart"
          />
        ) : (
          <LinearProgress />
        )}
      </div>
    )
  },
  (prev, next) => _.isEqual(prev.data, next.data)
)

export default Resources
