import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  icon: {
    fontSize: '52px',
  },
})

const InfoCard = ({ classes, icon, title, content, className }) => {
  return (
    <Grid container alignItems="center" className={className}>
      <Grid item xs={3}>
        {icon(classes.icon)}
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body2" component="h3" align="right">
          {title}
        </Typography>

        <Typography variant="h3" component="h3" align="right">
          {content}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(InfoCard)
