import { createMuiTheme } from '@material-ui/core/styles'

// This is the definition for the overall ref.
// The enum colors defined are the equivalent of ["Red", "Yellow", "Green"]
export const OVERALL_METRIC_DEFINITION = {
  name: 'Overall',
  type: 'colors_enum',
  enum: ['#FF0000', '#FFCC00', '#7ECC0A'],
}

const defaultTheme = createMuiTheme()

export const baseTheme = {
  overrides: {
    MUIDataTable: {
      tableRoot: {
        [defaultTheme.breakpoints.down('sm')]: {
          minWidth: 600,
          overflow: 'auto',
        },
      },
      responsiveScroll: {
        height: '100%',
        maxHeight: 'auto',
      },
      responsiveScrollFullHeight: {
        height: 'calc(100% - 145px)',
        [defaultTheme.breakpoints.up('sm')]: {
          height: 'calc(100% - 130px)',
        },
        overflow: 'auto',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        '&:last-child': {
          '& span': {
            justifyContent: 'center',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      left: {
        flex: 'unset',
      },
      root: {
        flexFlow: 'row wrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      titleRoot: {
        textAlign: 'left',
      },
    },
    MUIDataTablePagination: {
      root: {
        padding: 0,
        '&:last-child': {
          padding: 0,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          padding: '0px 24px 0px 24px',
        },
      },
      toolbar: {
        paddingRight: '40px',
      },
    },
    MuiTablePagination: {
      select: {
        paddingLeft: 0,
      },
      actions: {
        [defaultTheme.breakpoints.down('sm')]: {
          marginLeft: 10,
          '& button': {
            paddingLeft: 0,
          },
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        padding: '4px 8px 4px 18px',
      },
    },
    MuiTableCell: {
      head: {
        maxWidth: 200,
      },
      body: {
        fontSize: '0.8125rem',
        maxWidth: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },
}

export const customTableTheme = createMuiTheme(baseTheme)
