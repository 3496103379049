import React from 'react'
import logo from '../../assets/tools-logo.png'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  img: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  logoContainer: {
    width: '100%',
  },
})

const Logo = ({ classes }) => (
  <span className={classes.logoContainer}>
    <img className={classes.img} src={logo} alt="Ttruelogic Tools" />
  </span>
)

export default withStyles(styles)(Logo)
