import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { LinearProgress, Paper } from '@material-ui/core'

import ProjectStatus from '../../components/ProjectStatus'
import { fetchProjectMetrics } from '../../actions/projectMetricsActions'

const ProjectStatusContainer = () => {
  const dispatch = useDispatch()

  const projectMetrics = useSelector((state) => state.projectMetrics.items.sort((a, b) => b.createdAt - a.createdAt))
  const projectMetricsLoading = useSelector((state) => state.projectMetrics.loading)

  useEffect(() => {
    dispatch(fetchProjectMetrics())
  }, [dispatch])

  useEffect(() => {
    document.title = 'Project Status Details'
  })

  return (
    <Paper className={classNames('projectStatus', projectMetricsLoading && 'projectStatus--loading')}>
      {projectMetricsLoading ? (
        <LinearProgress />
      ) : (
        <ProjectStatus projectMetrics={projectMetrics} title="Project Status Details" />
      )}
    </Paper>
  )
}

export default ProjectStatusContainer
