import { errorNotification, successNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_PROJECT_METRICS_BEGIN = 'FETCH_PROJECT_METRICS_BEGIN'
export const FETCH_PROJECT_METRICS_SUCCESS = 'FETCH_PROJECT_METRICS_SUCCESS'
export const FETCH_PROJECT_METRICS_FAILURE = 'FETCH_PROJECT_METRICS_FAILURE'

export const CREATE_PROJECT_METRIC_BEGIN = 'CREATE_PROJECT_METRIC_BEGIN'
export const CREATE_PROJECT_METRIC_SUCCESS = 'CREATE_PROJECT_METRIC_SUCCESS'
export const CREATE_PROJECT_METRIC_FAILURE = 'CREATE_PROJECT_METRIC_FAILURE'

export const fetchProjectMetricsBegin = () => ({
  type: FETCH_PROJECT_METRICS_BEGIN,
})

export const fetchProjectMetricsSuccess = (projectMetrics) => ({
  type: FETCH_PROJECT_METRICS_SUCCESS,
  payload: { projectMetrics },
})

export const fetchProjectMetricsFailure = (error) => ({
  type: FETCH_PROJECT_METRICS_FAILURE,
  payload: { error },
})

export function fetchProjectMetrics(options = {}) {
  return (dispatch) => {
    dispatch(fetchProjectMetricsBegin())
    return apiService
      .get(null, 'projects/metrics', options)
      .then((res) => {
        dispatch(fetchProjectMetricsSuccess(res.data))
        return res.data
      })
      .catch((error) => {
        dispatch(errorNotification('There was an error retrieving project metrics.'))
        dispatch(fetchProjectMetricsFailure(error))
      })
  }
}

export const createProjectMetricBegin = () => ({
  type: CREATE_PROJECT_METRIC_BEGIN,
})

export const createProjectMetricSuccess = (projectMetric) => ({
  type: CREATE_PROJECT_METRIC_SUCCESS,
  payload: { projectMetric },
})

export const createProjectMetricFailure = (error) => ({
  type: CREATE_PROJECT_METRIC_FAILURE,
  payload: { error },
})

export function createProjectMetric(formData, project) {
  return (dispatch) => {
    dispatch(createProjectMetricBegin())
    return apiService
      .create(formData, `projects/${project.id}/metrics`)
      .then((res) => {
        dispatch(createProjectMetricSuccess(res.data))
        dispatch(successNotification(`Report submitted!`))
        return res
      })
      .catch((error) => {
        dispatch(createProjectMetricFailure(error))
        dispatch(errorNotification(`There was a problem saving this report!`))
        console.error(error)
      })
  }
}
