export const schema = {
  type: 'object',
  required: ['id', 'name', 'email', 'role_name'],
  properties: {
    id: { type: 'string', title: 'User Id', default: '', readOnly: true },
    name: { type: 'string', title: 'Name', default: '' },
    email: { type: 'string', title: 'Email', default: '', readOnly: true },
    role_name: { type: 'string', title: 'Role', enum: [] },
  },
}

export const uiSchema = {}

export const initialState = {
  id: '',
  name: '',
  email: '',
  role_name: '',
}
