import apiService from '../services/api'

export const FETCH_PLANNING_BEGIN = 'FETCH_PLANNING_BEGIN'
export const FETCH_PLANNING_SUCCESS = 'FETCH_PLANNING_SUCCESS'
export const FETCH_PLANNING_FAILURE = 'FETCH_PLANNING_FAILURE'

export const fetchPlanningBegin = () => ({
  type: FETCH_PLANNING_BEGIN,
})

export const fetchPlanningSuccess = planning => ({
  type: FETCH_PLANNING_SUCCESS,
  payload: { planning },
})

export const fetchPlanningFailure = error => ({
  type: FETCH_PLANNING_FAILURE,
  payload: { error },
})

export function fetchPlanning(year) {
  return dispatch => {
    dispatch(fetchPlanningBegin())
    return apiService
      .get(null, 'planning/report', { year })
      .then(res => {
        dispatch(fetchPlanningSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchPlanningFailure(error)))
  }
}
