import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { LinearProgress, Paper } from '@material-ui/core'

import ResourcePerformance from '../../components/ResourcePerformance'
import { fetchResourcePerformance } from '../../actions/resourcePerformanceActions'

const ResourcePerformanceContainer = ({ classes }) => {
  const dispatch = useDispatch()

  const resourcePerformance = useSelector((state) =>
    state.resourcePerformance.items.sort((a, b) => b.createdAt - a.createdAt)
  )
  const resourcePerformanceLoading = useSelector((state) => state.resourcePerformance.loading)

  useEffect(() => {
    dispatch(fetchResourcePerformance())
  }, [dispatch])

  useEffect(() => {
    document.title = 'Resource Performance'
  })

  return (
    <Paper className={classNames('resourcePerformance', resourcePerformanceLoading && 'resourcePerformance--loading')}>
      {resourcePerformanceLoading ? (
        <LinearProgress />
      ) : (
        <ResourcePerformance resourcePerformance={resourcePerformance} title="Resource Performance" />
      )}
    </Paper>
  )
}

export default ResourcePerformanceContainer
