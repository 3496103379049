export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION'
export const NOTIFICATION_SENT = 'NOTIFICATION_SENT'

export const notificationSent = key => ({
  type: NOTIFICATION_SENT,
  payload: key,
})

export const enqueueNotification = notification => {
  const key = notification.options && notification.options.key

  return {
    type: ENQUEUE_NOTIFICATION,
    payload: {
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    },
  }
}

export const successNotification = message =>
  enqueueNotification({
    message,
    options: {
      variant: 'success',
    },
  })

export const errorNotification = message =>
  enqueueNotification({
    message,
    options: {
      variant: 'error',
    },
  })
