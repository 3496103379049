import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { LinearProgress, Paper } from '@material-ui/core'

import Audits from '../../components/Audits'

import { fetchAudits } from '../../actions/auditActions'

const AuditsContainer = ({ classes }) => {
  const dispatch = useDispatch()
  const audits = useSelector((state) => state.audits.items)
  const auditsLoading = useSelector((state) => state.audits.loading)

  useEffect(() => {
    document.title = 'Audits'
  }, [])

  useEffect(() => {
    dispatch(fetchAudits())
  }, [dispatch])

  return (
    <Paper className={classNames('audits', auditsLoading && 'audits--loading')}>
      {auditsLoading ? <LinearProgress /> : <Audits data={audits} />}
    </Paper>
  )
}

export default AuditsContainer
