import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import classNames from 'classnames'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Paper, Table, TableHead, TableBody, TableFooter, TableRow, TableCell, Typography } from '@material-ui/core'

const styles = () => ({
  extraPlanInfoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  expandedRowPaper: {
    margin: 15,
  },
  fullHeight: {
    height: '100%',
  },
})

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableToolbar: {
        titleText: {
          textAlign: 'left',
        },
      },
      MuiTableCell: {
        body: {
          '&:nth-child(8)': {
            maxWidth: 300,
          },
        },
      },
    },
  })

const Allocation = React.memo(
  ({ resourceData, classes }) => {
    const [planStatuses, setPlanStatuses] = useState(null)
    const columns = [
      {
        name: 'plans',
        options: {
          filter: false,
          display: false,
          download: false,
        },
      },
      {
        label: 'Full Name',
        name: 'displayName',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Contract',
        name: 'type',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'ICA end Date',
        name: 'ica',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        label: 'SOW End Date',
        name: 'sow',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        label: 'Client',
        name: 'client',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Resoure Status',
        name: 'active',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value ? 'ACTIVE' : 'INACTIVE'),
        },
      },
      {
        label: 'Role Name(s)',
        name: 'role',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        label: 'City',
        name: 'city',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        label: 'Country',
        name: 'country',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        name: 'username',
        options: {
          filter: false,
          display: false,
          download: false,
        },
      },
    ]

    // TODO: Can't we just use the table columns and add the download attr?
    const exportHeaders = [
      {
        name: 'Full Name',
        download: true,
      },
      {
        name: 'Contract',
        download: true,
      },
      {
        name: 'ICA End Date',
        download: true,
      },
      {
        name: 'SOW End Date',
        download: true,
      },
      {
        name: 'Status',
        download: true,
      },
      {
        name: 'Role Name(s)',
        download: true,
      },
      {
        name: 'City',
        download: true,
      },
      {
        name: 'Country',
        download: true,
      },
    ]

    useEffect(() => {
      const plans = resourceData.map((r) => r.plans).flat()
      const planStatuses = plans.reduce((acc, item) => {
        let status = item.plan_status
        if (status === null) {
          status = 'INVALID'
        }

        if (typeof acc[status] === 'undefined') {
          acc[status] = 0
        } else {
          acc[status]++
        }

        return acc
      }, {})

      setPlanStatuses(planStatuses)
    }, [resourceData])

    const options = {
      filterType: false,
      selectableRows: 'none',
      print: false,
      pagination: false,
      tableBodyHeight: 'calc(100vh - 160px)',
      viewColumns: false,
      expandableRows: true,
      // eslint-disable-next-line react/display-name
      customFooter: (count) => {
        return (
          <TableFooter>
            <TableRow>
              <TableCell>
                <Typography variant="body2">
                  <b>Resource count:</b> {count}
                </Typography>
              </TableCell>
              {planStatuses &&
                Object.keys(planStatuses).map((key) => {
                  if (planStatuses[key] > 0) {
                    return (
                      <TableCell key={key}>
                        <Typography variant="body2">
                          <b>{key} Plans:</b> {planStatuses[key]}
                        </Typography>
                      </TableCell>
                    )
                  }

                  return null
                })}
            </TableRow>
          </TableFooter>
        )
      },
      // eslint-disable-next-line react/display-name
      renderExpandableRow: (rowData, rowMeta) => {
        const resourcePlanData = resourceData[rowMeta.dataIndex]

        return (
          <TableRow>
            <TableCell colSpan={columns.length}>
              {resourcePlanData.plans && resourcePlanData.plans.length ? (
                <Paper className={classes.expandedRowPaper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Plan Key</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Task Status</TableCell>
                        <TableCell>Plan Start</TableCell>
                        <TableCell>Plan End</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resourcePlanData.plans.map((item) => (
                        <TableRow key={item.plan_id}>
                          <TableCell>{item.plan_key || '-'}</TableCell>
                          <TableCell>{(item.project && item.project.project_name) || '-'}</TableCell>
                          <TableCell>{(item.client && item.client.client_name) || '-'}</TableCell>
                          <TableCell>{item.plan_status || '-'}</TableCell>
                          <TableCell>{moment(item.plan_start_date).utc().format('YYYY-MM-DD')}</TableCell>
                          <TableCell>{moment(item.plan_end_date).utc().format('YYYY-MM-DD')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <Typography>No additional plan data</Typography>
              )}
            </TableCell>
          </TableRow>
        )
      },
      downloadOptions: {
        filename: `resource-allocation-${moment().utc().format('YYYY-MM-DD')}.csv`,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataToExport = []
        let index = 0

        data.forEach((dataI) => {
          const dataItem = dataI.data

          const [
            plans,
            fullName,
            contract,
            ica,
            sow,
            ,
            status, // Above empty is "client" which we dont use
            role,
            city,
            country,
            username,
          ] = dataItem

          dataToExport.push({
            index: ++index,
            data: ['', fullName, contract, ica, sow, status ? 'ACTIVE' : 'INACTIVE', role, city, country],
          })

          if (plans.length) {
            plans.forEach((plan) => {
              dataToExport.push({
                index: ++index,
                data: [
                  '',
                  username || 'Invalid',
                  plan.plan_key,
                  plan.project.project_name,
                  plan.client.client_name,
                  plan.plan_status,
                  moment(plan.plan_start_date).utc().format('YYYY-MM-DD'),
                  moment(plan.plan_end_date).utc().format('YYYY-MM-DD'),
                ],
              })
            })
          }
        })

        return buildHead(exportHeaders) + buildBody(dataToExport)
      },
    }

    return (
      <div className={classNames(classes.fullHeight, 'resourceAllocation__content')}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={resourceData}
            columns={columns}
            options={options}
            title="Truepers Allocation Report"
            className="resourceAllocation__chart"
          />
        </MuiThemeProvider>
      </div>
    )
  },
  (prev, next) => _.isEqual(prev.resourceData, next.resourceData)
)

export default withStyles(styles)(Allocation)
