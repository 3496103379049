import _ from 'lodash'
import React from 'react'

import Typography from '@material-ui/core/Typography'

//TODO: DRY this
const PlannedHoursTooltipComponent = ({ slice }) => {
  const pointSuffix = (id) => (id === 'utilization' || id === 'overhead' ? '%' : 'hs')

  const loggedData = slice.points.find((s) => s.id.includes('logged'))
  const utilizationData = slice.points.find((s) => s.id.includes('utilization'))
  const overheadData = slice.points.find((s) => s.id.includes('overhead'))

  if (loggedData) {
    if (loggedData.data.utilization && !utilizationData) {
      const utilization = {
        ...loggedData,
        id: 'utilization.0',
        serieId: 'utilization',
        serieColor: '#37474f',
        ...(loggedData.data
          ? {
              data: {
                ...loggedData.data,
                total: loggedData.data.utilization,
                yFormatted: loggedData.data.utilization,
              },
            }
          : {}),
      }

      slice.points.push(utilization)
    }

    if (loggedData.data.overhead && !overheadData) {
      const overhead = {
        ...loggedData,
        id: 'overhead.0',
        serieId: 'overhead',
        serieColor: '#37474f',
        ...(loggedData.data
          ? {
              data: {
                ...loggedData.data,
                total: loggedData.data.overhead,
                yFormatted: loggedData.data.overhead,
              },
            }
          : {}),
      }

      slice.points.push(overhead)
    }
  }

  return (
    <div
      key={slice.x}
      style={{
        background: '#fff',
        padding: '9px 12px',
        border: '1px solid #ccc',
        position: 'absolute',
        top: -75,
        left: -75,
        width: 130,
        textAlign: 'center',
      }}
    >
      <Typography variant="h6">{slice.points[0].data.month}</Typography>
      {slice.points.map((point) => (
        <div
          key={point.id}
          style={{
            padding: '3px 0',
          }}
        >
          <Typography variant="subtitle2">
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                color: point.serieColor,
                textTransform: 'capitalize',
                width: '100%',
              }}
            >
              {point.serieId}
            </span>
            {point.data.yFormatted}
            {pointSuffix(point.serieId)}
          </Typography>
        </div>
      ))}
    </div>
  )
}

const PlannedHoursTooltip = React.memo(PlannedHoursTooltipComponent, (prev, next) =>
  _.isEqual(prev.slice.points, next.slice.points)
)

export default PlannedHoursTooltip
