import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LinearProgress from '@material-ui/core/LinearProgress'
import ClientsIcon from '../../assets/icons/clients.svg'

import { fetchClients } from '../../actions/clientActions'
import InfoCard from '../Cards/InfoCard'

const ActiveClients = ({ classes }) => {
  const dispatch = useDispatch()

  const [activeClients, setActiveClients] = useState(0)

  const clients = useSelector((state) => state.clients.items)
  const loading = useSelector((state) => state.clients.loading)

  useEffect(() => {
    const activeClients = clients.filter((p) => p.status === 'ACTIVE')
    setActiveClients(activeClients.length)
  }, [clients])

  useEffect(() => {
    dispatch(fetchClients())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <InfoCard
          icon={() => <img src={ClientsIcon} alt="Clients Icon" style={{ width: 50, height: 50 }} />}
          title="Active Clients"
          content={activeClients}
          className="activeClients"
        />
      )}
    </>
  )
}

export default ActiveClients
