import { errorNotification, successNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_CLIENTS_BEGIN = 'FETCH_CLIENTS_BEGIN'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const FETCH_CLIENT_BEGIN = 'FETCH_CLIENT_BEGIN'
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS'
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE'

export const REMOVE_CLIENT_BEGIN = 'REMOVE_CLIENT_BEGIN'
export const REMOVE_CLIENT_SUCCESS = 'REMOVE_CLIENT_SUCCESS'
export const REMOVE_CLIENT_FAILURE = 'REMOVE_CLIENT_FAILURE'

export const CREATE_CLIENT_BEGIN = 'CREATE_CLIENT_BEGIN'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE'

export const UPDATE_CLIENT_BEGIN = 'UPDATE_CLIENT_BEGIN'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE'

export const REMOVE_PROJECT_CLIENT_BEGIN = 'REMOVE_PROJECT_CLIENT_BEGIN'
export const REMOVE_PROJECT_CLIENT_SUCCESS = 'REMOVE_PROJECT_CLIENT_SUCCESS'
export const REMOVE_PROJECT_CLIENT_FAILURE = 'REMOVE_PROJECT_CLIENT_FAILURE'

// --------------- GET ALL ----------------

export const fetchClientsBegin = () => ({
  type: FETCH_CLIENTS_BEGIN,
})

export const fetchClientsSuccess = clients => ({
  type: FETCH_CLIENTS_SUCCESS,
  payload: { clients },
})

export const fetchClientsFailure = error => ({
  type: FETCH_CLIENTS_FAILURE,
  payload: { error },
})

export function fetchClients() {
  return dispatch => {
    dispatch(fetchClientsBegin())
    return apiService
      .get(null, 'clients')
      .then(res => {
        dispatch(fetchClientsSuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(errorNotification('There was an error fetching Clients'))
        dispatch(fetchClientsFailure(error))
      })
  }
}

// --------------- GET ONE ----------------

export const fetchClientBegin = () => ({
  type: FETCH_CLIENT_BEGIN,
})

export const fetchClientSuccess = client => ({
  type: FETCH_CLIENT_SUCCESS,
  payload: { client },
})

export const fetchClientFailure = error => ({
  type: FETCH_CLIENT_FAILURE,
  payload: { error },
})

export function fetchClient(clientId) {
  return (dispatch, getState) => {
    dispatch(fetchClientBegin())

    const state = getState()
    const client = state.clients.items.filter(c => c.id === clientId)

    if (client.length) {
      return dispatch(fetchClientSuccess(client[0]))
    } else {
      return dispatch(fetchClients()).then(res => {
        const client = res.filter(c => c.id === clientId)

        if (client) {
          dispatch(fetchClientSuccess(client[0]))
        } else {
          dispatch(errorNotification('There was an error fetching the Client'))
          dispatch(fetchClientFailure('There was an error fetching the Client'))
        }
      })
    }
  }
}

// --------------- REMOVE ----------------

export const removeClientBegin = () => ({
  type: REMOVE_CLIENT_BEGIN,
})

export const removeClientSuccess = clientId => ({
  type: REMOVE_CLIENT_SUCCESS,
  payload: clientId,
})

export const removeClientFailure = error => ({
  type: REMOVE_CLIENT_FAILURE,
  payload: { error },
})

export function removeClient(clientId, clientName) {
  return dispatch => {
    dispatch(removeClientBegin())
    return apiService
      .remove(clientId, 'clients')
      .then(res => {
        dispatch(removeClientSuccess(clientId))
        dispatch(successNotification(`Client: "${clientName}" deleted`))
        return res
      })
      .catch(error => {
        dispatch(removeClientFailure(error))
        dispatch(
          errorNotification(
            `There was an error deleting client: "${clientName}"`
          )
        )
      })
  }
}

// --------------- CREATE ----------------

export const createClientBegin = () => ({
  type: CREATE_CLIENT_BEGIN,
})

export const createClientSuccess = client => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: client,
})

export const createClientFailure = error => ({
  type: CREATE_CLIENT_FAILURE,
  payload: { error },
})

export function createClient(data) {
  return dispatch => {
    dispatch(createClientBegin())
    return apiService
      .create(data, 'clients')
      .then(res => {
        dispatch(createClientSuccess(res.data[0]))
        dispatch(successNotification(`Client: "${data.name}" created!`))
        return res
      })
      .catch(error => {
        dispatch(createClientFailure(error))
        dispatch(
          errorNotification(
            `There was an error creating client: "${data.name}"`
          )
        )
      })
  }
}

// --------------- REMOVE CLIENT-PROJECT ----------------

export const removeProjectClientBegin = () => ({
  type: REMOVE_PROJECT_CLIENT_BEGIN,
})

export const removeProjectClientSuccess = projectId => ({
  type: REMOVE_PROJECT_CLIENT_SUCCESS,
  payload: projectId,
})

export const removeProjectClientFailure = error => ({
  type: REMOVE_PROJECT_CLIENT_FAILURE,
  payload: { error },
})

export function removeProjectClient(projectId) {
  return dispatch => {
    dispatch(removeProjectClientBegin())
    return apiService
      .remove(projectId, 'projects/client')
      .then(res => {
        dispatch(removeProjectClientSuccess(projectId))
        dispatch(successNotification(`Removed Project-Client relation!`))
        return res
      })
      .catch(error => {
        dispatch(removeProjectClientFailure(error))
        dispatch(
          errorNotification(
            `There was an error deleting the project-client relation.`
          )
        )
      })
  }
}

// --------------- UPDATE ----------------

export const updateClientBegin = () => ({
  type: UPDATE_CLIENT_BEGIN,
})

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
})

export const updateClientFailure = error => ({
  type: UPDATE_CLIENT_FAILURE,
  payload: { error },
})

export function updateClient(clientId, data) {
  return dispatch => {
    dispatch(updateClientBegin())
    return apiService
      .update(clientId, data, 'clients')
      .then(res => {
        dispatch(updateClientSuccess(res.data[0]))
        dispatch(successNotification(`Client: "${data.name}" updated!`))
        return res
      })
      .catch(error => {
        dispatch(updateClientFailure(error))
        dispatch(
          errorNotification(
            `There was an error updating client: "${data.name}"`
          )
        )
      })
  }
}
