import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { fetchSync } from '../actions/syncActions'

const styles = () => ({
  syncContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  button: {
    color: '#fff',
    border: 'none',
  },
  label: {
    marginLeft: 10,
    fontSize: 12,
  },
})

const SyncStatus = ({ classes }) => {
  const [currentState, setCurrentState] = useState(null)
  const [openSyncDialog, setOpenSyncDialog] = useState(false)

  const dispatch = useDispatch()
  const sync = useSelector((state) => state.sync.items.sort((a, b) => b.createdAt - a.createdAt))

  useEffect(() => {
    if (!sync.length) {
      dispatch(fetchSync())
    }
  }, [dispatch, sync.length])

  useEffect(() => {
    if (sync && sync.length) {
      setCurrentState(sync.shift())
    } else {
      setCurrentState('error')
    }
  }, [sync])

  const handleOpen = () => {
    setOpenSyncDialog(true)
  }

  const handleClose = () => {
    setOpenSyncDialog(false)
  }

  const renderSyncMessage = () => {
    if (currentState) {
      if (currentState === 'error') {
        return (
          <>
            <ErrorIcon />
            <span className={classes.label}>There was an error Syncing!</span>
          </>
        )
      }

      const processedTime = moment(currentState.createdAt)
      const currentTime = moment()

      const duration = moment.duration(currentTime.diff(processedTime))
      const hours = duration.asHours()

      if (hours > 3) {
        return (
          <>
            <ErrorIcon />
            <span className={classes.label}>There was an error Syncing!</span>
          </>
        )
      }

      if (currentState.messages.length) {
        return (
          <>
            <Button variant="outlined" onClick={handleOpen} className={classes.button} disableRipple disableFocusRipple>
              <WarningIcon />
              <span className={classes.label}>
                Last Sync: {moment(currentState.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Button>
          </>
        )
      } else {
        return (
          <>
            <ThumbUpIcon />
            <span className={classes.label}>
              Last Sync: {moment(currentState.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </>
        )
      }
    }

    return null
  }

  if (currentState === null) {
    return null
  }

  return (
    <div className={classes.syncContainer}>
      {renderSyncMessage()}

      {currentState && currentState.messages && currentState.messages.length ? (
        <Dialog open={openSyncDialog} onClose={handleClose}>
          <DialogTitle>Sync Status</DialogTitle>
          <DialogContent>
            <DialogContentText>Errors found during sync:</DialogContentText>
            <ul>
              {currentState.messages.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            <span>
              Time taken: <b>{currentState.totalTimeTaken}</b>
            </span>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  )
}

export default withStyles(styles)(SyncStatus)
