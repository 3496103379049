import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Box, LinearProgress, Typography } from '@material-ui/core'

import apiService from '../../services/api'

import Text from '../Metrics/MetricView/Text'

const options = {
  responsive: 'standard',
  elevation: 1,
  filter: false,
  fixedHeader: true,
  pagination: false,
  print: false,
  viewColumns: false,
  search: false,
  tableBodyHeight: '270px',
  selectableRows: 'none',
  downloadOptions: {
    filename: `bench-${moment().utc().format()}.csv`,
  },
}

const columns = [
  {
    label: 'Name',
    name: 'name',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Start Date',
    name: 'benched_at',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Contract',
    name: 'contract',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Role Name',
    name: 'role',
    options: {
      filter: false,
      sort: false,
      // eslint-disable-next-line react/display-name
      customBodyRender: (value) => {
        return <Text value={value} option={{ placement: 'top-start', maxLength: 30 }} />
      },
    },
  },
]

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          minHeight: 0,
          height: 0,
        },
        actions: {
          '& button': {
            position: 'absolute',
            top: '-45px',
            right: 0,
          },
        },
      },
    },
  })

const HomeBenched = () => {
  const [benched, setBenched] = useState([])
  const [benchedLoading, setBenchedLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiService.get(null, `resources/bench`)

        setBenched(result.data)
        setBenchedLoading(false)
      } catch (e) {
        setBenchedLoading(false)
        console.log(e)
      }
    }

    if (!benched.length) {
      setBenchedLoading(true)
      fetchData()
    }
  }, [benched.length])

  return (
    <div className={classNames('benchedWidget', benchedLoading && 'benchedWidget--loading')}>
      {benchedLoading ? (
        <LinearProgress />
      ) : !benched.length ? (
        <Box p={2} color="text.primary" border={1} borderColor="grey.500">
          <Typography variant="body2">There are no Truepers in Bench at this time</Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h6" className="benchedWidget__title">
            Bench
          </Typography>
          <Box mb={2}>
            <Typography variant="body2">Total Truepers: {benched.length}</Typography>
          </Box>

          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable data={benched} columns={columns} options={options} className="bench__chart" />
          </MuiThemeProvider>
        </>
      )}
    </div>
  )
}

export default HomeBenched
