import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'

import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import Can from '../../components/Common/Can'

import Resources from '../../components/Resources'
import ResourceDrawer from '../../components/ResourceDrawer'

import { fetchResources } from '../../actions/resourceActions'
import { errorNotification } from '../../actions/notificationActions'

const styles = (theme) => ({
  div: {
    flexGrow: 1,
    overflow: 'auto',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  anchor: {
    color: '#fff',
    textDecoration: 'none',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

const ResourcesContainer = ({ classes }) => {
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.currentUser)

  let resources = useSelector((state) =>
    state.resources.items.map((r) => {
      if (r.location) {
        const locationParts = r.location.split(',')

        r.city = locationParts[0].trim()
        r.country = locationParts[1].trim()
      }

      return r
    })
  )
  const resourcesError = useSelector((state) => state.resources.error)

  const [selectedResource, setSelectedResource] = useState(null)

  useEffect(() => {
    document.title = 'Truepers List'
  }, [])

  useEffect(() => {
    if (!resources.length) {
      dispatch(fetchResources())
    }
  }, [dispatch, resources.length])

  useEffect(() => {
    if (resourcesError) {
      dispatch(errorNotification('There was an error retrieving resources, please try again later.'))
    }
  }, [dispatch, resourcesError])

  const toggleDrawer = (resource) => {
    setSelectedResource(resource)
  }

  const handleDrawerClose = () => {
    setSelectedResource(null)
  }

  const actionButtons = (resourceId) => (
    <div className={classNames(classes.centerActionButtons, 'resources__actionButtons')}>
      <Can
        role={currentUser.role_name}
        perform="resources:edit"
        yes={() => (
          <Link to={`/admin/resources/${resourceId}`} className="resources__edit">
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Link>
        )}
        no={() => '-'}
      />
    </div>
  )

  return (
    <>
      <Resources data={resources} openDetails={toggleDrawer} actionButtons={actionButtons} />
      <ResourceDrawer resource={selectedResource} handleClose={handleDrawerClose} />
    </>
  )
}

export default withStyles(styles)(withSnackbar(ResourcesContainer))
