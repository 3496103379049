import apiService from '../services/api'

export const FETCH_SYNC_BEGIN = 'FETCH_SYNC_BEGIN'
export const FETCH_SYNC_SUCCESS = 'FETCH_SYNC_SUCCESS'
export const FETCH_SYNC_FAILURE = 'FETCH_SYNC_FAILURE'

// --------------- GET ALL ----------------

export const fetchSyncBegin = () => ({
  type: FETCH_SYNC_BEGIN,
})

export const fetchSyncSuccess = sync => ({
  type: FETCH_SYNC_SUCCESS,
  payload: { sync },
})

export const fetchSyncFailure = error => ({
  type: FETCH_SYNC_FAILURE,
  payload: { error },
})

export function fetchSync() {
  return dispatch => {
    dispatch(fetchSyncBegin())
    return apiService
      .get(null, 'sync')
      .then(res => {
        dispatch(fetchSyncSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchSyncFailure(error)))
  }
}
