import { combineReducers } from 'redux'

//Import all reducers here
import allocationReducer from './allocation'
import clientsReducer from './clients'
import currentUserReducer from './currentUser'
import errorsReducer from './errors'
import metricTypesReducer from './metricTypes'
import contractTypesReducer from './contractTypes'
import notificationsReducer from './notifications'
import planningReducer from './planning'
import projectsReducer from './projects'
import resourceReducer from './resources'
import auditsReducer from './audits'
import usersReducer from './users'
import skillsReducer from './skills'
import seniorityReducer from './seniority'
import rolesReducer from './roles'
import projectStatuses from './projectStatus'
import projectMetrics from './projectMetrics'
import resourcePerformance from './resourcePerformance'
import skillSeniorityReducer from './skillSeniority'
import syncReducer from './sync'

const rootReducer = combineReducers({
  audits: auditsReducer,
  allocation: allocationReducer,
  clients: clientsReducer,
  currentUser: currentUserReducer,
  errors: errorsReducer,
  metricTypes: metricTypesReducer,
  notifications: notificationsReducer,
  planning: planningReducer,
  projects: projectsReducer,
  resources: resourceReducer,
  users: usersReducer,
  skills: skillsReducer,
  seniority: seniorityReducer,
  roles: rolesReducer,
  projectStatus: projectStatuses,
  projectMetrics: projectMetrics,
  resourcePerformance: resourcePerformance,
  contractTypes: contractTypesReducer,
  skillSeniorities: skillSeniorityReducer,
  sync: syncReducer,
})

export default rootReducer
