import {
  FETCH_PROJECT_METRICS_BEGIN,
  FETCH_PROJECT_METRICS_SUCCESS,
  FETCH_PROJECT_METRICS_FAILURE,
  CREATE_PROJECT_METRIC_BEGIN,
  CREATE_PROJECT_METRIC_SUCCESS,
  CREATE_PROJECT_METRIC_FAILURE,
} from '../../actions/projectMetricsActions'

import initialState from './initialState'

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_METRICS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_PROJECT_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.projectMetrics,
      }

    case FETCH_PROJECT_METRICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }
    case CREATE_PROJECT_METRIC_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case CREATE_PROJECT_METRIC_SUCCESS:
      console.log('action', action)
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.payload.projectMetric],
      }

    case CREATE_PROJECT_METRIC_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
