import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'

import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  anchor: {
    color: '#fff',
    textDecoration: 'none',
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
})

const exportHeaders = [
  {
    name: 'Client Name',
    download: true,
  },
  {
    name: 'Location',
    download: true,
  },
  {
    name: 'Active Projects',
    download: true,
  },
  {
    name: 'Project Manager',
    download: true,
  },
  {
    name: 'Client Status',
    download: true,
  },
]

const Clients = React.memo(
  ({ clients, actionButtons, options, title, classes }) => {
    const opts = {
      filterType: false,
      selectableRows: 'none',
      pagination: true,
      print: false,
      tableBodyHeight: 'calc(100vh - 220px)',
      viewColumns: true,
      ...options,
      downloadOptions: {
        filename: `clients-${moment().utc().format()}.csv`,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataToExport = data.map((d, index) => ({
          index,
          data: [
            d.data[1] || '',
            d.data[2] || '',
            d.data[3] || '',
            d.data[4] && d.data[4].name ? d.data[4].name : '',
            d.data[5] || '',
          ],
        }))

        return buildHead(exportHeaders) + buildBody(dataToExport)
      },
    }

    const columns = [
      {
        name: 'id',
        options: {
          display: false,
          filter: false,
          sort: false,
          viewColumns: false,
        },
      },
      {
        label: 'Client Name',
        name: 'name',
        options: {
          filter: true,
          sort: true,
          viewColumns: true,
        },
      },
      {
        label: 'Location',
        name: 'location',
        options: {
          filter: true,
          sort: true,
          viewColumns: true,
        },
      },
      {
        label: 'Active Projects',
        name: 'projectCount',
        options: {
          filter: false,
          sort: true,
          viewColumns: false,
        },
      },

      {
        label: 'Project Manager',
        name: 'projectManager',
        options: {
          filter: false,
          customBodyRender: (value) => (value ? value.name : '-'),
          viewColumns: false,
        },
      },

      {
        label: 'Client Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          viewColumns: true,
        },
      },
      {
        label: 'Actions',
        name: 'action',
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const clientId = tableMeta.rowData[0] ? tableMeta.rowData[0] : ''
            return actionButtons(clientId)
          },
        },
      },
    ]

    return <MUIDataTable title={title} data={clients} columns={columns} options={opts} className="clients__chart" />
  },
  (prev, next) => _.isEqual(prev.clients, next.clients)
)

export default withStyles(styles)(Clients)
