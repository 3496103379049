import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withSnackbar } from 'notistack'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Drawer, Divider, AppBar, Toolbar, IconButton, Avatar, Menu, MenuItem, useMediaQuery } from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Typography from '@material-ui/core/Typography'

import signinActions from '../../actions/signinActions'

import Logo from '../../components/Common/Logo'
import LeftMenu from '../../components/Common/LeftMenu'
import SyncStatus from '../../components/SyncStatus'

import { successNotification } from '../../actions/notificationActions'

const drawerWidth = 290

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: 0,
    height: '100vh',
  },
  toolbar: {
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 24,
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    maxHeight: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    height: '100%',
    overflow: 'auto',
  },
  navBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    justifyContent: 'center',
  },
  infoItem: {
    color: 'white',
    lineHeight: 1,
  },
  menuIcon: {
    color: 'white',
  },
})

// Disable Varian error with MUI Datatables
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

const Dashboard = (props) => {
  const isSm = useMediaQuery('(min-width:600px)')

  const [drawerIsOpen, setOpen] = useState(isSm)
  const [anchorEl, setAnchorEl] = useState(null)

  const { classes, currentUser } = props

  const dispatch = useDispatch()

  useEffect(() => {
    setOpen(isSm)
  }, [isSm])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logOut = () => {
    handleUserMenuClose()
    props.signOut()
    dispatch(successNotification(`Succesfully logged out`))
    props.history.push('/admin/signin')
  }

  const profile = () => {
    handleUserMenuClose()
    props.history.push('/admin/profile')
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  const handleUserMenuOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const clickCallBack = () => {
    if (!isSm) {
      setOpen(false)
    }
  }

  const getMuiTheme = () => createMuiTheme()

  return (
    <div className={classNames(classes.root, 'mainContainer')}>
      <AppBar
        position="absolute"
        className={classNames(classes.appBar, drawerIsOpen && classes.appBarShift, 'mainContainer__header')}
      >
        <Toolbar disableGutters={!drawerIsOpen} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, isSm && drawerIsOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          <SyncStatus />

          {currentUser && currentUser.token && (
            <>
              <div className={classNames(classes.navBar, 'navbar')}>
                <Avatar alt={currentUser.name} src={currentUser.photos[0].value} className="navbar__avatar" />
                <div className={classNames(classes.userInfo, 'userInfo')}>
                  <Typography variant="subtitle2" className={classNames(classes.infoItem, 'userInfo__name')}>
                    {currentUser.name}
                  </Typography>
                  <Typography variant="subtitle2" className={classNames(classes.infoItem, 'userInfo__role')}>
                    {currentUser.role_name}
                  </Typography>
                </div>
              </div>
              <IconButton
                aria-label="Menu"
                aria-owns={!!anchorEl ? 'user-nav-menu' : undefined}
                aria-haspopup="true"
                onClick={handleUserMenuOpen}
                className="headerMenuButton"
              >
                <MoreVertIcon className={classes.menuIcon} />
              </IconButton>
              <Menu
                id="user-nav-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleUserMenuClose}
                style={{
                  marginTop: '20px',
                  marginLeft: '-28px',
                }}
                className="headerMenu"
              >
                <MenuItem className={classNames('headerMenu__item', 'headerMenu__item--profile')} onClick={profile}>
                  Profile
                </MenuItem>
                <MenuItem className={classNames('headerMenu__item', 'headerMenu__item--logout')} onClick={logOut}>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isSm ? 'permanent' : 'temporary'}
        classes={{
          paper: classNames(classes.drawerPaper, !drawerIsOpen && classes.drawerPaperClose),
        }}
        className={classNames('mainContainer__menu', !drawerIsOpen && 'mainContainer__menu--closed')}
        open={drawerIsOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.toolbarIcon}>
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <LeftMenu clickCallBack={clickCallBack} />
      </Drawer>

      <main className={classNames(classes.content, 'mainContainer__content')}>
        <div className={classes.appBarSpacer} />
        <MuiThemeProvider theme={getMuiTheme()}>{props.children}</MuiThemeProvider>
      </main>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const signinBinds = bindActionCreators(signinActions, dispatch)

  return {
    signOut: signinBinds.signOut,
    rehydrateCurrentUser: signinBinds.rehydrateCurrentUser,
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(Dashboard))))
