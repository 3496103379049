import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'

import LinearProgress from '@material-ui/core/LinearProgress'

import Allocation from '../../components/Reports/Allocation'

import { fetchAllocation } from '../../actions/allocationActions'
import { errorNotification } from '../../actions/notificationActions'

const currentDate = moment().utc().format('YYYY-MM-DD')
const futureDate = moment().utc().add(40, 'days').format('YYYY-MM-DD')

const styles = (theme) => ({
  div: {
    height: 'calc(100% - 64px)',
    overflow: 'auto',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
})

const AllocationContainer = ({ classes }) => {
  const dispatch = useDispatch()

  let resources = useSelector((state) => state.allocation.items)
  const loading = useSelector((state) => state.allocation.loading)
  const allocationError = useSelector((state) => state.allocation.error)

  resources = resources.map((r) => {
    let updatedResource = r
    if (r.location) {
      const locationParts = r.location.split(',')

      updatedResource.city = locationParts[0].trim()
      updatedResource.country = locationParts[1].trim()
    }

    return updatedResource
  })

  useEffect(() => {
    if (!resources.length) {
      dispatch(
        fetchAllocation({
          from: currentDate,
          to: futureDate,
        })
      )
    }
  }, [dispatch, resources.length])

  useEffect(() => {
    if (allocationError) {
      dispatch(errorNotification('There was an error retrieving the allocation data, please try again later.'))
    }
  }, [dispatch, allocationError])

  useEffect(() => {
    document.title = 'Truepers Allocation Report'
  }, [])

  return (
    <div
      className={classNames(
        classes.div,
        'resourceAllocation',
        (loading || !resources.length) && 'resourceAllocation--loading'
      )}
    >
      {loading || !resources.length ? (
        <LinearProgress className={classes.progress} />
      ) : (
        <Allocation resourceData={resources} />
      )}
    </div>
  )
}

export default withStyles(styles)(withSnackbar(AllocationContainer))
