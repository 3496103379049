import _ from 'lodash'

import {
  FETCH_CLIENTS_BEGIN,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENT_BEGIN,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAILURE,
  REMOVE_CLIENT_BEGIN,
  REMOVE_CLIENT_FAILURE,
  REMOVE_CLIENT_SUCCESS,
  REMOVE_PROJECT_CLIENT_BEGIN,
  REMOVE_PROJECT_CLIENT_SUCCESS,
  REMOVE_PROJECT_CLIENT_FAILURE,
  CREATE_CLIENT_BEGIN,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPDATE_CLIENT_BEGIN,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from '../../actions/clientActions'

import initialState from './initialState'

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLIENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.clients,
      }

    case FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    case FETCH_CLIENT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: _.uniqBy([...state.items, action.payload.client], 'id'),
      }

    case FETCH_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case REMOVE_CLIENT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(clients => clients.id !== action.payload),
      }

    case REMOVE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case REMOVE_PROJECT_CLIENT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_PROJECT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(client => {
          const newProjects = client.projects.filter(
            p => p.id !== action.payload
          )
          const activeProjects = newProjects.filter(p => p.status === 'ACTIVE')

          return {
            ...client,
            projects: newProjects,
            active: !!activeProjects.length,
          }
        }),
      }

    case REMOVE_PROJECT_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case CREATE_CLIENT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.payload],
      }

    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_CLIENT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => {
          return item.id !== action.payload.id
            ? item
            : { ...item, ...action.payload }
        }),
      }

    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
