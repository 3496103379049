import _ from 'lodash'
import {
  FETCH_METRIC_TYPES_BEGIN,
  FETCH_METRIC_TYPES_SUCCESS,
  FETCH_METRIC_TYPES_FAILURE,
} from '../../actions/metricTypesActions'

import initialState from './initialState'

export default function metricTypeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_METRIC_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_METRIC_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: _.unionBy(action.payload.metricTypes, state.items, 'id'),
      }

    case FETCH_METRIC_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    default:
      return state
  }
}
