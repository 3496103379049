import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { LinearProgress, Tooltip, Typography } from '@material-ui/core'

import apiService from '../../services/api'
import ResourceAttrition from '../ResourceAttrition/graph'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e0e0e0',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    maxWidth: 'none',
    padding: 4,
    marginBottom: 0,
  },
}))(Tooltip)

const styles = (theme) => ({
  resourceAttrition: {
    height: '100%',
    width: '100%',
  },
  chart: {
    display: 'inline-block',
    height: 'calc(100% - 30px)',
    width: '100%',
  },
  progress: {
    margin: theme.spacing(2),
  },
  cardTitle: {
    textAlign: 'center',
  },
  subtitleContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  titleDivider: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  tooltipArrow: {
    arrow: {
      '&::before': {
        border: '1px solid #e0e0e0',
        boxShadow: theme.shadows[1],
      },
      color: theme.palette.common.white,
    },
  },
})

const ResourceAttritionHome = ({ classes }) => {
  const [resourceAttrition, setResourceAttrition] = useState(null)
  const [loading, setLoading] = useState(true)
  const [resourceAttritionRate, setResourceAttritionRate] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const year = new Date().getFullYear()
      const res = await apiService.get(null, 'resource-attrition/report', { year })

      setResourceAttrition(
        res.data.resourceAttrition.map((item) => ({
          ...item,
          id: item.id.toLowerCase() === 'ingress' ? 'Hires' : 'Exits',
        }))
      )
      setResourceAttritionRate(res.data.resourceAttritionRate)
      setLoading(false)
    }

    setLoading(true)
    fetchData()
  }, [])

  return (
    <div
      className={classNames(classes.resourceAttrition, 'resourceAttrition', loading && 'resourceAttrition--loading')}
    >
      {loading ? (
        <LinearProgress className={classes.progress} />
      ) : (
        <>
          <Typography
            color="textPrimary"
            variant="h6"
            className={classNames(classes.cardTitle, 'resourceAttrition__title')}
          >
            Truepers Attrition
          </Typography>
          <div className={classes.subtitleContainer}>
            <LightTooltip
              arrow
              title="EXITs / Total Active Resources"
              aria-label="EXITs / Total Active Resources"
              placement="top"
              classes={{ tooltip: classes.tooltipArrow }}
            >
              <Typography
                color="textPrimary"
                variant="body2"
                className={classNames(classes.cardTitle, 'resourceAttrition__subtitles')}
              >
                {resourceAttritionRate}
              </Typography>
            </LightTooltip>
          </div>
          <div className={classNames(classes.chart, 'resourceAttrition__chart')}>
            <ResourceAttrition
              widget
              reportData={resourceAttrition}
              optionOverrides={{
                legends: [
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    translateX: 40,
                    translateY: 80,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ],
                enablePointLabel: false,
                isInteractive: true,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(ResourceAttritionHome)
