/* eslint-disable react/display-name */
import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip, Typography, FormControl } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import IngressIcon from '../../assets/icons/resource_join.svg'
import EgressIcon from '../../assets/icons/resource_leave.svg'

import CustomFooter from '../Common/CustomFooter'

const styles = (theme) => ({
  icon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  rotate: {
    transform: 'rotate(45180deg)',
  },
  footerSubtitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
  },
  subtitleItem: {
    paddingRight: theme.spacing(2),
  },
})

const filterResourceByDate = (date, filterDate) => {
  const rowDate = moment(date, 'MM/DD/YYYY')
  const rowMonth = rowDate.format('M')
  const rowYear = rowDate.format('YYYY')

  const filterMonth = filterDate.format('M')
  const filterYear = filterDate.format('YYYY')

  return !(rowMonth === filterMonth && rowYear === filterYear)
}

const resourceFilter = (resource, type, filterDate = null) => {
  if (filterDate) {
    return resource.type === type && !filterResourceByDate(resource.date, filterDate)
  }

  return resource.type === type
}

const ResourceAttrition = ({ resources, title, classes }) => {
  const [hasFilter, setHasFilter] = useState(false)
  const [filterDate, setFilterDate] = useState(null)
  const [ingressResources, setIngressResources] = useState(resources.filter((r) => resourceFilter(r, 'ingress')).length)
  const [egressResources, setEgressResources] = useState(resources.filter((r) => resourceFilter(r, 'egress')).length)

  const columns = [
    {
      name: 'type',
      options: {
        display: false,
        download: false,
      },
    },
    {
      label: 'Status',
      name: 'type',
      options: {
        display: true,
        filter: true,
        sort: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const val = resources[dataIndex].type
          return val === 'egress' ? (
            <Tooltip title="Exit">
              <img src={EgressIcon} className={classes.icon} alt="Exit" />
            </Tooltip>
          ) : (
            <Tooltip title="Hire">
              <img src={IngressIcon} className={classNames(classes.icon, classes.rotate)} alt="Hire" />
            </Tooltip>
          )
        },
        filterOptions: {
          renderValue: (v) => (v ? v.charAt(0).toUpperCase() + v.slice(1) : ''),
        },
      },
    },
    {
      label: 'Trueper',
      name: 'resource_name',
      options: {
        display: true,
        filter: false,
        sort: true,
        viewColumns: false,
      },
    },
    {
      label: 'Date',
      name: 'date',
      options: {
        display: true,
        sort: true,
        viewColumns: false,
        customFilterListOptions: {
          render: (v) => {
            return v && v[0] ? v[0].format('MMMM YYYY') : null
          },
        },
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          logic: (date, filter) => {
            if (filter && filter[0]) {
              return filterResourceByDate(date, filter[0])
            }

            return false
          },
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    variant="inline"
                    openTo="month"
                    views={['year', 'month']}
                    label="Date"
                    emptyLabel="Date"
                    autoOk
                    format="MMMM YYYY"
                    value={filterDate}
                    onChange={(date) => {
                      const momentDate = date.startOf('month')
                      filterList[index][0] = momentDate
                      onChange(filterList[index], index, column)
                      setFilterDate(momentDate)
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )
          },
        },
      },
    },
    {
      label: 'Contract',
      name: 'resource_contract',
      options: {
        display: true,
        filter: false,
        sort: true,
        viewColumns: false,
      },
    },
    {
      label: 'Country',
      name: 'resource_country',
      options: {
        display: true,
        filter: false,
        sort: true,
        viewColumns: false,
      },
    },
    {
      label: 'Role(s) Name',
      name: 'resource_role',
      options: {
        display: true,
        filter: false,
        sort: true,
        viewColumns: false,
      },
    },
  ]

  const options = {
    filterType: false,
    selectableRows: 'none',
    pagination: true,
    print: false,
    tableBodyHeight: `calc(100vh - ${hasFilter ? 250 : 210}px)`,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      const tableData = tableState.displayData.map((d) => d.data)

      const ingress = tableData.map((t) => t[0]).filter((item) => item === 'ingress').length
      const egress = tableData.map((t) => t[0]).filter((item) => item === 'egress').length

      setIngressResources(ingress)
      setEgressResources(egress)
      setHasFilter(!!tableState.filterList.flat().length)
    },
    // eslint-disable-next-line react/display-name
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        >
          <div className={classes.footerSubtitle}>
            <Typography variant="body1" className={classes.subtitleItem}>
              Trueper Hires: {ingressResources}
            </Typography>
            <Typography variant="body1" className={classes.subtitleItem}>
              Trueper Exits: {egressResources}
            </Typography>
          </div>
        </CustomFooter>
      )
    },
    downloadOptions: {
      filename: `resource-attrition-${moment().utc().format()}.csv`,
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      console.log('columns', columns)
      const dataToExport = data.map((d, index) => ({
        index,
        data: [
          d.data[0] === 'ingress' ? 'Hire' : 'Exit' || '',
          d.data[1] || '',
          d.data[2] || '',
          d.data[3] || '',
          d.data[4] || '',
          d.data[5] || '',
        ],
      }))

      return buildHead(columns) + buildBody(dataToExport)
    },
  }

  return (
    <div className="resourceAttrition">
      <MUIDataTable
        title={title}
        data={resources}
        columns={columns}
        options={options}
        className="resourceAttrition__chart"
      />
    </div>
  )
}

export default withStyles(styles)(ResourceAttrition)
