import React from 'react'
import moment from 'moment'

import { FormControlLabel, Typography, Switch, TextField } from '@material-ui/core'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import RemoveIcon from '@material-ui/icons/Remove'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import MultipleTagSearcheable from '../components/Common/MultipleTagSearcheable'
import PlaceAutocomplete from '../components/Common/PlaceAutocomplete'

const customSwitch = ({ schema, value, required, disabled, readonly, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
          value={value}
          disabled={disabled || readonly}
          required={required}
          color="primary"
        />
      }
      label={schema.title}
    />
  )
}

// eslint-disable-next-line react/jsx-key
const icons = [<ThumbDownIcon />, <RemoveIcon />, <ThumbUpIcon />]

const customButtonGroup = ({ schema, value, required, disabled, readonly, onChange }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        marginLeft: 0,
        marginRight: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography variant="h6">
        {schema.title}
        {required ? '*' : ''}
      </Typography>
      <ToggleButtonGroup size="small" value={value} exclusive onChange={(event, value) => onChange(value)}>
        {schema.enum &&
          schema.enum.map((item, index) => {
            return (
              <ToggleButton
                key={item}
                value={item}
                style={{
                  backgroundColor: item,
                  ...(value === item && {
                    border: '4px black solid',
                    padding: '4px',
                  }),
                }}
                disabled={disabled || readonly}
              >
                {icons[index]}
              </ToggleButton>
            )
          })}
      </ToggleButtonGroup>
    </div>
  )
}

const customMultiSelect = (props) => {
  const { value, schema, onChange } = props

  return (
    <MultipleTagSearcheable
      onChange={onChange}
      value={value}
      title={schema.title}
      placeholder={schema.placeholder}
      suggestions={schema.suggestions}
    />
  )
}

const customPlaceAutocomplete = (props) => {
  const { value, schema, onChange, label } = props

  return <PlaceAutocomplete value={value} onChange={onChange} label={label} placeholder={schema.placeholder} />
}

const customTextfield = (props) => (
  <TextField
    multiline
    label={props.label}
    placeholder={props.schema.placeholder}
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
)

const customDatePicker = ({ value, onChange, label }) => {
  // TODO: default param doesnt work??
  if (!value) {
    value = moment().format('YYYY-MM-DD')
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        label={label}
        value={value}
        onChange={(e) => onChange(e.format('YYYY-MM-DD'))}
        allowKeyboardControl
        autoOk
        format="YYYY-MM-DD"
      />
    </MuiPickersUtilsProvider>
  )
}

export default {
  customDatePicker,
  customTextfield,
  customSwitch,
  customButtonGroup,
  customMultiSelect,
  customPlaceAutocomplete,
}
