import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { Box, LinearProgress, Select, MenuItem, Typography } from '@material-ui/core'

import ResourcePerformance from '../ResourcePerformance'
import utils from '../../utils'

import { fetchResourcePerformance } from '../../actions/resourcePerformanceActions'

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginLeft: 'auto',
  },
  dateFilter: {
    alignItems: 'flex-start',
    marginLeft: 'auto',
  },
  resourcePerformanceContainer: {
    marginTop: 16,
  },
})

const HomeResourcePerformance = ({ classes }) => {
  const dispatch = useDispatch()

  const [selectedDate, setSelectedDate] = useState(5)

  const resourcePerformance = useSelector((state) =>
    utils.filterRedProjectsAndResources(state.resourcePerformance.items)
  )
  const resourcePerformanceLoading = useSelector((state) => state.resourcePerformance.loading)

  useEffect(() => {
    dispatch(fetchResourcePerformance({ status: 'ACTIVE', daysAgo: selectedDate }))
  }, [dispatch, selectedDate])

  const handleFilterCallback = (e) => {
    setSelectedDate(e.target.value)
  }

  return (
    <div className={classNames('projectStatusWidget', resourcePerformanceLoading && 'projectStatus--loading')}>
      {resourcePerformanceLoading ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.container}>
            <Box textAlign="center" className={classes.title}>
              <Typography variant="h6" className="resourcePerformance__title">
                Truepers Performance
              </Typography>
            </Box>
            <Select className={classes.dateFilter} value={selectedDate} onChange={handleFilterCallback}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value={5}>5 Days</MenuItem>
              <MenuItem value={10}>10 Days</MenuItem>
              <MenuItem value={20}>20 Days</MenuItem>
            </Select>
          </div>
          <div className={classes.resourcePerformanceContainer}>
            <ResourcePerformance resourcePerformance={resourcePerformance} widget />
          </div>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(HomeResourcePerformance)
