import { FETCH_PLANNING_BEGIN, FETCH_PLANNING_SUCCESS, FETCH_PLANNING_FAILURE } from '../../actions/planningActions'

import initialState from './initialState'

export default function planningReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PLANNING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_PLANNING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.planning,
      }

    case FETCH_PLANNING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: null,
      }

    default:
      return state
  }
}
