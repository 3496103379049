import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Divider, LinearProgress, Tooltip, Typography } from '@material-ui/core'
import PlannedHours from '../../components/Reports/PlannedHours'

import { fetchPlanning } from '../../actions/planningActions'
import { errorNotification } from '../../actions/notificationActions'

const styles = (theme) => ({
  plannedHours: {
    height: '100%',
    width: '100%',
  },
  chart: {
    display: 'inline-block',
    height: 'calc(100% - 30px)',
    width: '100%',
  },
  progress: {
    margin: theme.spacing(2),
  },
  cardTitle: {
    textAlign: 'center',
  },
  subtitleContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  titleDivider: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  tooltipArrow: {
    arrow: {
      '&::before': {
        border: '1px solid #e0e0e0',
        boxShadow: theme.shadows[1],
      },
      color: theme.palette.common.white,
    },
  },
})

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e0e0e0',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    maxWidth: 'none',
    padding: 4,
    marginBottom: 0,
  },
}))(Tooltip)

const PlannedHoursHome = ({ classes }) => {
  const dispatch = useDispatch()
  const currentYear = new Date().getFullYear()

  const planning = useSelector((state) => state.planning.items)
  let reportData = []
  let resourceUtilization = ''
  let resourceOverhead = ''

  if (planning) {
    reportData = planning.reportData
    resourceUtilization = planning.lastMonthUtilization
    resourceOverhead = planning.lastMonthOverhead
  }

  const loading = useSelector((state) => state.planning.loading)
  const planningError = useSelector((state) => state.planning.error)

  useEffect(() => {
    if (planningError) {
      dispatch(errorNotification('There was an error retrieving the resource count, please try again later.'))
    }
  }, [dispatch, planningError])

  useEffect(() => {
    dispatch(fetchPlanning(currentYear))
  }, [dispatch, currentYear])

  return (
    <div className={classNames(classes.plannedHours, 'plannedHours', loading && 'plannedHours--loading')}>
      {loading ? (
        <LinearProgress className={classes.progress} />
      ) : (
        <>
          <Typography color="textPrimary" variant="h6" className={classNames(classes.cardTitle, 'plannedHours__title')}>
            Hour Status Per Month
          </Typography>
          <div className={classes.subtitleContainer}>
            <LightTooltip
              arrow
              title="(Bench + IntProject) / TotalBilled"
              aria-label="(Bench + IntProject) / TotalBilled"
              placement="top"
              classes={{ tooltip: classes.tooltipArrow }}
            >
              <Typography
                color="textPrimary"
                variant="body2"
                className={classNames(classes.cardTitle, 'plannedHours__subtitles')}
              >
                {resourceUtilization}
              </Typography>
            </LightTooltip>
            <Divider orientation="vertical" flexItem className={classes.titleDivider} />
            <LightTooltip
              arrow
              title="(TotalStaff / TotalBilled)"
              aria-label="(TotalStaff / TotalBilled)"
              placement="top"
              classes={{ tooltip: classes.tooltipArrow }}
            >
              <Typography
                color="textPrimary"
                variant="body2"
                className={classNames(classes.cardTitle, 'plannedHours__subtitles')}
              >
                {resourceOverhead}
              </Typography>
            </LightTooltip>
          </div>
          <div className={classNames(classes.chart, 'plannedHours__chart')}>
            <PlannedHours
              widget
              reportData={reportData}
              optionOverrides={{
                legends: [
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    translateX: 40,
                    translateY: 80,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ],
                enablePointLabel: false,
                isInteractive: true,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(withSnackbar(PlannedHoursHome))
