import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import { Link } from 'react-router-dom'

import EditIcon from '@material-ui/icons/Edit'
import { LinearProgress, IconButton } from '@material-ui/core'

import Projects from '../../components/Projects'
import Can from '../../components/Common/Can'

import { fetchProjects } from '../../actions/projectActions'

const ProjectsContainer = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.currentUser)
  const projects = useSelector((state) => state.projects.items)
  const loading = useSelector((state) => state.projects.loading)

  useEffect(() => {
    document.title = 'Projects List'
  }, [])

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  const actionButtons = (projectId) => (
    <div className="projects__actionButtons">
      <Can
        role={currentUser.role_name}
        perform="projects:edit"
        yes={() => (
          <Link to={`/admin/projects/${projectId}`} className="projects__edit">
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Link>
        )}
        no={() => null}
      />
    </div>
  )

  return (
    <div className="projects">
      {!loading ? (
        <Projects projects={projects} title="Projects List" actionButtons={actionButtons} />
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}

export default withSnackbar(ProjectsContainer)
