import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'

import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  breadcrumbContainer: {
    marginTop: 0,
    marginBottom: 10,
    display: 'flex',
    marginLeft: 0,
    paddingLeft: 0,
  },
  breadcrumbItem: {
    display: 'flex',
  },
  breadcrumbText: {
    textTransform: 'capitalize',
  },
  link: {
    textDecoration: 'none',
  },
})

const BreadcrumbGen = ({ paths, classes }) =>
  paths && paths.length ? (
    <ul className={classNames(classes.breadcrumbContainer, 'breadcrumbs')}>
      {paths.map(path => {
        if (path === paths[paths.length - 1]) {
          return (
            <Typography
              color="textPrimary"
              key={path.name}
              className={classNames(
                classes.breadcrumbText,
                'breadcrumbs__item--last'
              )}
            >
              {path.name}
            </Typography>
          )
        } else {
          return (
            <div
              key={`${path.route}-${path.name}`}
              className={classNames(
                classes.breadcrumbItem,
                'breadcrumbs__item'
              )}
            >
              <Link
                className={classes.link}
                component={RouterLink}
                to={path.route}
                color="inherit"
              >
                <Typography
                  color="textPrimary"
                  key={path.name}
                  className={classes.breadcrumbText}
                >
                  {path.name}
                </Typography>
              </Link>
              <NavigateNextIcon fontSize="small" />
            </div>
          )
        }
      })}
    </ul>
  ) : null

export default withRouter(withStyles(styles)(BreadcrumbGen))
