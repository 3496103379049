import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router'
import { withSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import Form from '@rjsf/material-ui'

import { Box, Paper, Button, Typography, LinearProgress } from '@material-ui/core'

import BreadcrumbGen from '../../components/Common/BreadcrumbGen'

import { schema, uiSchema } from '../../models/resources'

import widgets from '../../models/customWidgets'

import { fetchSkillSeniority } from '../../actions/skillSeniorityActions'
import { fetchResources, updateResource } from '../../actions/resourceActions'

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

const FormContainer = ({ classes, match }) => {
  const resourceId = match.params.id

  const dispatch = useDispatch()

  const [cancel, setCancel] = useState(false)
  const [schemaVal, setSchema] = useState(null)
  const [uiSchemaVal, setUiSchema] = useState(uiSchema)

  const resources = useSelector((state) => state.resources.items)
  const resourceData = useSelector((state) => state.resources.items.filter((r) => r.id === resourceId)[0] || null)

  const skillSeniorityResults = useSelector((state) => state.skillSeniorities.items)

  const [formData, setFormData] = useState(null)

  const resourcePaths = [
    {
      name: 'Home',
      route: '/',
    },
    {
      name: 'Resources',
      route: '/admin/resources',
    },
    {
      name: `Edit resource: ${resourceId}`,
    },
  ]

  // Update schema with skill suggestions
  useEffect(() => {
    if (skillSeniorityResults.length) {
      const schemaClone = Object.assign({}, schema)
      schemaClone.properties.skills.suggestions = skillSeniorityResults

      setSchema(schemaClone)
    }
  }, [skillSeniorityResults])

  // Fetch skills and seniorities
  useEffect(() => {
    if (!skillSeniorityResults.length) {
      dispatch(fetchSkillSeniority())
    }
  }, [dispatch, skillSeniorityResults])

  useEffect(() => {
    if (!resources.length) {
      dispatch(fetchResources())
    }
  }, [dispatch, resources.length])

  useEffect(() => {
    if (resourceData) {
      setFormData(resourceData)

      if (resourceData.bench) {
        setUiSchema((state) => ({
          ...state,
          benched_at: {
            'ui:widget': 'customDatePicker',
          },
        }))
      } else {
        setUiSchema((state) => ({
          ...state,
          benched_at: {
            'ui:widget': 'hidden',
          },
        }))
      }
    }
  }, [resourceData])

  useEffect(() => {
    document.title = 'Edit Resource'
  }, [])

  const handleSubmit = ({ formData }) => {
    dispatch(updateResource(match.params.id, formData))
    //TODO: Update allocation with updated resource data
  }

  const handleChange = ({ formData }) => {
    const updatedForm = {
      ...formData,
      ...(formData.location && formData.location.latlng && formData.location.latlng.lat
        ? { latitude: formData.location.latlng.lat }
        : {}),
      ...(formData.location && formData.location.latlng && formData.location.latlng.lng
        ? { longitude: formData.location.latlng.lng }
        : {}),
      ...(formData.location && formData.location.name && formData.location.country
        ? { location: `${formData.location.name}, ${formData.location.country}` }
        : {}),
    }

    if (formData.bench && !formData.benched_at) {
      updatedForm.benched_at = moment().format('YYYY-MM-DD')
    }

    setFormData(updatedForm)

    if (updatedForm.bench) {
      setUiSchema((state) => ({
        ...state,
        benched_at: {
          'ui:widget': 'customDatePicker',
        },
      }))
    } else {
      setUiSchema((state) => ({
        ...state,
        benched_at: {
          'ui:widget': 'hidden',
        },
      }))
    }
  }

  return (
    <>
      <BreadcrumbGen paths={resourcePaths} />

      <Paper className={classes.paper}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          Edit Resource
        </Typography>
        {cancel && <Redirect to="/admin/resources" />}
        {formData && schemaVal ? (
          <Form
            noHtml5Validate
            showErrorList={false}
            schema={schemaVal}
            uiSchema={uiSchemaVal}
            widgets={widgets}
            formData={formData}
            onSubmit={handleSubmit}
            onChange={handleChange}
            noValidate
          >
            <Box mt={2}>
              <Button
                variant="contained"
                color="default"
                className={classes.cancelButton}
                onClick={() => setCancel(true)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Form>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </>
  )
}

export default withStyles(styles)(withSnackbar(FormContainer))
