import { SET_CURRENT_USER } from '../../actions/signinActions'

import initialState from './initialState'

export const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return Object.assign({}, state, {
        id: action.id,
        name: action.name,
        photos: action.photos,
        email: action.email,
        token: action.token,
        role_name: action.role_name,
      })
    case SET_CURRENT_USER_INFO:
      return Object.assign({}, state, {
        ...action.payload,
      })
    default:
      return state
  }
}
