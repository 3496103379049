import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'

import { LinearProgress, MenuItem, Select, Typography, Paper } from '@material-ui/core'

import PlannedHours from '../../components/Reports/PlannedHours'

import { fetchPlanning } from '../../actions/planningActions'
import { errorNotification } from '../../actions/notificationActions'

const styles = (theme) => ({
  fullHeight: {
    height: 'calc(100% - 80px)',
    padding: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filters: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'right',
  },
  filterTitle: {
    paddingRight: theme.spacing(2),
  },
  chartTitle: {
    [theme.breakpoints.down('sm')]: {
      'font-size': 16,
    },
  },
  chartContainer: {
    height: '100%',
  },
})

// Initial year we have data for
const EARLIEST_YEAR = 2018
const CURRENT_YEAR = new Date().getFullYear()

const PlannedHoursContainer = ({ classes }) => {
  const [year, setYear] = useState(new Date().getFullYear())

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.planning.loading)
  const planningError = useSelector((state) => state.planning.error)

  const planning = useSelector((state) => state.planning.items)
  let reportData = []

  if (planning) {
    reportData = planning.reportData
  }

  useEffect(() => {
    if (planningError) {
      dispatch(errorNotification('There was an error retrieving the resource count, please try again later.'))
    }
  }, [dispatch, planningError])

  useEffect(() => {
    dispatch(fetchPlanning(year))

  }, [dispatch, year])

  useEffect(() => {
    document.title = 'Hour Status Per Month '
  }, [])

  const handleYearChange = (e) => {
    setYear(e.target.value)
  }

  const buildYearDropdown = () => {
    const years = new Array(CURRENT_YEAR + 1 - EARLIEST_YEAR).fill('').map((val, index) => EARLIEST_YEAR + index)

    return (
      <Select value={year} onChange={handleYearChange} className="plannedHours__filter">
        {years.map((val) => (
          <MenuItem
            className={classNames('plannedHours__filterItem', `plannedHours__filterItem--${val}`)}
            value={val}
            key={val}
          >
            {val}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return (
    <Paper className={classNames(classes.fullHeight, 'plannedHours')}>
      <div className={classNames(classes.titleRow, 'plannedHours__titleRow')}>
        <Typography color="textPrimary" variant="h6" className={classNames(classes.chartTitle, 'plannedHours__title')}>
          Hour Status Per Month
        </Typography>
        <div className={classNames(classes.filters, 'plannedHours__filters')}>
          <Typography color="textPrimary" variant="subtitle1" className={classes.filterTitle}>
            Report Year
          </Typography>
          {buildYearDropdown()}
        </div>
      </div>
      <div
        className={classNames(
          classes.chartContainer,
          'plannedHours__container',
          (loading || !reportData.length) && 'plannedHours__container--loading'
        )}
      >
        {loading || !reportData.length ? (
          <LinearProgress className={classes.progress} />
        ) : (
          <PlannedHours reportData={reportData} />
        )}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(withSnackbar(PlannedHoursContainer))
