const recursiveFind = (items, searchText) => {
  if (!items || !items.length) return false

  for (const item of items) {
    if (item.index === searchText) {
      return item
    }

    if (item.submenu.length) {
      return recursiveFind(item.submenu, searchText)
    }

    return false
  }
}

// Sort metrics by their "order" property
const sortProjectMetrics = (metricA, metricB) => metricA.order - metricB.order

// Given a value, check what the final score is
const calculateOverallScore = (value) => {
  return (value & 8) > 0 ? 0 : (value & 4) > 0 ? 1 : 2
}

// Return the message in the correct tense for CRUD operations
const actionVerb = (formAction, tense) => {
  let verb = ''
  switch (tense) {
    case 'present': {
      if (formAction === 'ADD') {
        verb = 'to add'
      } else if (formAction === 'EDIT') {
        verb = 'to update'
      } else if (formAction === 'DELETE') {
        verb = 'to delete'
      }
      break
    }
    case 'past': {
      if (formAction === 'ADD') {
        verb = 'added'
      } else if (formAction === 'EDIT') {
        verb = 'updated'
      } else if (formAction === 'DELETE') {
        verb = 'deleted'
      }
      break
    }
    case 'infinitive': {
      if (formAction === 'ADD') {
        verb = 'adding'
      } else if (formAction === 'EDIT') {
        verb = 'updating'
      } else if (formAction === 'DELETE') {
        verb = 'deleting'
      }
      break
    }
    default:
      break
  }
  return verb
}

const breakLongNames = (value) => {
  const nameArr = value.split(' ')
  if (nameArr.length > 2) {
    if (nameArr.length > 3) {
      nameArr[0] = nameArr[0] + ' ' + nameArr[1]
      nameArr[1] = nameArr[2] + ' ' + nameArr[3]
      delete nameArr[2]
      delete nameArr[3]
    } else {
      nameArr[1] = nameArr[1] + ' ' + nameArr[2]
      delete nameArr[2]
    }
  }

  return nameArr
}

const OverallMetric = Object.freeze({
  Red: 0,
  Yellow: 1,
  Green: 2,
})

const filterRedProjectsAndResources = (projects = []) => {
  if (!projects.length) return []

  const filteredProjects = projects.filter((p) => {
    const overall = calculateOverallScore(p.overall)
    return overall === OverallMetric.Red
  })

  return filteredProjects
}

export default {
  sortProjectMetrics,
  recursiveFind,
  calculateOverallScore,
  actionVerb,
  breakLongNames,
  OverallMetric,
  filterRedProjectsAndResources,
}
