import { CLEAR_LOGIN_ERROR, SET_LOGIN_ERROR } from '../../actions/errorsActions'

import initialState from './initialState'

export default function error(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        login: action.error,
      })
    case CLEAR_LOGIN_ERROR:
      const { login, ...errors } = state
      return errors
    default:
      return state
  }
}
