import React, { useEffect } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { Google } from 'mdi-material-ui'
import { withSnackbar } from 'notistack'
import { connect, useDispatch } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import { Button, Typography } from '@material-ui/core'

import signinActions from '../../actions/signinActions'
import errorsActions from '../../actions/errorsActions'

import backgroundImage from '../../assets/login-background.png'
import logo from '../../assets/logo-only.svg'

import { errorNotification } from '../../actions/notificationActions'

const styles = theme => ({
  main: {
    backgroundColor: '#6077F4',
    backgroundImage: `url(${backgroundImage})`,
    backgroundBlendMode: 'multiply',
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    backgroundColor: '#3F51B5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 3, 2, 3),
    height: '80vh',
    width: '80vw',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: '#4B60D3',
    padding: theme.spacing(1.5, 3),
  },
  leftIcon: {
    marginRight: theme.spacing(1.5),
  },
  disclaimer: {
    marginTop: theme.spacing(1.5),
    color: '#fff',
  },
  img: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(6),
  },
  welcome: {
    color: '#fff',
    fontSize: 48,
    fontWeight: 'bold',
  },
})

const SignIn = props => {
  const {
    classes,
    errors,
    clearLoginError,
    currentUser,
    checkLoginErrors,
    rehydrateCurrentUser,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    props.checkLoginErrors()
    props.rehydrateCurrentUser()
  }, [checkLoginErrors, props, rehydrateCurrentUser])

  useEffect(() => {
    if (errors.login) {
      dispatch(errorNotification(errors.login))
      clearLoginError()
    }
  }, [dispatch, errors, clearLoginError])

  const authRedirect = () => {
    window.location.href = '/auth/google'
  }

  const protectWithAuth = () => {
    return !currentUser.token ? null : <Redirect to="/admin/dashboard" />
  }

  return (
    <main className={classes.main}>
      {protectWithAuth()}
      <div className={classes.paper}>
        <div className={classes.content}>
          <img
            className={classes.img}
            src={logo}
            alt="Truelogicsoftware Logo"
          />
          <Typography component="h1" variant="h5" className={classes.welcome}>
            Welcome to Truelogic Tools!
          </Typography>

          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            onClick={authRedirect}
          >
            <Google
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            Sign in using Google*
          </Button>

          <Typography
            variant="caption"
            gutterBottom
            className={classes.disclaimer}
          >
            *Only "truelogicsoftware.com" domains are allowed
          </Typography>
        </div>
      </div>
    </main>
  )
}

const mapDistpachToProps = dispatch => {
  return {
    rehydrateCurrentUser: () => dispatch(signinActions.rehydrateCurrentUser()),
    checkLoginErrors: () => dispatch(errorsActions.checkLoginErrors()),
    clearLoginError: () => dispatch(errorsActions.clearLoginError()),
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDistpachToProps
  )(withStyles(styles)(withSnackbar(SignIn)))
)
