import Cookies from 'universal-cookie'

import userInitialState from '../reducers/currentUser/initialState'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

const cookies = new Cookies()

function setCurrentUser(currentUser) {
  return {
    type: SET_CURRENT_USER,
    ...currentUser,
  }
}

function rehydrateCurrentUser() {
  return dispatch => {
    if (cookies.get('currentUser')) {
      localStorage.setItem(
        'currentUser',
        JSON.stringify(cookies.get('currentUser'))
      )
    }

    const currentUser = JSON.parse(localStorage.getItem('currentUser'))

    if (currentUser) {
      cookies.remove('currentUser')
      dispatch(setCurrentUser(currentUser))
    }
  }
}

function signOut() {
  return dispatch => {
    localStorage.removeItem('currentUser')
    dispatch(setCurrentUser(userInitialState))
  }
}

const signinActions = {
  setCurrentUser,
  rehydrateCurrentUser,
  signOut,
}

export default signinActions
