import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  anchor: {
    color: '#fff',
    textDecoration: 'none',
  },
  progress: {
    margin: theme.spacing(2),
  },
})

const options = {
  filterType: false,
  selectableRows: 'none',
  pagination: true,
  print: false,
  tableBodyHeight: 'calc(100vh - 220px)',
  viewColumns: false,
}

const columns = [
  {
    label: 'Action',
    name: 'action',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    label: 'User',
    name: 'user',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    label: 'Created At',
    name: 'createdAt',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => moment(value).utc().format('YYYY-MM-DD HH:mm:ss'),
    },
  },
  {
    label: 'Item',
    name: 'item',
    options: {
      filter: true,
      sort: true,
    },
  },
]

const Audits = ({ data }) => (
  <MUIDataTable title="Audits" data={data} columns={columns} options={options} className="audits__chart" />
)

export default withStyles(styles)(Audits)
