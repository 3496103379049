import React from 'react'
import Places from 'places.js'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = (_) => ({
  input: {
    marginTop: 0,
  },
})

class PlaceAutocomplete extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.setState({ value })

    if (value === '') {
      this.props.onChange('')
    }
  }

  componentDidMount() {
    if (!Places) return

    this.autocomplete = Places({
      ...this.props.options,
      container: this.autocompleteElem,
      type: 'city',
      aroundLatLngViaIP: false,
    })

    this.autocomplete.on('change', ({ suggestion }) => {
      this.props.onChange(suggestion)
      this.handleChange(suggestion.value)
    })

    this.autocomplete.on('clear', () => {
      this.props.onChange('')
      this.handleChange('')
    })
  }

  componentWillUnmount() {
    if (this.autocomplete) {
      this.autocomplete.removeAllListeners('change')
    }
  }

  render() {
    const { value } = this.state

    return (
      <TextField
        className={this.props.classes.input}
        label={this.props.label}
        placeholder={this.props.placeholder}
        margin="normal"
        value={value}
        onChange={(e) => this.handleChange(e.target.value)}
        inputRef={(ref) => {
          this.autocompleteElem = ref
        }}
      />
    )
  }
}

export default withStyles(styles)(PlaceAutocomplete)
