import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'

import { notificationSent } from '../actions/notificationActions'

const Notifier = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications.items)

  useEffect(() => {
    if (!notifications.length) return

    notifications.forEach(({ key, message, options = {} }) => {
      enqueueSnackbar(message, options)
      dispatch(notificationSent(key))
    })
  }, [notifications, notifications.length, enqueueSnackbar, dispatch])

  return null
}

export default withSnackbar(Notifier)
