import {
  FETCH_SENIORITY_BEGIN,
  FETCH_SENIORITY_SUCCESS,
  FETCH_SENIORITY_FAILURE,
  REMOVE_SENIORITY_BEGIN,
  REMOVE_SENIORITY_SUCCESS,
  REMOVE_SENIORITY_FAILURE,
} from '../../actions/seniorityActions'

import initialState from './initialState'

export default function seniorityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SENIORITY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_SENIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.seniority,
      }

    case FETCH_SENIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }
    case REMOVE_SENIORITY_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_SENIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(
          seniorities => seniorities.id !== action.payload
        ),
      }

    case REMOVE_SENIORITY_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
