import { errorNotification, successNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const REMOVE_USER_BEGIN = 'REMOVE_USER_BEGIN'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

// --------------- GET ALL ----------------

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN,
})

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users },
})

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error },
})

export function fetchUsers() {
  return dispatch => {
    dispatch(fetchUsersBegin())
    return apiService
      .get(null, 'users')
      .then(res => {
        dispatch(fetchUsersSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchUsersFailure(error)))
  }
}

// --------------- GET ONE ----------------

export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN,
})

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: { user },
})

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: { error },
})

export function fetchUser(userId) {
  return (dispatch, getState) => {
    dispatch(fetchUserBegin())

    const state = getState()
    const user = state.users.items.filter(c => c.id === userId)

    if (user.length) {
      return dispatch(fetchUserSuccess(user[0]))
    } else {
      return dispatch(fetchUsers()).then(res => {
        const user = res.filter(c => c.id === userId)

        if (user) {
          dispatch(fetchUserSuccess(user[0]))
        } else {
          dispatch(errorNotification('There was an error fetching the User'))
          dispatch(fetchUserFailure('There was an error fetching the User'))
        }
      })
    }
  }
}

// --------------- REMOVE ----------------

export const removeUserBegin = () => ({
  type: REMOVE_USER_BEGIN,
})

export const removeUserSuccess = userId => ({
  type: REMOVE_USER_SUCCESS,
  payload: userId,
})

export const removeUserFailure = error => ({
  type: REMOVE_USER_FAILURE,
  payload: { error },
})

export function removeUser(userId, userName) {
  return dispatch => {
    dispatch(removeUserBegin())
    return apiService
      .remove(userId, 'users')
      .then(res => {
        dispatch(removeUserSuccess(userId))
        dispatch(successNotification(`User: "${userName}" deleted`))
        return res
      })
      .catch(error => {
        dispatch(removeUserFailure(error))
        dispatch(
          errorNotification(`There was an error deleting user: "${userName}"`)
        )
      })
  }
}

// --------------- CREATE ----------------

export const createUserBegin = () => ({
  type: CREATE_USER_BEGIN,
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
})

export const createUserFailure = error => ({
  type: CREATE_USER_FAILURE,
  payload: { error },
})

export function createUser(data) {
  return dispatch => {
    dispatch(createUserBegin())
    return apiService
      .create(data, 'users')
      .then(res => {
        dispatch(createUserSuccess(res.data[0]))
        dispatch(successNotification(`User: "${data.name}" created!`))
        return res
      })
      .catch(error => {
        dispatch(createUserFailure(error))
        dispatch(
          errorNotification(`There was an error creating user: "${data.name}"`)
        )
      })
  }
}

// --------------- UPDATE ----------------

export const updateUserBegin = () => ({
  type: UPDATE_USER_BEGIN,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  payload: { error },
})

export function updateUser(userId, data) {
  return dispatch => {
    dispatch(updateUserBegin())
    return apiService
      .update(userId, data, 'users')
      .then(res => {
        dispatch(updateUserSuccess(res.data[0]))
        dispatch(successNotification(`User: "${data.name}" updated!`))
        return res
      })
      .catch(error => {
        dispatch(updateUserFailure(error))
        dispatch(
          errorNotification(`There was an error updating user: "${data.name}"`)
        )
      })
  }
}
