import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'

import LinearProgress from '@material-ui/core/LinearProgress'

const styles = (theme) => ({
  container: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(1),
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  anchor: {
    color: '#fff',
    textDecoration: 'none',
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
})

const Users = ({ data, classes, actionButtons }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    setUsers(data)
  }, [data])

  const options = {
    filterType: false,
    selectableRows: 'none',
    pagination: true,
    print: false,
    tableBodyHeight: 'calc(100vh - 220px)',
    viewColumns: false,
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Role',
      name: 'role_name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Edit',
      name: 'id',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0] ? tableMeta.rowData[0] : ''
          return actionButtons(userId)
        },
      },
    },
  ]

  return (
    <div className="users">
      {users.length ? (
        <MUIDataTable
          title="Tools User Access List"
          data={users}
          columns={columns}
          options={options}
          className="users__chart"
        />
      ) : (
        <LinearProgress className={classes.progress} />
      )}
    </div>
  )
}

export default withStyles(styles)(Users)
