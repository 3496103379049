import _ from 'lodash'

import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  REMOVE_USER_BEGIN,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAILURE,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../../actions/userActions'

import initialState from './initialState'

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.users,
      }

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    case FETCH_USER_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: _.uniqBy([...state.items, action.payload.user], 'id'),
      }

    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case REMOVE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(users => users.id !== action.payload),
      }

    case REMOVE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case CREATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.payload],
      }

    case CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => {
          return item.id !== action.payload.id
            ? item
            : { ...item, ...action.payload }
        }),
      }

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
