import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router'
import Form from '@rjsf/material-ui';
import classNames from 'classnames'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import BreadcrumbGen from '../../components/Common/BreadcrumbGen'

import { schema, uiSchema, initialState } from '../../models/users'

import {
  fetchUser,
  fetchUsers,
  createUser,
  updateUser,
} from '../../actions/userActions'

import { fetchRoles } from '../../actions/rolesActions'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

const FormContainer = ({ classes, match }) => {
  let userId = match.params.id
  const tries = useRef(0)

  const dispatch = useDispatch()

  const clonedSchema = _.cloneDeep(schema)

  const currentUser = useSelector(state => state.currentUser)
  const rolesData = useSelector(state => state.roles.items)

  const userData = useSelector(state => {
    if (state.users.items.length && !!userId) {
      return state.users.items.filter(user => user.id === userId)[0]
    }

    return initialState
  })

  const [finished, setFinished] = useState(false)

  const [isProfile, setIsProfile] = useState(
    match.path === '/admin/profile' || userId === currentUser.id
  )

  if (isProfile) {
    userId = currentUser.id
  }

  const newUser = !userId

  let resourcePaths = [
    { name: 'Home', route: '/' },
    { name: 'Users', route: '/admin/users' },
    { name: `${userId ? 'Edit user: ' + userId : 'Create'}` },
  ]

  if (isProfile) {
    resourcePaths = [
      { name: 'Home', route: '/' },
      { name: 'Profile', route: '/admin/profile' },
    ]
  }

  useEffect(() => {
    if (userId && !newUser && tries.current === 0) {
      tries.current++
      dispatch(fetchUser(userId))
      dispatch(fetchRoles())
    }
  }, [userId, dispatch, newUser])

  useEffect(() => {
    document.title = isProfile ? 'Profile' : 'Edit Resource'
  }, [isProfile])

  useEffect(() => {
    setIsProfile(userId === currentUser.id)
  }, [currentUser, userId])

  const handleSubmit = async ({ formData }) => {
    const data = {
      ...initialState,
      ...formData,
    }

    if (newUser) {
      dispatch(createUser(data))
      dispatch(fetchUsers())
    } else {
      dispatch(updateUser(data.id, data))
    }

    setFinished(true)
  }

  clonedSchema.properties.role_name.enum = rolesData.map(r => r.name)

  // Cannot edit your own role
  if (isProfile || userId === currentUser.id) {
    clonedSchema.properties.role_name.readOnly = true
  }

  return (
    <>
      <BreadcrumbGen paths={resourcePaths} />

      <Paper className={classNames(classes.paper, 'users__formContainer')}>
        <Typography
          variant="h5"
          gutterBottom
          className={classNames(classes.title, 'users__formTitle')}
        >
          {isProfile ? 'Profile' : userId ? 'Edit User' : 'Create User'}
        </Typography>
        {finished && <Redirect to="/admin/users" />}
        {(!newUser && userData) || newUser ? (
          <Form
            noHtml5Validate
            showErrorList={false}
            schema={clonedSchema}
            uiSchema={uiSchema}
            formData={userData}
            onSubmit={handleSubmit}
            className="users__form"
          >
            <Box mt={2}>
              <Button
                variant="contained"
                color="default"
                className={classes.cancelButton}
                onClick={() => setFinished(true)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Form>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </>
  )
}

export default withStyles(styles)(FormContainer)
