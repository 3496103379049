import {
  FETCH_RESOURCES_BEGIN,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  UPDATE_RESOURCE_SUCCESS,
} from '../../actions/resourceActions'

import initialState from './initialState'

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESOURCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.resources,
      }

    case FETCH_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    case UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.accountId === action.payload.accountId) {
            return {
              ...item,
              ...action.payload,
            }
          }

          return item
        }),
      }

    default:
      return state
  }
}
