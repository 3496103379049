import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { LinearProgress, Paper } from '@material-ui/core'
import apiService from '../../services/api'
import ResourceAttrition from '../../components/ResourceAttrition'

const styles = () => ({
  fullHeight: {
    height: 'calc(100% - 60px)',
  },
})

const ResourceAttritionContainer = ({ classes }) => {
  const [resources, setResources] = useState(null)
  const [resourcesLoading, setResourcesLoading] = useState(null)

  useEffect(() => {
    document.title = 'Resource Attrition'
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setResourcesLoading(true)

        const result = await apiService.get(null, `resource-attrition`)

        setResourcesLoading(false)
        setResources(result.data)
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  return (
    <Paper
      className={classNames('resourceAttrition', resourcesLoading && 'resourceAttrition--loading', classes.fullHeight)}
    >
      {resources ? <ResourceAttrition resources={resources} title="Truepers Attrition" /> : <LinearProgress />}
    </Paper>
  )
}

export default withStyles(styles)(ResourceAttritionContainer)
