import apiService from '../services/api'
import { errorNotification, successNotification } from './notificationActions'

export const FETCH_RESOURCES_BEGIN = 'FETCH_RESOURCES_BEGIN'
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS'
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE'

export const UPDATE_RESOURCE_BEGIN = 'UPDATE_RESOURCE_BEGIN'
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS'
export const UPDATE_RESOURCE_FAILURE = 'UPDATE_RESOURCE_FAILURE'

export const fetchResourcesBegin = () => ({
  type: FETCH_RESOURCES_BEGIN,
})

export const fetchResourcesSuccess = (resources) => ({
  type: FETCH_RESOURCES_SUCCESS,
  payload: { resources },
})

export const fetchResourcesFailure = (error) => ({
  type: FETCH_RESOURCES_FAILURE,
  payload: { error },
})

export function fetchResources(options = null) {
  return (dispatch) => {
    dispatch(fetchResourcesBegin())
    return apiService
      .get(null, 'resources', options)
      .then((res) => {
        dispatch(fetchResourcesSuccess(res.data))
        return res.data
      })
      .catch((error) => {
        dispatch(fetchResourcesFailure(error))
        dispatch(errorNotification('There was an error retrieving resources, please try again later.'))
      })
  }
}

export const updateResourceBegin = () => ({
  type: UPDATE_RESOURCE_BEGIN,
})

export const updateResourceSuccess = (payload) => ({
  type: UPDATE_RESOURCE_SUCCESS,
  payload,
})

export const updateResourceFailure = (error) => ({
  type: UPDATE_RESOURCE_FAILURE,
  payload: { error },
})

export function updateResource(resourceId, resourceData) {
  return (dispatch) => {
    dispatch(updateResourceBegin())
    return apiService
      .update(resourceId, resourceData, 'resources')
      .then((res) => {
        dispatch(updateResourceSuccess(resourceData))
        dispatch(successNotification(`Updated Resource: ${resourceData.fullName}!`))
        return res.data[0]
      })
      .catch((error) => {
        dispatch(updateResourceFailure(error))
        dispatch(errorNotification('There was an error updating given resource, please try again later.'))
      })
  }
}
