import apiService from '../services/api'
import { successNotification, errorNotification } from './notificationActions'

export const FETCH_SKILLS_BEGIN = 'FETCH_SKILLS_BEGIN'
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS'
export const FETCH_SKILLS_FAILURE = 'FETCH_SKILLS_FAILURE'
export const REMOVE_SKILL_BEGIN = 'REMOVE_SKILL_BEGIN'
export const REMOVE_SKILL_SUCCESS = 'REMOVE_SKILL_SUCCESS'
export const REMOVE_SKILL_FAILURE = 'REMOVE_SKILL_FAILURE'

export const fetchSkillsBegin = () => ({
  type: FETCH_SKILLS_BEGIN,
})

export const fetchSkillsSuccess = skills => ({
  type: FETCH_SKILLS_SUCCESS,
  payload: { skills },
})

export const fetchSkillsFailure = error => ({
  type: FETCH_SKILLS_FAILURE,
  payload: { error },
})

export function fetchSkills() {
  return dispatch => {
    dispatch(fetchSkillsBegin())
    return apiService
      .get(null, 'skills')
      .then(res => {
        dispatch(fetchSkillsSuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(fetchSkillsFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving items, please try again later.'
          )
        )
      })
  }
}
export const removeSkillBegin = () => ({
  type: REMOVE_SKILL_BEGIN,
})

export const removeSkillSuccess = skillId => ({
  type: REMOVE_SKILL_SUCCESS,

  payload: skillId,
})

export const removeSkillFailure = error => ({
  type: REMOVE_SKILL_FAILURE,

  payload: { error },
})

export function removeSkill(skillId, skillName) {
  return dispatch => {
    dispatch(removeSkillBegin())
    return apiService
      .remove(skillId, 'skills')
      .then(res => {
        dispatch(removeSkillSuccess(skillId))
        dispatch(successNotification(`Skills: "${skillName}" deleted`))
        return res
      })
      .catch(error => {
        dispatch(removeSkillFailure(error))
        dispatch(
          errorNotification(
            `There was an error deleting Skills: "${skillName}"`
          )
        )
        console.error('[error]', error)
      })
  }
}
