import {
  FETCH_SYNC_BEGIN,
  FETCH_SYNC_SUCCESS,
  FETCH_SYNC_FAILURE,
} from '../../actions/syncActions'

import initialState from './initialState'

export default function syncReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYNC_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.sync,
      }

    case FETCH_SYNC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    default:
      return state
  }
}
