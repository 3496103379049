import axios from 'axios'

const request = opts => {
  let currentUser = localStorage.getItem('currentUser')
  let token = null

  if (currentUser) {
    currentUser = JSON.parse(currentUser)
    token = currentUser.token
  }

  return axios.request({
    ...opts,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const get = (id, type, params) => {
  return request({
    url: `/api/${type}${id ? `/${id}` : ''}`,
    method: 'get',
    params: {
      ...params,
    },
  })
}

const remove = (id, type) => {
  return request({
    url: `/api/${type}/${id}`,
    method: 'delete',
  })
}

const getAll = (type, params) => {
  return request({
    url: `/api/${type}`,
    method: 'get',
    params: {
      ...params,
    },
  })
}

const create = (data, type) => {
  return request({
    url: `/api/${type}`,
    method: 'post',
    data: {
      ...data,
    },
  })
}

const update = (id, data, type) => {
  return request({
    url: `/api/${type}/${id}`,
    method: 'put',
    data: {
      ...data,
    },
  })
}

export default {
  get,
  remove,
  getAll,
  create,
  update,
}
