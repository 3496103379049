import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { ClickAwayListener, Fade, Paper, Popper, Typography } from '@material-ui/core'

const styles = (theme) => ({
  summaryText: {
    cursor: 'pointer',
    overflow: 'hidden',
  },
  popper: {
    zIndex: 100,
    width: '35vw',
  },
  popperContent: {
    padding: theme.spacing(3),
  },
})

const Text = ({ classes, value, className, option = {} }) => {
  const tableRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)

  function openPopper() {
    setAnchorEl(anchorEl ? null : tableRef.current)
  }

  function handleClickAway() {
    setAnchorEl(null)
  }

  function handleEscKey(e) {
    if (!!anchorEl && e.key === 'Escape') {
      handleClickAway()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleEscKey)

    return () => {
      document.removeEventListener('keyup', handleEscKey)
    }
  })

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const maxLength = option.maxLength || 80

  const initialText = value ? value : '-'
  const longText = initialText.length > maxLength
  const textValue = longText ? initialText.substring(0, maxLength) + '...' : initialText

  return (
    <div className={className} ref={tableRef}>
      {!longText ? (
        <Typography variant="body2">{textValue}</Typography>
      ) : (
        <>
          <Typography className={classes.summaryText} variant="body2" onClick={openPopper}>
            {textValue}
          </Typography>
          <Popper
            className={classes.popper}
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            placement={option.placement || 'left'}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
              arrow: {
                enabled: true,
              },
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper variant="outlined" elevation={5}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Typography className={classes.popperContent} variant="body2">
                      {initialText}
                    </Typography>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(Text)
