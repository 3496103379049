import { errorNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_METRIC_TYPES_BEGIN = 'FETCH_METRIC_TYPES_BEGIN'
export const FETCH_METRIC_TYPES_SUCCESS = 'FETCH_METRIC_TYPES_SUCCESS'
export const FETCH_METRIC_TYPES_FAILURE = 'FETCH_METRIC_TYPES_FAILURE'

export const fetchMetricTypesBegin = () => ({
  type: FETCH_METRIC_TYPES_BEGIN,
})

export const fetchMetricTypesSuccess = (metricTypes) => ({
  type: FETCH_METRIC_TYPES_SUCCESS,
  payload: { metricTypes },
})

export const fetchMetricTypesFailure = (error) => ({
  type: FETCH_METRIC_TYPES_FAILURE,
  payload: { error },
})

export function fetchMetricTypes(reference = null) {
  return (dispatch) => {
    dispatch(fetchMetricTypesBegin())
    return apiService
      .get(null, 'metric-types', { reference })
      .then((res) => {
        dispatch(fetchMetricTypesSuccess(res.data))
        return res.data
      })
      .catch((error) => {
        dispatch(fetchMetricTypesFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving the metric types, please try again later.'
          )
        )
      })
  }
}
