import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LinearProgress from '@material-ui/core/LinearProgress'
import ProjectsIcon from '../../assets/icons/projects.svg'

import { fetchProjects } from '../../actions/projectActions'
import InfoCard from '../Cards/InfoCard'

const ActiveProjects = () => {
  const dispatch = useDispatch()

  const [activeProjects, setActiveProjects] = useState(0)

  const projects = useSelector((state) => state.projects.items)
  const loading = useSelector((state) => state.projects.loading)

  useEffect(() => {
    const activeProjects = projects.filter((p) => p.status === 'ACTIVE')

    setActiveProjects(activeProjects.length)
  }, [projects])

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <InfoCard
          icon={() => <img src={ProjectsIcon} alt="Clients Icon" style={{ width: 50, height: 50 }} />}
          title="Active Projects"
          content={activeProjects}
          className="activeProjects"
        />
      )}
    </>
  )
}

export default ActiveProjects
