import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import Form from '@rjsf/material-ui';
import classNames from 'classnames'

import {
  Box,
  Button,
  Paper,
  LinearProgress,
  Typography,
} from '@material-ui/core'

import apiService from '../../services/api'

import BreadcrumbGen from '../../components/Common/BreadcrumbGen'

import { fetchSkills } from '../../actions/skillActions'
import { fetchSeniority } from '../../actions/seniorityActions'
import {
  errorNotification,
  successNotification,
} from '../../actions/notificationActions'

const types = {
  skills: {
    action: fetchSkills,
  },
  seniority: {
    action: fetchSeniority,
  },
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  itemTypeTitle: {
    textTransform: 'capitalize',
  },
})

const SkillSeniorityForm = ({ classes, match }) => {
  const [itemData, setItemData] = useState(null)
  const [newItem, setNewItem] = useState(false)
  const [finished, setFinished] = useState(false)

  const itemId = match.params.id
  const itemType = match.url.split('/')[2]

  const { schema, initialState } = require(`../../models/${itemType}`)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async id => {
      const result = await apiService.get(id, itemType)

      setItemData(result.data[0])
    }

    if (itemId) {
      fetchData(itemId)
      setNewItem(false)
    } else {
      setNewItem(true)
      setItemData(initialState)
    }
  }, [initialState, itemId, itemType])

  const handleSubmit = async ({ formData }) => {
    const data = {
      ...initialState,
      name: formData.name,
    }

    try {
      if (newItem) {
        await apiService.create(data, itemType)
        dispatch(successNotification(`Created new itemData!`))
      } else {
        await apiService.update(match.params.id, data, itemType)
        dispatch(successNotification(`Updated ${itemType}: ${data.name}!`))
      }

      dispatch(types[itemType].action())
      setFinished(true)
    } catch (e) {
      dispatch(errorNotification(`There was a problem creating a itemData!`))
      console.error(e)
    }
  }

  const resourcePaths = [
    { name: 'Home', route: '/' },
    { name: itemType, route: `/admin/${itemType}` },
    {
      name: `${
        match && match.params && match.params.id
          ? `Edit ${itemType}: ${match.params.id}`
          : 'Create'
      }`,
    },
  ]

  return (
    <>
      <BreadcrumbGen paths={resourcePaths} />

      <Paper
        className={classNames(
          classes.paper,
          'skillsAndSeniority__formContainer'
        )}
      >
        <Typography
          variant="h5"
          gutterBottom
          className={classNames(classes.title, 'skillsAndSeniority__formTitle')}
        >
          {itemId ? 'Edit' : 'Create'}{' '}
          <span className={classes.itemTypeTitle}>{itemType}</span>
        </Typography>
        {finished && <Redirect to="/admin/skills-and-seniority" />}
        {itemData || newItem ? (
          <Form
            showErrorList={false}
            schema={schema}
            formData={itemData}
            onSubmit={handleSubmit}
            className="skillsAndSeniority__form"
          >
            <Box mt={2} className="skillsAndSeniority__formActions">
              <Button
                variant="contained"
                color="default"
                className={classNames(
                  classes.cancelButton,
                  'skillsAndSeniority__cancel'
                )}
                onClick={() => setFinished(true)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="skillsAndSeniority__submit"
              >
                Submit
              </Button>
            </Box>
          </Form>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </>
  )
}

export default withRouter(withStyles(styles)(withSnackbar(SkillSeniorityForm)))
