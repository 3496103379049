import {
  FETCH_CONTRACT_TYPES_BEGIN,
  FETCH_CONTRACT_TYPES_SUCCESS,
  FETCH_CONTRACT_TYPES_FAILURE,
} from '../../actions/contractTypesActions'

import initialState from './initialState'

export default function contractTypeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTRACT_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_CONTRACT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.contractTypes,
      }

    case FETCH_CONTRACT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    default:
      return state
  }
}
