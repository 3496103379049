import React, { useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'

import { Typography, Card, CardContent } from '@material-ui/core'

import Benched from '../../components/Home/Benched'
import HeadCount from '../../components/Home/HeadCount'
import PlannedHours from '../../components/Home/PlannedHours'
import ProjectStatus from '../../components/Home/ProjectStatus'
import ResourceAttrition from '../../components/Home/ResourceAttrition'
import ResourcePerformance from '../../components/Home/ResourcePerformance'
import ActiveProjects from '../../components/Home/ActiveProjects'
import ActiveClients from '../../components/Home/ActiveClients'

import Can from '../../components/Common/Can'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  gridCell: {
    marginBottom: theme.spacing(2),
  },
  gridRow: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  card: {
    textAlign: 'center',
    height: 380,
  },
  cardContent: {
    maxHeight: 380,
    overflow: 'hidden',
    paddingBottom: 0,
    height: '100%',
    width: '100%',
  },
  cardStatus: {
    overflow: 'auto',
  },
  smallCard: {
    height: 'auto',
  },
  smallCardContent: {
    maxHeight: 'auto',
    padding: '0 !important',
  },
  container: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridGap: theme.spacing(3),
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    },
  },
})

const HomeContainer = ({ classes, currentUser }) => {
  useEffect(() => {
    document.title = 'Home'
  }, [])

  return (
    <div className={classNames(classes.root, 'home')}>
      <div className={classNames(classes.gridContainer, 'home__grid')}>
        <div className={classNames(classes.column, 'home__gridColumn')}>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, classes.smallCard, 'home__card', 'home__card--small')}>
              <CardContent className={classNames(classes.cardContent, classes.smallCardContent, 'home__cardContent')}>
                <>
                  <Can
                    role={currentUser.role_name}
                    perform="clients:view"
                    yes={() => (
                      <div className={classes.container}>
                        <ActiveClients />
                      </div>
                    )}
                    no={() => (
                      <CardContent className="home__cardContent">
                        <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                        </Typography>
                      </CardContent>
                    )}
                  />
                </>
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="project-status:view"
                  yes={() => <HeadCount />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, classes.cardStatus, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="project-status:view"
                  yes={() => <ProjectStatus />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="benched:view"
                  yes={() => <Benched />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </div>
        <div className={classNames(classes.column, 'home__gridColumn')}>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, classes.smallCard, 'home__card', 'home__card--small')}>
              <CardContent className={classNames(classes.cardContent, classes.smallCardContent, 'home__cardContent')}>
                <>
                  <Can
                    role={currentUser.role_name}
                    perform="projects:view"
                    yes={() => (
                      <div className={classes.container}>
                        <ActiveProjects />
                      </div>
                    )}
                    no={() => (
                      <CardContent className="home__cardContent">
                        <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                        </Typography>
                      </CardContent>
                    )}
                  />
                </>
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="planned-hours:view"
                  yes={() => <PlannedHours />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, classes.cardStatus, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="resource-performance:view"
                  yes={() => <ResourcePerformance />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
          <div className={classNames(classes.gridCell, 'home__gridCell')}>
            <Card className={classNames(classes.card, 'home__card')}>
              <CardContent className={classNames(classes.cardContent, classes.cardStatus, 'home__cardContent')}>
                <Can
                  role={currentUser.role_name}
                  perform="resource-attrition:view"
                  yes={() => <ResourceAttrition />}
                  no={() => (
                    <CardContent className="home__cardContent">
                      <Typography className={classes.title} color="textSecondary" variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis, illum vero nostrum dolores
                        cum, non incidunt repudiandae omnis, voluptas quisquam iste ratione dolorum ducimus tempora.
                        Reprehenderit veniam officiis maxime consequuntur.
                      </Typography>
                    </CardContent>
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps, {})(withStyles(styles)(withSnackbar(HomeContainer)))
