import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import EditIcon from '@material-ui/icons/Edit'
import { LinearProgress, IconButton } from '@material-ui/core'

import Can from '../../components/Common/Can'
import Clients from '../../components/Clients'

import { fetchClients } from '../../actions/clientActions'

const styles = (theme) => ({
  div: {
    flexGrow: 1,
    overflow: 'auto',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  anchor: {
    color: '#fff',
    lineHeight: 1,
    textDecoration: 'none',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

const ClientsContainer = ({ classes }) => {
  const dispatch = useDispatch()

  const fetchTries = useRef(0)

  const clients = useSelector((state) => state.clients.items)
  const loading = useSelector((state) => state.clients.loading)

  const currentUser = useSelector((state) => state.currentUser)

  useEffect(() => {
    document.title = 'Client List'
  }, [])

  useEffect(() => {
    if ((!clients || !clients.length) && fetchTries.current === 0) {
      fetchTries.current++
      dispatch(fetchClients())
    }
  }, [dispatch, clients])

  const actionButtons = (clientId) => (
    <div className="clients__actionButtons">
      <Can
        role={currentUser.role_name}
        perform="clients:edit"
        yes={() => (
          <Link to={`/admin/clients/${clientId}`} className="clients__edit">
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Link>
        )}
        no={() => null}
      />
    </div>
  )

  return (
    <div className="clients">
      {!loading ? (
        <Clients clients={clients} actionButtons={actionButtons} title="Client List" />
      ) : (
        <LinearProgress className={classes.progress} />
      )}
    </div>
  )
}

export default withStyles(styles)(ClientsContainer)
