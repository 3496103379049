import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Marker } from '@urbica/react-map-gl'

const styles = theme => ({
  clusterMarker: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: '#00c6c0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
})

const ClusterMarker = ({
  longitude,
  latitude,
  pointCount,
  classes,
  clusterId,
  onClick,
}) => {
  const handleMarkerClick = () => {
    onClick(longitude, latitude, clusterId)
  }

  return (
    <Marker longitude={longitude} latitude={latitude}>
      <div onClick={handleMarkerClick} className={classes.clusterMarker}>
        {pointCount}
      </div>
    </Marker>
  )
}

export default withStyles(styles)(ClusterMarker)
