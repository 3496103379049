import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const SkillAndSeniority = ({ items, actionButtons, options }) => {
  const opts = {
    download: false,
    filter: false,
    filterType: false,
    selectableRows: 'none',
    pagination: true,
    print: false,
    tableBodyHeight: 'calc(100vh - 340px)',
    viewColumns: false,
    ...options,
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Actions',
      name: 'action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const itemId = tableMeta.rowData[0] ? tableMeta.rowData[0] : ''
          return actionButtons(itemId)
        },
      },
    },
  ]

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          textAlign: 'right',
          '& div': {
            paddingRight: 40
          }
        }
      }
    }
  })

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={items}
        columns={columns}
        options={opts}
        className='skillAndSeniority__chart'
      />
    </MuiThemeProvider>
  )
}

export default SkillAndSeniority
