import apiService from '../services/api'

import { successNotification, errorNotification } from './notificationActions'

export const FETCH_SENIORITY_BEGIN = 'FETCH_SENIORITY_BEGIN'
export const FETCH_SENIORITY_SUCCESS = 'FETCH_SENIORITY_SUCCESS'
export const FETCH_SENIORITY_FAILURE = 'FETCH_SENIORITY_FAILURE'

export const REMOVE_SENIORITY_BEGIN = 'REMOVE_SENIORITY_BEGIN'
export const REMOVE_SENIORITY_SUCCESS = 'REMOVE_SENIORITY_SUCCESS'
export const REMOVE_SENIORITY_FAILURE = 'REMOVE_SENIORITY_FAILURE'

export const fetchSeniorityBegin = () => ({
  type: FETCH_SENIORITY_BEGIN,
})

export const fetchSenioritySuccess = seniority => ({
  type: FETCH_SENIORITY_SUCCESS,
  payload: { seniority },
})

export const fetchSeniorityFailure = error => ({
  type: FETCH_SENIORITY_FAILURE,
  payload: { error },
})

export function fetchSeniority() {
  return dispatch => {
    dispatch(fetchSeniorityBegin())
    return apiService
      .get(null, 'seniority')
      .then(res => {
        dispatch(fetchSenioritySuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(fetchSeniorityFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving items, please try again later.'
          )
        )
      })
  }
}
export const removeSeniorityBegin = () => ({
  type: REMOVE_SENIORITY_BEGIN,
})

export const removeSenioritySuccess = seniorityId => ({
  type: REMOVE_SENIORITY_SUCCESS,
  payload: seniorityId,
})

export const removeSeniorityFailure = error => ({
  type: REMOVE_SENIORITY_FAILURE,
  payload: { error },
})

export function removeSeniority(seniorityId, seniorityName) {
  return dispatch => {
    dispatch(removeSeniorityBegin())
    return apiService
      .remove(seniorityId, 'seniority')
      .then(res => {
        dispatch(removeSenioritySuccess(seniorityId))
        dispatch(successNotification(`Seniority: "${seniorityName}" deleted`))
        return res
      })
      .catch(error => {
        dispatch(removeSeniorityFailure(error))
        dispatch(
          errorNotification(
            `There was an error deleting Seniority: "${seniorityName}"`
          )
        )
        console.error('[error]', error)
      })
  }
}
