import moment from 'moment'

const today = moment().format('YYYY-MM-DD')

export const schema = {
  type: 'object',
  required: [],
  properties: {
    accountId: { type: 'string', title: 'Account ID', readOnly: true },
    fullName: { type: 'string', title: 'Name', readOnly: true },
    username: { type: 'string', title: 'Username' },
    email: { type: 'string', title: 'Email' },
    type: { type: 'string', title: 'Contract Type', readOnly: true },
    location: {
      type: 'string',
      title: 'Location',
      default: '',
      placeholder: 'What is your location?',
    },
    active: { type: 'boolean', title: 'Active', readOnly: true },
    bench: { type: 'boolean', title: 'Bench' },
    benched_at: { type: 'string', title: 'Benched At', default: today },
    skills: {
      type: 'string',
      title: 'Skills',
      values: [],
      placeholder: 'Select your skills',
    },
  },
}

export const uiSchema = {
  active: {
    'ui:widget': 'customSwitch',
  },
  bench: {
    'ui:widget': 'customSwitch',
  },
  benched_at: {
    'ui:widget': 'hidden',
  },
  skills: {
    'ui:widget': 'customMultiSelect',
  },
  location: {
    'ui:widget': 'customPlaceAutocomplete',
  },
}

export const initialState = {
  username: '',
  fullName: '',
  email: '',
  type: '',
  location: '',
  skills: '',
  active: false,
  bench: false,
  benched_at: today,
}
