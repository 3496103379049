import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  REMOVE_PROJECT_BEGIN,
  REMOVE_PROJECT_FAILURE,
  REMOVE_PROJECT_SUCCESS,
} from '../../actions/projectActions'

import initialState from './initialState'

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.projects,
      }

    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    case REMOVE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(projects => projects.id !== action.payload),
      }

    case REMOVE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
