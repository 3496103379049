export const schema = {
  type: 'object',
  required: ['location'],
  properties: {
    name: { type: 'string', title: 'Client Name', default: '', readOnly: true },
    status: { type: 'string', title: 'Status', default: '', readOnly: true },
    projectManager: {
      type: 'string',
      title: 'Project Manager',
      default: '',
      readOnly: true,
    },
    location: {
      type: 'string',
      title: 'Location',
      default: '',
      placeholder: 'What is your location?',
    },
    key: { type: 'string', title: 'Jira Key', default: '' },
  },
}

export const uiSchema = {
  location: {
    'ui:widget': 'customPlaceAutocomplete',
  },
}

export const initialState = {
  name: '',
  location: '',
  status: '',
  projects: [],
}
