import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LinearProgress, Paper } from '@material-ui/core'

import apiService from '../../services/api'
import ProjectStatus from '../../components/ProjectStatus'

const ProjectStatusHistory = () => {
  const [projectStatusHistory, setProjectStatusHistory] = useState(null)
  const [projectStatusHistoryLoading, setProjectStatusHistoryLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const projectStatusHistory = await apiService.get(null, 'project-status/history', {})

      const data = projectStatusHistory.data.sort((a, b) => b.createdAt - a.createdAt)

      setProjectStatusHistory(data)
      setProjectStatusHistoryLoading(false)
    }

    setProjectStatusHistoryLoading(true)
    fetchData()

    document.title = 'Project Status History Details'
  }, [])

  return (
    <Paper
      className={classNames('projectStatusHistory', projectStatusHistoryLoading && 'projectStatusHistory--loading')}
    >
      {projectStatusHistoryLoading ? (
        <LinearProgress />
      ) : (
        <ProjectStatus projectMetrics={projectStatusHistory} title="Project Status History Details" />
      )}
    </Paper>
  )
}

export default ProjectStatusHistory
