import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './components/Common/PrivateRoute'

import Dashboard from './containers/Dashboard'
import Home from './containers/Home'

import Allocation from './containers/Reports/Allocation'
import PlannedHours from './containers/Reports/PlannedHours'
import ResourcesLocation from './containers/Reports/ResourcesLocation'

import Users from './containers/Users'
import UsersForm from './containers/Users/Form'

import Resources from './containers/Resources'
import ResourceForm from './containers/Resources/Form'

import Projects from './containers/Projects'
import ProjectsForm from './containers/Projects/Form'

import Clients from './containers/Clients'
import ClientsForm from './containers/Clients/Form'

import SkillAndSeniority from './containers/SkillAndSeniority'
import SkillAndSeniorityForm from './containers/SkillAndSeniority/Form'

import ProjectStatus from './containers/ProjectStatus'
import ProjectStatusHistory from './containers/ProjectStatus/history'
import ProjectStatusReport from './containers/ProjectStatus/report'

import ResourcePerformance from './containers/ResourcePerformance'
import ResourceAttrition from './containers/ResourceAttrition'

import ResourcePerformanceHistory from './containers/ResourcePerformance/history'
import ResourcePerformanceReport from './containers/ResourcePerformance/report'

import SignIn from './containers/SignIn'

import Audits from './containers/Audits'

import Pricing from './containers/Pricing'

const Routes = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/pricing" component={Pricing} />

      <Route path="/admin/signin" component={SignIn} />
      <Redirect exact from="/admin" to="/admin/signin" />

      <Redirect exact from="/" to="/admin/signin" />
      <Dashboard>
        <Switch>
          <PrivateRoute exact path="/admin/dashboard" component={Home} action="dashboard:view" />
          <PrivateRoute exact path="/admin/allocation" component={Allocation} action="allocation:view" />
          <PrivateRoute exact path="/admin/planned-hours" component={PlannedHours} action="planned-hours:view" />

          <PrivateRoute
            exact
            path="/admin/resources-location"
            component={ResourcesLocation}
            action="resources-location:view"
          />

          <PrivateRoute exact path="/admin/resources-attrition" component={ResourceAttrition} action="reports:view" />

          <PrivateRoute exact path="/admin/project-status" component={ProjectStatus} action="project-status:view" />
          <PrivateRoute
            exact
            path="/admin/project-status/history"
            component={ProjectStatusHistory}
            action="project-status:view"
          />
          <PrivateRoute
            exact
            path="/admin/project-status/new"
            component={ProjectStatusReport}
            action="project-status:view"
          />

          <PrivateRoute
            exact
            path="/admin/resource-performance"
            component={ResourcePerformance}
            action="resource-performance:view"
          />
          <PrivateRoute
            exact
            path="/admin/resource-performance/history"
            component={ResourcePerformanceHistory}
            action="resource-performance:view"
          />
          <PrivateRoute
            exact
            path="/admin/resource-performance/new"
            component={ResourcePerformanceReport}
            action="resource-performance:view"
          />
          <PrivateRoute exact path="/admin/users" component={Users} action="users:view" />
          <PrivateRoute exact path="/admin/users/:id" component={UsersForm} action="users:edit" />
          <PrivateRoute exact path="/admin/profile" component={UsersForm} action="users:edit" />
          <PrivateRoute exact path="/admin/resources" component={Resources} action="resources:view" />
          <PrivateRoute exact path="/admin/resources/:id" component={ResourceForm} action="resources:edit" />

          <PrivateRoute exact path="/admin/projects/new" component={ProjectsForm} action="projects:create" />
          <PrivateRoute exact path="/admin/projects/:id" component={ProjectsForm} action="projects:edit" />
          <PrivateRoute exact path="/admin/projects" component={Projects} action="projects:view" />

          <PrivateRoute exact path="/admin/clients/new" component={ClientsForm} action="clients:create" />
          <PrivateRoute exact path="/admin/clients/:id" component={ClientsForm} action="clients:edit" />
          <PrivateRoute exact path="/admin/clients" component={Clients} action="clients:view" />

          <PrivateRoute exact path="/admin/skills/new" component={SkillAndSeniorityForm} action="skills:create" />
          <PrivateRoute exact path="/admin/skills/:id" component={SkillAndSeniorityForm} action="skills:edit" />
          <PrivateRoute exact path="/admin/seniority/new" component={SkillAndSeniorityForm} action="seniority:create" />
          <PrivateRoute exact path="/admin/seniority/:id" component={SkillAndSeniorityForm} action="seniority:edit" />

          <PrivateRoute
            exact
            path="/admin/skills-and-seniority"
            component={SkillAndSeniority}
            action="skills-and-seniority:view"
          />

          <PrivateRoute exact path="/admin/audits" component={Audits} action="audits:view" />
        </Switch>
      </Dashboard>
    </Switch>
  </BrowserRouter>
)

export default Routes
