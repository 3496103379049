import axios from 'axios'
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'

import { SnackbarProvider } from 'notistack'

import Routes from './Routes'
import Notifier from './components/Notifier'

import signinActions from './actions/signinActions'

import store from './store/store'

axios.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      store.dispatch(signinActions.signOut())
    } else {
      return Promise.reject(error)
    }
  }
)

const history = createBrowserHistory()

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <>
              <Notifier />
              {Routes}
            </>
          </SnackbarProvider>
        </>
      </Router>
    </Provider>
  )
}

export default App
