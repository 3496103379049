import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Tabs, Tab } from '@material-ui/core'

import { fetchSkills } from '../../actions/skillActions'
import { fetchSeniority } from '../../actions/seniorityActions'

import List from './List'

const styles = theme => ({
  container: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(),
    height: 'calc(100vh - 100px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 100px)',
    },
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
})

const tabs = [
  { collection: 'skills', label: 'Skills', action: fetchSkills },
  { collection: 'seniority', label: 'Seniority', action: fetchSeniority },
]

const SkillsAndSeniority = ({ classes }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const tabChange = (event, tab) => {
    setSelectedTab(tab)
  }

  return (
    <div className='skillsAndSeniority'>
      <Tabs
        value={selectedTab}
        onChange={tabChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        classes={{
          root: 'skillsAndSeniority__tabs',
        }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.collection}
            label={tab.label}
            className={classNames(
              'skillsAndSeniority__tabItem',
              `skillsAndSeniority__tabItem--${tab.label.toLowerCase()}`
            )}
          />
        ))}
      </Tabs>
      <List selectedItemType={tabs[selectedTab]} />
    </div>
  )
}

export default withStyles(styles)(SkillsAndSeniority)
