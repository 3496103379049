import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import apiService from '../services/api'

const styles = theme => ({
  avatarContainer: {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
  },
  bigAvatar: {
    width: 48,
    height: 48,
  },
  drawer: {
    width: 350,
    textAlign: 'center',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  skillsLabel: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
  },
  extraInfo: {
    margin: theme.spacing(1),
  },
  userInfoList: {
    paddingTop: 0,
  },
  userInfo: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  userInfoLink: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 250,
    display: 'block',
  },
  userInfoItem: {
    paddingTop: 0,
    width: '270',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    overflow: 'hidden',
  },
  paper: {
    margin: theme.spacing(1),
  },
  skillContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 0,
  },
  planWrapper: {
    padding: theme.spacing(1, 2),
  },
  planItem: {
    width: '270',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    overflow: 'hidden',
  },
  planItemContainer: {
    margin: theme.spacing(3),
    marginTop: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontWeight: 'bold',
  },
  location: {
    whiteSpace: 'normal',
    wordBreak: 'break-all',
  },
})

const ResourceDrawer = ({ resource, handleClose, classes }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [extraInfo, setExtraInfo] = useState({})

  useEffect(() => {
    setOpen(!!resource)
  }, [resource])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiService.get(null, `resources/${resource.id}`)

        setExtraInfo(result.data[0])
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }

    if (resource && resource.id) {
      fetchData()
    }
  }, [resource])

  const buildPlans = () => {
    if (loading) {
      return <LinearProgress />
    }

    if (!loading && extraInfo && extraInfo.plans && extraInfo.plans.length) {
      return extraInfo.plans.map(plan => (
        <Paper
          key={plan.project_id}
          className={classNames(classes.planItemContainer, 'plan__container')}
        >
          <Typography
            className={classNames(classes.planItem, 'planItem__text')}
          >
            <span className={classes.title}>Name: </span>
            {plan.project_name}
          </Typography>
          <Typography
            className={classNames(classes.planItem, 'planItem__text')}
          >
            <span className={classes.title}>Client: </span>
            {plan.project_client}
          </Typography>
          <Typography
            className={classNames(classes.planItem, 'planItem__text')}
          >
            <span className={classes.title}>Work Order: </span>
            {plan.project_workOrder}
          </Typography>
          <Typography
            className={classNames(classes.planItem, 'planItem__text')}
          >
            <span className={classes.title}>Start: </span>
            {moment(plan.plan_start_date).format('YYYY-MM-DD')}
          </Typography>
          <Typography
            className={classNames(classes.planItem, 'planItem__text')}
          >
            <span className={classes.title}>End: </span>
            {moment(plan.plan_end_date).format('YYYY-MM-DD')}
          </Typography>
        </Paper>
      ))
    }

    return <Typography>No active plans</Typography>
  }

  return !!resource ? (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      className="resourceDrawer"
    >
      <div className={classes.drawer}>
        <div className={classes.avatarContainer}>
          <Avatar
            alt={resource.fullName}
            src={resource.avatars['48x48']}
            className={classes.bigAvatar}
          />
          <div className={classes.userInfo}>
            <Typography variant="subtitle1">{resource.fullName}</Typography>
            <Link
              href={`https://truelogic.atlassian.net/people/${resource.accountId}`}
              target="_blank"
              rel="noopener"
            >
              <ListItemText
                variant="inherit"
                primary={resource.email}
                className={classes.userInfoLink}
              />
            </Link>
          </div>
        </div>
        <Divider />
        <Typography variant="subtitle1" className={classes.extraInfo}>
          User Info
        </Typography>
        <List className={classes.userInfoList}>
          <ListItem className={classes.userInfoItem}>
            <ListItemText className={classes.userInfoItem}>
              <span className={classes.title}>Group: </span>
              {resource.type}
            </ListItemText>
          </ListItem>
          {resource.location && (
            <ListItem className={classes.userInfoItem}>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" className={classes.location}>
                    <span className={classes.title}>Location: </span>
                    {resource.location}
                  </Typography>
                }
              />
            </ListItem>
          )}
          {resource.skills && resource.skills.length ? (
            <ListItem className={classes.skillContainer}>
              <ListItemText
                classes={{ primary: classes.title }}
                primary="Skills:"
              />
              <div>
                {resource.skills.map(data => (
                  <Chip
                    color="primary"
                    key={data.id}
                    label={data.name}
                    className={classes.chip}
                  />
                ))}
              </div>
            </ListItem>
          ) : null}
        </List>
        <Divider />
        <Typography variant="subtitle1" className={classes.planWrapper}>
          Active Plans
        </Typography>
        {buildPlans()}
      </div>
    </Drawer>
  ) : null
}

export default withStyles(styles)(ResourceDrawer)
