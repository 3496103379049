import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { Collapse, List, ListItem, ListItemIcon, ListItemText, Divider, Link, ListSubheader } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'

import AllocationIcon from '../../assets/icons/allocation.svg'
import ClientsIcon from '../../assets/icons/clients.svg'
import DashboardIcon from '../../assets/icons/dashboard.svg'
import HistoryIcon from '../../assets/icons/history.svg'
import NewReportIcon from '../../assets/icons/new_report.svg'
import PlannedHoursIcon from '../../assets/icons/planned_hours.svg'
import ReportsIcon from '../../assets/icons/reports.svg'
import ResourceLocationIcon from '../../assets/icons/resources_location.svg'
import ProjectsIcon from '../../assets/icons/projects.svg'
import ProjectStatusIcon from '../../assets/icons/project_status.svg'
import ResourcesIcon from '../../assets/icons/resources.svg'
import ResourcePerformanceIcon from '../../assets/icons/resource_performance.svg'
import SkillsAndSeniorityIcon from '../../assets/icons/skills_and_seniority.svg'
import StatusAndPerformanceIcon from '../../assets/icons/status_and_performance.svg'
import UsersIcons from '../../assets/icons/users.svg'
import ResourceAttritionIcon from '../../assets/icons/resource_attrition.svg'

import signinActions from '../../actions/signinActions'

import Can from './Can'

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  list: {
    paddingTop: theme.spacing(3),
  },
  subList: {
    paddingLeft: theme.spacing(2),
  },
  fontAwesomeIcon: {
    paddingLeft: 2,
  },
  menuIcon: {
    height: '1.5em',
    width: '1.5em',
  },
})

const LeftMenu = ({ classes, location, rehydrateCurrentUser, currentUser, clickCallBack }) => {
  const [projectStatusOpen, setProjectStatusOpen] = useState(false)
  const [resourcePerformanceOpen, setResourcePerformanceOpen] = useState(false)
  const [toggleReportsOpen, setToggleReports] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('project-status')) {
      setProjectStatusOpen(true)
    } else {
      setProjectStatusOpen(false)
    }

    if (location.pathname.includes('resource-performance')) {
      setResourcePerformanceOpen(true)
    } else {
      setResourcePerformanceOpen(false)
    }

    if (
      location.pathname.includes('planned-hours') ||
      location.pathname.includes('allocation') ||
      location.pathname.includes('resources-location') ||
      location.pathname.includes('resources-attrition')
    ) {
      setToggleReports(true)
    } else {
      setToggleReports(false)
    }
  }, [location.pathname])

  useEffect(() => {
    rehydrateCurrentUser()
  }, [rehydrateCurrentUser])

  const toggleProjectStatus = () => {
    setProjectStatusOpen(!projectStatusOpen)
  }

  const toggleResourcePerformance = () => {
    setResourcePerformanceOpen(!resourcePerformanceOpen)
  }

  const toggleReports = () => {
    setToggleReports(!toggleReportsOpen)
  }

  return (
    <List className={classNames(classes.list, 'menu')} id="menu">
      <Can
        role={currentUser.role_name}
        perform="dashboard:view"
        yes={() => (
          <Link
            to="/admin/dashboard"
            className={classes.link}
            component={RouterLink}
            color="inherit"
            onClick={clickCallBack}
          >
            <ListItem
              button
              selected={location.pathname === '/admin/dashboard'}
              className={classNames(
                'menu__item',
                'menu__item--dashboard',
                location.pathname === '/admin/dashboard' && 'menu__item--active'
              )}
            >
              <ListItemIcon>
                <img src={DashboardIcon} alt="Dashboard Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="reports:view"
        yes={() => (
          <>
            <ListItem button onClick={toggleReports}>
              <ListItemIcon>
                <img src={ReportsIcon} alt="Reports Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {toggleReportsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={toggleReportsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.subList}>
                <Can
                  role={currentUser.role_name}
                  perform="allocation:view"
                  yes={() => (
                    <Link
                      to="/admin/allocation"
                      className={classes.link}
                      component={RouterLink}
                      color="inherit"
                      onClick={clickCallBack}
                    >
                      <ListItem
                        button
                        selected={location.pathname === '/admin/allocation'}
                        className={classNames(
                          'menu__item',
                          'menu__item--allocation',
                          location.pathname === '/admin/allocation' && 'menu__item--active'
                        )}
                      >
                        <ListItemIcon className={classes.fontAwesomeIcon}>
                          <img src={AllocationIcon} alt="Allocation Icon" className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText primary="Truepers Allocation" />
                      </ListItem>
                    </Link>
                  )}
                />
                <Can
                  role={currentUser.role_name}
                  perform="planned-hours:view"
                  yes={() => (
                    <Link
                      to="/admin/planned-hours"
                      className={classes.link}
                      component={RouterLink}
                      color="inherit"
                      onClick={clickCallBack}
                    >
                      <ListItem
                        button
                        selected={location.pathname === '/admin/planned-hours'}
                        className={classNames(
                          'menu__item',
                          'menu__item--plannedHours',
                          location.pathname === '/admin/planned-hours' && 'menu__item--active'
                        )}
                      >
                        <ListItemIcon className={classes.fontAwesomeIcon}>
                          <img src={PlannedHoursIcon} alt="Hour Status Icon" className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText primary="Hour Status Per Month" />
                      </ListItem>
                    </Link>
                  )}
                />
                <Can
                  role={currentUser.role_name}
                  perform="resources-location:view"
                  yes={() => (
                    <Link
                      to="/admin/resources-location"
                      className={classes.link}
                      component={RouterLink}
                      color="inherit"
                      onClick={clickCallBack}
                    >
                      <ListItem
                        button
                        selected={location.pathname === '/admin/resources-location'}
                        className={classNames(
                          'menu__item',
                          'menu__item--resources-location',
                          location.pathname === '/admin/resources-location' && 'menu__item--active'
                        )}
                      >
                        <ListItemIcon className={classes.fontAwesomeIcon}>
                          <img src={ResourceLocationIcon} alt="Truepers Location Icon" className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText primary="Truepers Location" />
                      </ListItem>
                    </Link>
                  )}
                />
                <Can
                  role={currentUser.role_name}
                  perform="resource-attrition:view"
                  yes={() => (
                    <Link
                      to="/admin/resources-attrition"
                      className={classes.link}
                      component={RouterLink}
                      color="inherit"
                      onClick={clickCallBack}
                    >
                      <ListItem
                        button
                        selected={location.pathname === '/admin/resource-attrition'}
                        className={classNames(
                          'menu__item',
                          'menu__item--resources-attrition',
                          location.pathname === '/admin/resources-attrition' && 'menu__item--active'
                        )}
                      >
                        <ListItemIcon className={classes.fontAwesomeIcon}>
                          <img src={ResourceAttritionIcon} alt="Truepers Attrition Icon" className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText primary="Truepers Attrition" />
                      </ListItem>
                    </Link>
                  )}
                />
              </List>
            </Collapse>
          </>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="project-status:view"
        yes={() => (
          <>
            <ListItem button onClick={toggleProjectStatus}>
              <ListItemIcon>
                <img src={ProjectStatusIcon} alt="Project Status Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Project Status" />
              {projectStatusOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={projectStatusOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.subList}>
                <Link
                  to="/admin/project-status"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/project-status'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--projectStatus',
                      location.pathname === '/admin/project-status' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img src={StatusAndPerformanceIcon} alt="Project Status Icon" className={classes.menuIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Status Details" />
                  </ListItem>
                </Link>
                <Link
                  to="/admin/project-status/history"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/project-status/history'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--projectStatusHistory',
                      location.pathname === '/admin/project-status/history' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img src={HistoryIcon} alt="Project History Icon" className={classes.menuIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Status History Details" />
                  </ListItem>
                </Link>
                <Link
                  to="/admin/project-status/new"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/project-status/new'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--projectStatusMetrics',
                      location.pathname === '/admin/project-status/new' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img src={NewReportIcon} alt="New Project Status Icon" className={classes.menuIcon} />
                    </ListItemIcon>
                    <ListItemText primary="New Status Report" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="resource-performance:view"
        yes={() => (
          <>
            <ListItem button onClick={toggleResourcePerformance}>
              <ListItemIcon className={classes.fontAwesomeIcon}>
                <img src={ResourcePerformanceIcon} alt="Truepers Performance Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Truepers Performance" />
              {resourcePerformanceOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={resourcePerformanceOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.subList}>
                <Link
                  to="/admin/resource-performance"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/resource-performance'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--resourcePerformance',
                      location.pathname === '/admin/resource-performance' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img src={StatusAndPerformanceIcon} alt="Performance Details Icon" className={classes.menuIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Performance Details" />
                  </ListItem>
                </Link>
                <Link
                  to="/admin/resource-performance/history"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/resource-performance/history'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--resourcePerformanceHistory',
                      location.pathname === '/admin/resource-performance/history' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img src={HistoryIcon} alt="Performance History Details Icon" className={classes.menuIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Performance History Details" />
                  </ListItem>
                </Link>
                <Link
                  to="/admin/resource-performance/new"
                  className={classes.link}
                  component={RouterLink}
                  color="inherit"
                  onClick={clickCallBack}
                >
                  <ListItem
                    button
                    selected={location.pathname === '/admin/resource-performance/new'}
                    className={classNames(
                      classes.nested,
                      'menu__item',
                      'menu__item--resourcePerformanceNew',
                      location.pathname === '/admin/resource-performance/new' && 'menu__item--active'
                    )}
                  >
                    <ListItemIcon className={classes.fontAwesomeIcon}>
                      <img
                        src={NewReportIcon}
                        alt="New Resource Performance Report Icon"
                        className={classes.menuIcon}
                      />
                    </ListItemIcon>
                    <ListItemText primary="New Performance Report" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </>
        )}
        no={() => null}
      />

      <Divider />

      <Can
        role={currentUser.role_name}
        perform="users:view"
        yes={() => (
          <>
            <ListSubheader>List Views</ListSubheader>
            <Link
              to="/admin/users"
              className={classes.link}
              component={RouterLink}
              color="inherit"
              onClick={clickCallBack}
            >
              <ListItem
                button
                selected={location.pathname === '/admin/users'}
                className={classNames(
                  'menu__item',
                  'menu__item--users',
                  location.pathname === '/admin/users' && 'menu__item--active'
                )}
              >
                <ListItemIcon className={classes.fontAwesomeIcon}>
                  <img src={UsersIcons} alt="User Access List Icon" className={classes.menuIcon} />
                </ListItemIcon>
                <ListItemText primary="User Access List" />
              </ListItem>
            </Link>
          </>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="resources:view"
        yes={() => (
          <Link
            to="/admin/resources"
            className={classes.link}
            component={RouterLink}
            color="inherit"
            onClick={clickCallBack}
          >
            <ListItem
              button
              selected={location.pathname === '/admin/resources'}
              className={classNames(
                'menu__item',
                'menu__item--resources',
                location.pathname === '/admin/resources' && 'menu__item--active'
              )}
            >
              <ListItemIcon className={classes.fontAwesomeIcon}>
                <img src={ResourcesIcon} alt="Truepers List Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Truepers List" />
            </ListItem>
          </Link>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="skills-and-seniority:view"
        yes={() => (
          <Link
            to="/admin/skills-and-seniority"
            className={classes.link}
            component={RouterLink}
            color="inherit"
            onClick={clickCallBack}
          >
            <ListItem
              button
              selected={location.pathname === '/admin/skills-and-seniority'}
              className={classNames(
                'menu__item',
                'menu__item--skillsAndSeniority',
                location.pathname === '/admin/skills-and-seniority' && 'menu__item--active'
              )}
            >
              <ListItemIcon className={classes.fontAwesomeIcon}>
                <img src={SkillsAndSeniorityIcon} alt="Skills and Seniority List Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Skills and Seniority List" />
            </ListItem>
          </Link>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="projects:view"
        yes={() => (
          <Link
            to="/admin/projects"
            className={classes.link}
            component={RouterLink}
            color="inherit"
            onClick={clickCallBack}
          >
            <ListItem
              button
              selected={location.pathname === '/admin/projects'}
              className={classNames(
                'menu__item',
                'menu__item--projects',
                location.pathname === '/admin/projects' && 'menu__item--active'
              )}
            >
              <ListItemIcon>
                <img src={ProjectsIcon} alt="Project List Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Project List" />
            </ListItem>
          </Link>
        )}
        no={() => null}
      />

      <Can
        role={currentUser.role_name}
        perform="clients:view"
        yes={() => (
          <Link
            to="/admin/clients"
            className={classes.link}
            component={RouterLink}
            color="inherit"
            onClick={clickCallBack}
          >
            <ListItem
              button
              selected={location.pathname === '/admin/clients'}
              className={classNames(
                'menu__item',
                'menu__item--clients',
                location.pathname === '/admin/clients' && 'menu__item--active'
              )}
            >
              <ListItemIcon className={classes.fontAwesomeIcon}>
                <img src={ClientsIcon} alt="Client List Icon" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary="Client Lists" />
            </ListItem>
          </Link>
        )}
        no={() => null}
      />

      <Divider />

      <Can
        role={currentUser.role_name}
        perform="audits:view"
        yes={() => (
          <>
            <ListSubheader>System</ListSubheader>

            <Link
              to="/admin/audits"
              className={classes.link}
              component={RouterLink}
              color="inherit"
              onClick={clickCallBack}
            >
              <ListItem
                button
                selected={location.pathname === '/admin/audits'}
                className={classNames(
                  'menu__item',
                  'menu__item--audits',
                  location.pathname === '/admin/audits' && 'menu__item--active'
                )}
              >
                <ListItemIcon className={classes.fontAwesomeIcon}>
                  <FontAwesomeIcon icon={faFileSignature} size="lg" />
                </ListItemIcon>
                <ListItemText primary="Audits" />
              </ListItem>
            </Link>
          </>
        )}
        no={() => null}
      />
    </List>
  )
}

const mapDispatchToProps = (dispatch) => {
  const signinBinds = bindActionCreators(signinActions, dispatch)

  return {
    rehydrateCurrentUser: signinBinds.rehydrateCurrentUser,
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeftMenu)))
