import {
  FETCH_SKILL_SENIORITY_BEGIN,
  FETCH_SKILL_SENIORITY_SUCCESS,
  FETCH_SKILL_SENIORITY_FAILURE,
} from '../../actions/skillSeniorityActions'

import initialState from './initialState'

export default function skillSeniorityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SKILL_SENIORITY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_SKILL_SENIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.skillSeniorities,
      }

    case FETCH_SKILL_SENIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }

    default:
      return state
  }
}
