import apiService from '../services/api'

export const FETCH_ROLES_BEGIN = 'FETCH_ROLES_BEGIN'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'

export const fetchRolesBegin = () => ({
  type: FETCH_ROLES_BEGIN,
})

export const fetchRolesSuccess = roles => ({
  type: FETCH_ROLES_SUCCESS,
  payload: { roles },
})

export const fetchRolesFailure = error => ({
  type: FETCH_ROLES_FAILURE,
  payload: { error },
})

export function fetchRoles() {
  return dispatch => {
    dispatch(fetchRolesBegin())
    return apiService
      .get(null, 'roles')
      .then(res => {
        dispatch(fetchRolesSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchRolesFailure(error)))
  }
}
