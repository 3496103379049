import { errorNotification, successNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_RESOURCE_PERFORMANCE_BEGIN = 'FETCH_RESOURCE_PERFORMANCE_BEGIN'
export const FETCH_RESOURCE_PERFORMANCE_SUCCESS = 'FETCH_RESOURCE_PERFORMANCE_SUCCESS'
export const FETCH_RESOURCE_PERFORMANCE_FAILURE = 'FETCH_RESOURCE_PERFORMANCE_FAILURE'
export const CREATE_RESOURCE_PERFORMANCE_BEGIN = 'CREATE_RESOURCE_PERFORMANCE_BEGIN'
export const CREATE_RESOURCE_PERFORMANCE_SUCCESS = 'CREATE_RESOURCE_PERFORMANCE_SUCCESS'
export const CREATE_RESOURCE_PERFORMANCE_FAILURE = 'CREATE_RESOURCE_PERFORMANCE_FAILURE'

export const fetchResourcePerformanceBegin = () => ({
  type: FETCH_RESOURCE_PERFORMANCE_BEGIN,
})

export const fetchResourcePerformanceSuccess = (resourcePerformance) => ({
  type: FETCH_RESOURCE_PERFORMANCE_SUCCESS,
  payload: { resourcePerformance },
})

export const fetchResourcePerformanceFailure = (error) => ({
  type: FETCH_RESOURCE_PERFORMANCE_FAILURE,
  payload: { error },
})

export function fetchResourcePerformance(options = {}) {
  return (dispatch) => {
    dispatch(fetchResourcePerformanceBegin())
    return apiService
      .get(null, 'resources/metrics', options)
      .then((res) => {
        dispatch(fetchResourcePerformanceSuccess(res.data))
        return res.data
      })
      .catch((error) => {
        dispatch(errorNotification('There was an error retrieving resource metrics.'))
        dispatch(fetchResourcePerformanceFailure(error))
      })
  }
}
export const createResourcePerformanceBegin = () => ({
  type: CREATE_RESOURCE_PERFORMANCE_BEGIN,
})

export const createResourcePerformanceSuccess = (resourcePerformance) => ({
  type: CREATE_RESOURCE_PERFORMANCE_SUCCESS,
  payload: { resourcePerformance },
})

export const createResourcePerformanceFailure = (error) => ({
  type: CREATE_RESOURCE_PERFORMANCE_FAILURE,

  payload: { error },
})

export function createResourcePerformance(formData, resource) {
  return (dispatch) => {
    dispatch(createResourcePerformanceBegin())
    return apiService
      .create(formData, `resources/${resource.id}/metrics`)
      .then((res) => {
        dispatch(createResourcePerformanceSuccess(res.data))
        dispatch(successNotification(`Report submitted!`))
        return res
      })
      .catch((error) => {
        dispatch(createResourcePerformanceFailure(error))
        dispatch(errorNotification(`There was a problem saving this report!`))
        console.error(error)
      })
  }
}
