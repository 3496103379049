import Cookies from 'universal-cookie'

export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

const cookies = new Cookies()

function setLoginError(error) {
  return {
    type: SET_LOGIN_ERROR,
    error,
  }
}

function clearLoginError() {
  return {
    type: CLEAR_LOGIN_ERROR,
  }
}

function checkLoginErrors() {
  return dispatch => {
    const error = cookies.get('error')

    if (error) {
      cookies.remove('error')
      dispatch(setLoginError(error))
    }
  }
}

const errorsActions = {
  checkLoginErrors,
  clearLoginError,
}

export default errorsActions
