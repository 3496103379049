import _ from 'lodash'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import IngressIcon from '../../assets/icons/resource_join.svg'
import EgressIcon from '../../assets/icons/resource_leave.svg'

const styles = (theme) => ({
  icon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  rotate: {
    transform: 'rotate(45180deg)',
  },
  sliceContainer: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-around',
    textTransform: 'capitalize',
    width: '100%',
  },
})

//TODO: DRY this
const ResourceAttritionTooltipComponent = ({ classes, slice }) => {
  const pointSuffix = (id) => (id === 'rate' ? '%' : 'hs')

  const exitData = slice.points.find((s) => s.id.includes('Exits'))
  const rateData = slice.points.find((s) => s.id.includes('rate'))

  if (exitData) {
    if (exitData.data.rate && !rateData) {
      const rate = {
        ...exitData,
        id: 'rate.0',
        serieId: 'rate',
        serieColor: '#37474f',
        ...(exitData.data
          ? {
              data: {
                ...exitData.data,
                total: exitData.data.rate,
                yFormatted: exitData.data.rate,
              },
            }
          : {}),
      }

      slice.points.push(rate)
    }
  }

  return (
    <div
      key={slice.x}
      style={{
        background: '#fff',
        padding: '9px 12px',
        border: '1px solid #ccc',
        position: 'absolute',
        top: -75,
        left: -75,
        width: 130,
        textAlign: 'center',
      }}
    >
      <Typography variant="h6">{slice.points[0].data.month}</Typography>
      {slice.points.map((point) => (
        <div
          key={point.id}
          style={{
            padding: '3px 0',
          }}
        >
          {point.serieId === 'rate' ? (
            <Typography variant="subtitle2">
              <span
                className={classes.sliceContainer}
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  color: point.serieColor,
                  textTransform: 'capitalize',
                  width: '100%',
                }}
              >
                <span style={{ paddingRight: 8 }}>{point.serieId}</span>
                <span>{point.data.yFormatted}</span>
                <span>{pointSuffix(point.serieId)}</span>
              </span>
            </Typography>
          ) : (
            <Typography>
              <span className={classes.sliceContainer} style={{ color: point.serieColor }}>
                <span>
                  {point.serieId.toLowerCase() === 'hires' ? (
                    <img src={IngressIcon} className={classNames(classes.icon, classes.rotate)} alt="Hires" />
                  ) : (
                    <img src={EgressIcon} className={classes.icon} alt="Exit" />
                  )}
                </span>
                <span>{point.serieId}</span>
                <span>{point.data.y}</span>
              </span>
            </Typography>
          )}
        </div>
      ))}
    </div>
  )
}

const PlannedHoursTooltip = React.memo(ResourceAttritionTooltipComponent, (prev, next) =>
  _.isEqual(prev.slice.points, next.slice.points)
)

export default withStyles(styles)(PlannedHoursTooltip)
