import _ from 'lodash'
import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  anchor: {
    color: '#fff',
    textDecoration: 'none',
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
})

const exportHeaders = [
  {
    name: 'Project Name',
    download: true,
  },
  {
    name: 'Client Name',
    download: true,
  },
  {
    name: 'Project Type',
    download: true,
  },
  {
    name: 'Project Manager',
    download: true,
  },
  {
    name: 'Status',
    download: true,
  },
]

const Projects = React.memo(
  ({ projects, actionButtons = null, options, title, hideFilters = {} }) => {
    let currentClientName

    let clientNames = projects.map((item) => (item.client.name ? item.client.name : '-'))
    clientNames = [...new Set(clientNames)]

    const opts = {
      ...options,
      filterType: false,
      selectableRows: 'none',
      pagination: true,
      print: false,
      tableBodyHeight: 'calc(100vh - 220px)',
      viewColumns: true,
      customSort: (data, colIndex, order) => {
        let aValue, bValue

        // TODO: Move this to utils
        const sortedData = data.sort((a, b) => {
          aValue = a.data[colIndex] && a.data[colIndex].name ? a.data[colIndex].name : a.data[colIndex]
          bValue = b.data[colIndex] && b.data[colIndex].name ? b.data[colIndex].name : b.data[colIndex]

          return typeof aValue === 'boolean' ? aValue - bValue : (aValue || '').localeCompare(bValue)
        })

        return order === 'asc' || order === 'none' ? sortedData : sortedData.reverse()
      },
      downloadOptions: {
        filename: `projects-${moment().utc().format()}.csv`,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataToExport = data.map((d, index) => ({
          index,
          data: [
            d.data[1] || '',
            d.data[2] && d.data[2].name ? d.data[2].name : '',
            d.data[3] || '',
            d.data[4] && d.data[4].name ? d.data[4].name : '',
            d.data[5] || '',
          ],
        }))

        return buildHead(exportHeaders) + buildBody(dataToExport)
      },
    }

    const columns = [
      {
        name: 'id',
        options: {
          display: false,
          filter: false,
          viewColumns: false,
        },
      },
      {
        label: 'Project name',
        name: 'name',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Client Name',
        name: 'client',
        options: {
          filter: true,
          sort: true,
          customFilterListOptions: {
            render: (selectedClient) => `Client: ${selectedClient}`,
          },
          filterOptions: {
            names: clientNames,
            logic(client, filterValues) {
              currentClientName = client.name ? client.name : client

              return currentClientName !== filterValues[0]
            },
          },
          customBodyRender: (value) => (value ? <Link to={`/admin/clients/${value.id}`}>{value.name}</Link> : '-'),
        },
      },
      {
        label: 'Project Type',
        name: 'projectType',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
      {
        label: 'Project Manager',
        name: 'projectManager',
        options: {
          filter: true,
          customBodyRender: (value) => (value ? value.name : '-'),
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          filter: hideFilters.status ? false : true,
          sort: true,
          customBodyRender: (value) => (value ? value : '-'),
        },
      },
    ]

    if (actionButtons) {
      columns.push({
        label: 'Actions',
        name: 'action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // Show action buttons only for active projects
            // if (!tableMeta.rowData[5] || tableMeta.rowData[5].toLowerCase() !== 'active') {
            //   return null;
            // }

            const projectId = tableMeta.rowData[0] ? tableMeta.rowData[0] : ''
            return actionButtons(projectId)
          },
        },
      })
    }

    return <MUIDataTable title={title} data={projects} columns={columns} options={opts} className="projects__chart" />
  },
  (prev, next) => _.isEqual(prev.projects, next.projects)
)

export default withStyles(styles)(Projects)
