import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Box, Typography } from '@material-ui/core'

import Text from './Metrics/MetricView/Text'
import grey from '@material-ui/core/colors/grey'

import utils from '../utils'
import { OVERALL_METRIC_DEFINITION } from '../constants'

const styles = (theme) => ({
  dateFilter: {
    alignItems: 'flex-start',
    marginBottom: 10,
    marginLeft: 'auto',
  },
  textPadding: {
    padding: '8px 16px',
  },
  lineClamper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  createdByCell: {
    display: 'flex',
    flexFlow: 'row',
    width: 250,
  },
  overall: {
    width: 20,
    padding: '8px 16px',
  },
  metricCell: {
    borderRight: '2px solid #fff',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 85,
    },
  },
  projectMetricAt: {
    color: grey['400'],
  },
})

// TODO: Project Status and Resource Performance can be DRYed, they're pretty much the same thing except the columns

const ResourcePerformance = ({ classes, resourcePerformance, widget = false, title }) => {
  const columns = [
    {
      label: 'Resource',
      name: 'resource_name',
      options: {
        filter: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const row = resourcePerformance[tableMeta.rowIndex]

          const overallValue = utils.calculateOverallScore(row.overall)
          const color = OVERALL_METRIC_DEFINITION.enum[overallValue]

          const createdAt = moment(row.createdAt).utc().format('MMM Do')

          return (
            <div className={classes.createdByCell}>
              <div style={{ backgroundColor: color }} className={classes.overall}>
                &nbsp;
              </div>
              <div className={classes.textPadding}>
                <Typography variant="body2">{row.resource_name}</Typography>
                <Typography variant="caption" className={classes.projectMetricAt}>
                  {row.createdBy} - {createdAt}
                </Typography>
              </div>
            </div>
          )
        },
      },
    },
    {
      label: 'English Level',
      name: 'english_level',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.english_level }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Performance',
      name: 'performance',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.performance }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Professionalism',
      name: 'professionalism',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.professionalism }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Flexibility',
      name: 'flexibility',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.flexibility }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Client Feedback	',
      name: 'client_feedback',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.client_feedback }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Motivation',
      name: 'motivation',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const row = resourcePerformance[dataIndex]
          return (
            <div style={{ backgroundColor: row.motivation }} className={classes.metricCell}>
              &nbsp;
            </div>
          )
        },
      },
    },
    {
      label: 'Comments and Risks',
      name: 'comments_and_risks',
      options: {
        filter: false,
        sort: false,
        display: !widget,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          return <Text value={value} className={classNames(classes.textPadding, classes.lineClamper)} />
        },
      },
    },
  ]

  const options = {
    filterType: false,
    selectableRows: 'none',
    pagination: false,
    print: false,
    tableBodyHeight: widget ? 'auto' : 'calc(100vh - 160px)',
    viewColumns: false,
    download: false,
    search: !widget,
    filter: !widget,
    elevation: widget ? 0 : 4,
  }

  const defaultTheme = createMuiTheme()

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          tableRoot: {
            height: 1, // this allows us to grow the td height 1o 100%
          },
        },
        MuiTableCell: {
          body: {
            padding: 0,
            height: '100%',
          },
          head: {
            display: widget ? 'none' : 'table-cell',
            textAlign: 'center',
          },
        },
        MUIDataTableBodyCell: {
          stackedCommon: {
            textAlign: 'center',
            [defaultTheme.breakpoints.up('md')]: {
              textAlign: 'left',
            },
          },
          root: {
            height: '100%',
          },
        },
        MUIDataTableHeadCell: {
          root: {
            '&:nth-child(8) > div': {
              [defaultTheme.breakpoints.up('md')]: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 1,
                '-webkit-box-orient': 'vertical',
              },
            },
          },
        },
      },
    })

  if (!resourcePerformance.length) {
    return (
      <Box p={2} color="text.primary" border={1} borderColor="grey.500">
        <Typography variant="body1">There are no reports requiring action plans</Typography>
      </Box>
    )
  }

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={!widget ? title : null}
        data={resourcePerformance}
        columns={columns}
        options={options}
        className="resourcePerformance__chart"
      />
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(ResourcePerformance)
