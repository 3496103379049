import {
  ENQUEUE_NOTIFICATION,
  NOTIFICATION_SENT,
} from '../../actions/notificationActions'

import initialState from './initialState'

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        items: [
          ...state.items,
          {
            ...action.payload.notification,
          },
        ],
      }

    case NOTIFICATION_SENT:
      return {
        ...state,
        items: state.items.filter(item => item.key !== action.payload),
      }

    default:
      return state
  }
}
