import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from '../reducers'
import getInitialState from './initialState'

const createStoreWithMiddleware = compose(
  applyMiddleware(thunk),
  typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)

//TODO: Use redux toolkit, rewrite in slices

const configureStore = (initialState) => {
  return createStore(reducer, initialState, createStoreWithMiddleware)
}

export default configureStore(getInitialState())
