import { errorNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_SKILL_SENIORITY_BEGIN = 'FETCH_SKILL_SENIORITY_BEGIN'
export const FETCH_SKILL_SENIORITY_SUCCESS = 'FETCH_SKILL_SENIORITY_SUCCESS'
export const FETCH_SKILL_SENIORITY_FAILURE = 'FETCH_SKILL_SENIORITY_FAILURE'

export const fetchSkillSeniorityBegin = () => ({
  type: FETCH_SKILL_SENIORITY_BEGIN,
})

export const fetchSkillSenioritySuccess = skillSeniorities => ({
  type: FETCH_SKILL_SENIORITY_SUCCESS,
  payload: { skillSeniorities },
})

export const fetchSkillSeniorityFailure = error => ({
  type: FETCH_SKILL_SENIORITY_FAILURE,
  payload: { error },
})

export function fetchSkillSeniority() {
  return dispatch => {
    dispatch(fetchSkillSeniorityBegin())
    return apiService
      .get(null, 'skill-seniority')
      .then(res => {
        dispatch(fetchSkillSenioritySuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(fetchSkillSeniorityFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving the Skill-Seniorities, please try again later.'
          )
        )
      })
  }
}
