import { errorNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_CONTRACT_TYPES_BEGIN = 'FETCH_CONTRACT_TYPES_BEGIN'
export const FETCH_CONTRACT_TYPES_SUCCESS = 'FETCH_CONTRACT_TYPES_SUCCESS'
export const FETCH_CONTRACT_TYPES_FAILURE = 'FETCH_CONTRACT_TYPES_FAILURE'

export const fetchContractTypesBegin = () => ({
  type: FETCH_CONTRACT_TYPES_BEGIN,
})

export const fetchContractTypesSuccess = contractTypes => ({
  type: FETCH_CONTRACT_TYPES_SUCCESS,
  payload: { contractTypes },
})

export const fetchContractTypesFailure = error => ({
  type: FETCH_CONTRACT_TYPES_FAILURE,
  payload: { error },
})

export function fetchContractTypes() {
  return dispatch => {
    dispatch(fetchContractTypesBegin())
    return apiService
      .get(null, 'contract-types')
      .then(res => {
        dispatch(fetchContractTypesSuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(fetchContractTypesFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving the contract types, please try again later.'
          )
        )
      })
  }
}
