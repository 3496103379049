import React, { useState, useEffect } from 'react'
import { LinearProgress, Paper } from '@material-ui/core'
import classNames from 'classnames'

import apiService from '../../services/api'

import ResourcePerformance from '../../components/ResourcePerformance'

const ResourcePerformanceHistory = () => {
  const [resourcePerformanceHistory, setResourcePerformanceHistory] = useState(null)
  const [resourcePerformanceHistoryLoading, setResourcePerformanceHistoryLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const resourcePerformanceHistory = await apiService.get(null, 'resource-performance/history', {})

      const data = resourcePerformanceHistory.data.sort((a, b) => b.createdAt - a.createdAt)

      setResourcePerformanceHistory(data)
      setResourcePerformanceHistoryLoading(false)
    }

    setResourcePerformanceHistoryLoading(true)
    fetchData()

    document.title = 'Truepers Performance History Details'
  }, [])

  return (
    <Paper
      className={classNames(
        'resourcePerformanceHistory',
        resourcePerformanceHistoryLoading && 'resourcePerformanceHistory--loading'
      )}
    >
      {resourcePerformanceHistoryLoading ? (
        <LinearProgress />
      ) : (
        <ResourcePerformance
          resourcePerformance={resourcePerformanceHistory}
          title="Truepers Performance History Details"
        />
      )}
    </Paper>
  )
}

export default ResourcePerformanceHistory
