export const schema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: { type: 'string', title: 'Skill Name', default: '', required: true },
  },
}

export const uiSchema = {}

export const initialState = {
  name: '',
}
