import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import * as skillsActions from '../../actions/skillActions'
import * as seniorityActions from '../../actions/seniorityActions'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  LinearProgress,
  IconButton,
} from '@material-ui/core'

import Can from '../../components/Common/Can'
import SkillAndSeniority from '../../components/SkillAndSeniority'

const styles = theme => ({
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  anchor: {
    color: '#fff',
    lineHeight: 1,
    textDecoration: 'none',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  centerActionButtons: {
    textAlign: 'right',
    marginRight: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
})

const List = ({ classes, selectedItemType }) => {
  const dispatch = useDispatch()

  const {
    collection: collectionType,
    action: collectionAction,
    label: collectionLabel,
  } = selectedItemType

  const items = useSelector(state => state[collectionType].items)
  const loading = useSelector(state => state[collectionType].loading)

  const currentUser = useSelector(state => state.currentUser)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [itemName, setItemName] = useState(null)
  const [itemId, setItemId] = useState(null)

  const itemsActions = {
    skills: {
      fetchAction: skillsActions.fetchSkills,
      removeAction: skillsActions.removeSkill,
    },
    seniority: {
      fetchAction: seniorityActions.fetchSeniority,
      removeAction: seniorityActions.removeSeniority,
    },
  }

  useEffect(() => {
    document.title = collectionLabel
  }, [collectionLabel])

  useEffect(() => {
    dispatch(collectionAction())
  }, [collectionAction, dispatch])

  const handleDelete = itemId => {
    const filteredItem = items.find(item => item.id === itemId)

    setDialogOpen(true)
    setItemId(itemId)
    setItemName(filteredItem.name)
  }

  const actionButtons = itemId => (
    <div
      className={classNames(
        classes.centerActionButtons,
        'skillsAndSeniority__actionButtons'
      )}
    >
      <Can
        role={currentUser.role_name}
        perform={`${collectionType}:edit`}
        yes={() => (
          <Link
            to={`/admin/${collectionType}/${itemId}`}
            className="skillsAndSeniority__edit"
          >
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Link>
        )}
        no={() => null}
      />
      <Can
        role={currentUser.role_name}
        perform={`${collectionType}:delete`}
        yes={() => (
          <IconButton
            className='skillsAndSeniority__delete'
            aria-label="Delete"
            onClick={() => handleDelete(itemId)}
          >
            <DeleteIcon />
          </IconButton>
        )}
        no={() => null}
      />
    </div>
  )

  const closeDialog = () => {
    setDialogOpen(false)
    setItemName(null)
    setItemId(null)
  }

  const handleAgree = async () => {
    dispatch(itemsActions[collectionType].removeAction(itemId, itemName))
    dispatch(collectionAction())
    closeDialog()
  }

  return (
    <div>
      {!loading && items.length ? (
        <SkillAndSeniority
          items={items}
          actionButtons={actionButtons}
          title={collectionLabel}
        />
      ) : (
        <LinearProgress className={classes.progress} />
      )}

      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="skillsAndSeniority__dialog"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="skillsAndSeniority__dialogTitle"
        >
          Delete {collectionType}?
        </DialogTitle>
        <DialogContent className="skillsAndSeniority__dialogContent">
          <DialogContentText>
            Are you sure you want to delete {collectionType}: "{itemName}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="clients__dialogActions">
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleAgree} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Can
        role={currentUser.role_name}
        perform={`${collectionType}:create`}
        yes={() => (
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, 'skillsAndSeniority__add')}
          >
            <Link
              to={`/admin/${collectionType}/new/`}
              className={classes.anchor}
            >
              <AddIcon />
            </Link>
          </Fab>
        )}
        no={() => null}
      />
    </div>
  )
}

export default withStyles(styles)(withSnackbar(List))
