import apiService from '../services/api'

export const FETCH_AUDITS_BEGIN = 'FETCH_AUDITS_BEGIN'
export const FETCH_AUDITS_SUCCESS = 'FETCH_AUDITS_SUCCESS'
export const FETCH_AUDITS_FAILURE = 'FETCH_AUDITS_FAILURE'

export const fetchAuditsBegin = () => ({
  type: FETCH_AUDITS_BEGIN,
})

export const fetchAuditsSuccess = audits => ({
  type: FETCH_AUDITS_SUCCESS,
  payload: { audits },
})

export const fetchAuditsFailure = error => ({
  type: FETCH_AUDITS_FAILURE,
  payload: { error },
})

export function fetchAudits() {
  return dispatch => {
    dispatch(fetchAuditsBegin())
    return apiService
      .get(null, 'audits')
      .then(res => {
        dispatch(fetchAuditsSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchAuditsFailure(error)))
  }
}
