import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Logo from '../../components/Common/Logo'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://truelogicsoftware.com/">
        Truelogic Software
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  toolbarActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    justifyContent: 'flex-end',
  },
  fulllink: {
    width: '100%',
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      'Users Module',
      'Resources Module',
      'Resource Performance',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: ['20 users included', 'Projects', 'Project Status', 'Reports'],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      'Audits',
      'Planned & Logged Hours',
      'Email notifications',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
]
const footers = [
  {
    title: 'Lorem',
    description: ['Lorem', 'Ipsum', 'Dolor', 'Sit'],
  },
  {
    title: 'Ipsum',
    description: ['Lorem', 'Ipsum', 'Dolor', 'Sit', 'amet', 'consectetur'],
  },
  {
    title: 'Dolor',
    description: ['Lorem', 'Ipsum', 'Dolor', 'Sit', 'amet', 'consectetur'],
  },
  {
    title: 'Sit',
    description: ['Lorem', 'Ipsum', 'Dolor', 'Sit'],
  },
]

export default function Pricing() {
  const classes = useStyles()

  return (
    <div className="pricing">
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classNames(classes.appBar, 'pricing__header')}
      >
        <Toolbar className={classes.toolbar}>
          <Logo className={classes.toolbarTitle} />

          <div className={classes.toolbarActions}>
            <nav>
              <Link
                variant="button"
                color="textPrimary"
                href="#"
                className={classes.link}
              >
                Features
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#"
                className={classes.link}
              >
                Enterprise
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#"
                className={classes.link}
              >
                Support
              </Link>
            </nav>
            <Link
              className={classes.link}
              component={RouterLink}
              to="/admin"
              color="inherit"
            >
              <Button color="primary" variant="outlined">
                Login
              </Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>

      {/* Hero unit */}
      <Container
        maxWidth="sm"
        component="main"
        className={classNames(classes.heroContent, 'pricing__content')}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt
          facilis distinctio, pariatur earum, iste odit labore quasi suscipit
          hic, nulla totam.
        </Typography>
      </Container>

      {/* End hero unit */}
      <Container maxWidth="md" component="main" className="pricing__hero">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Link
                    className={classes.fulllink}
                    component={RouterLink}
                    to="/admin"
                    color="inherit"
                  >
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                    >
                      {tier.buttonText}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        className={classNames(classes.footer, 'pricing__footer')}
      >
        <Grid container spacing={4} justify="space-evenly">
          {footers.map(footer => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map(item => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
  )
}
