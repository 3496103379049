import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Form from '@rjsf/material-ui'
import VisibilityIcon from '@material-ui/icons/Visibility'

import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  LinearProgress,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core'

import { fetchClient } from '../../actions/clientActions'

import BreadcrumbGen from '../../components/Common/BreadcrumbGen'

import { schema, uiSchema } from '../../models/clients'
import widgets from '../../models/customWidgets'

import { updateClient } from '../../actions/clientActions'

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

const ClientsForm = ({ classes, match }) => {
  const clientId = match.params.id

  const dispatch = useDispatch()

  const [formData, setFormData] = useState(null)

  const clientData = useSelector((state) => {
    let client = state.clients.items.filter((client) => client.id === clientId)

    if (!client || !client[0]) {
      return null
    }

    client = client[0]

    return {
      ...client,
      projectManager: client.projectManager && client.projectManager.name ? client.projectManager.name : '',
    }
  })

  const [cancel, setCancel] = useState(false)
  const clientLoading = useSelector((state) => state.clients.loading)

  // Fetch client data
  useEffect(() => {
    if (clientId && !clientData) {
      dispatch(fetchClient(clientId))
    }
  }, [clientId, clientData, dispatch])

  // Update form with client data
  useEffect(() => {
    if (clientData && !formData) {
      setFormData(clientData)
    }
  }, [clientData, formData])

  // Submit updated client data
  const handleSubmit = ({ formData }) => {
    dispatch(updateClient(match.params.id, formData))
    //TODO: Update allocation with updated resource data
  }

  const handleChange = ({ formData }) => {
    const updatedForm = {
      ...formData,
      latitude:
        formData.location && formData.location.latlng && formData.location.latlng.lat
          ? formData.location.latlng.lat
          : '',
      longitude:
        formData.location && formData.location.latlng && formData.location.latlng.lat
          ? formData.location.latlng.lng
          : '',
      location:
        formData.location && formData.location.name && formData.location.country
          ? `${formData.location.name}, ${formData.location.country}`
          : '',
    }

    setFormData(updatedForm)
  }

  const resourcePaths = [
    { name: 'Home', route: '/' },
    { name: 'Clients', route: '/admin/clients' },
    {
      name: `${match && match.params && match.params.id ? 'Edit client: ' + match.params.id : 'Create'}`,
    },
  ]

  return (
    <>
      <BreadcrumbGen paths={resourcePaths} />

      <Paper className={classNames(classes.paper, 'clients__form')}>
        <Typography variant="h5" gutterBottom className={classNames(classes.title, 'clients__formTitle')}>
          {clientId ? 'Edit' : 'Create'} Client
        </Typography>
        {cancel && <Redirect to="/admin/clients" />}
        {!clientLoading && formData ? (
          <Form
            onChange={handleChange}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            onSubmit={handleSubmit}
            widgets={widgets}
          >
            {formData.projects && formData.projects.length ? (
              <Card className={classes.card}>
                <CardContent>
                  <Typography>Projects</Typography>
                  <List dense>
                    {formData.projects.map((item) => (
                      <ListItem key={item.id}>
                        <ListItemText primary={item.name} />
                        <ListItemSecondaryAction>
                          <Link
                            className={classes.link}
                            component={RouterLink}
                            to={`/admin/projects/${item.id}`}
                            color="inherit"
                          >
                            <IconButton edge="end" aria-label="view">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ) : (
              ''
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="default"
                className={classes.cancelButton}
                onClick={() => setCancel(true)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Form>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </>
  )
}

export default withRouter(withStyles(styles)(ClientsForm))
