import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { withSnackbar } from 'notistack'

import Can from './Can'

import signinActions from '../../actions/signinActions'
import { errorNotification } from '../../actions/notificationActions'

const PrivateRoute = ({
  component: Component,
  rehydrateCurrentUser,
  currentUser,
  action,
  ...rest
}) => {
  useEffect(() => {
    rehydrateCurrentUser()
  }, [rehydrateCurrentUser])

  let storedCurrentUser = currentUser && currentUser.token ? currentUser : null

  const dispatch = useDispatch()

  // TODO: Fix this, first render currentUser is not saved in Redux so this causes a loop
  // Check localstorage if not in redux yet
  if (!currentUser || !currentUser.token) {
    storedCurrentUser = JSON.parse(localStorage.getItem('currentUser'))
  }

  const role =
    storedCurrentUser && storedCurrentUser.role_name
      ? storedCurrentUser.role_name.toLowerCase()
      : ''

  return (
    <Can
      role={role}
      perform={action}
      yes={() => {
        return <Route {...rest} render={props => <Component {...props} />} />
      }}
      no={() => {
        dispatch(errorNotification(`You are not authorized to view this route`))
        return <Redirect to={{ pathname: '/' }} />
      }}
    />
  )
}

const mapDispatchToProps = dispatch => {
  const signinBinds = bindActionCreators(signinActions, dispatch)

  return {
    rehydrateCurrentUser: signinBinds.rehydrateCurrentUser,
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(PrivateRoute))
