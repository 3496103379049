import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Users from '../../components/Users'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  LinearProgress,
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import { fetchUsers, removeUser } from '../../actions/userActions'

import Can from '../../components/Common/Can'

const UsersContainer = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.currentUser)

  const users = useSelector((state) => state.users.items)
  const usersLoading = useSelector((state) => state.users.loading)

  const [userName, setUserName] = useState(null)
  const [userId, setUserId] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    document.title = 'Users'
  }, [])

  useEffect(() => {
    if (!users.length) {
      dispatch(fetchUsers())
    }
  }, [dispatch, users.length])

  const closeDialog = () => {
    setDialogOpen(false)
    setUserName(null)
    setUserId(null)
  }

  const handleAgree = async () => {
    dispatch(removeUser(userId))
    closeDialog()
  }

  const actionButtons = (userId) => (
    <div className="users__actionButtons">
      <Can
        role={currentUser.role_name}
        perform="users:edit"
        yes={() => (
          <Link to={`/admin/users/${userId}`} className="users__edit">
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Link>
        )}
        no={() => '-'}
      />
    </div>
  )

  return (
    <Paper className={classNames('users', usersLoading && 'users--loading')}>
      {usersLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Users data={users} actionButtons={actionButtons} />

          <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="users__dialog"
          >
            <DialogTitle id="alert-dialog-title" className="users__dialogTitle">
              Delete user?
            </DialogTitle>
            <DialogContent className="users__dialogContent">
              <DialogContentText>Are you sure you want to delete user: &quot;{userName}&quot;?</DialogContentText>
            </DialogContent>
            <DialogActions className="users__dialogAactions">
              <Button onClick={closeDialog}>Cancel</Button>
              <Button onClick={handleAgree} color="secondary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Paper>
  )
}

export default UsersContainer
