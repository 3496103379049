import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Form from '@rjsf/material-ui'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'

import Can from '../../components/Common/Can'
import Resources from '../../components/Resources'

import utils from '../../utils'

import { fetchResources } from '../../actions/resourceActions'
import { fetchMetricTypes } from '../../actions/metricTypesActions'

import widgets from '../../models/customWidgets'

import { createResourcePerformance } from '../../actions/resourcePerformanceActions'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  centerActionButtons: {
    textAlign: 'center',
  },
  formActions: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: 10,
  },
  container: {
    height: 'calc(100% - 65px)',
  },
})

const ResourcePerformanceReport = ({ classes }) => {
  const dispatch = useDispatch()

  let resources = useSelector((state) => state.resources.items)
  const resourcesLoading = useSelector((state) => state.resources.loading)

  resources = resources.map((r) => {
    if (r.location) {
      const locationParts = r.location.split(',')

      r.city = locationParts[0].trim()
      r.country = locationParts[1].trim()
    }

    return r
  })

  const metricTypes = useSelector((state) =>
    state.metricTypes.items.filter((mt) => mt.reference === 'resource').sort(utils.sortProjectMetrics)
  )
  const metricTypesLoading = useSelector((state) => state.metricTypes.loading)

  const [resource, setResource] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const currentUser = useSelector((state) => state.currentUser)

  const schemaItems = metricTypes.reduce((acc, def) => {
    acc[def.id] = {
      type: 'string',
      title: def.name,
      default: '',
      order: def.order,
    }

    if (def.enum && def.enum.length) {
      acc[def.id].enum = def.enum
    }

    return acc
  }, {})

  const schema = {
    type: 'object',
    required: Object.keys(schemaItems),
    properties: {
      ...schemaItems,
    },
  }

  const uiSchema = metricTypes.reduce((acc, def) => {
    if (def.enum) {
      acc[def.id] = {
        'ui:widget': 'customButtonGroup',
      }
    }

    if (def.type === 'text') {
      acc[def.id] = {
        'ui:widget': 'customTextfield',
      }
    }

    return acc
  }, {})

  const orderedMetricTypes = metricTypes.sort((a, b) => {
    if (a.name < b.name) return 1
    if (a.name > b.name) return -1
    return 0
  })

  const orderedFields = orderedMetricTypes.reduce((acc, def) => {
    if (def.enum) {
      acc.unshift(def.id)
    } else {
      acc.push(def.id)
    }

    return acc
  }, [])

  uiSchema['ui:order'] = orderedFields

  useEffect(() => {
    dispatch(fetchResources())
    dispatch(fetchMetricTypes('resource'))
  }, [dispatch])

  const openDialog = (resourceId) => {
    const filteredResource = resources.find((resource) => resource.id === resourceId)

    setDialogOpen(true)
    setResource(filteredResource)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = async ({ formData }) => {
    dispatch(createResourcePerformance(formData, resource))
    handleClose()
  }

  const transformErrors = (errors) => {
    return errors.map((error) => {
      error.message = 'This field is required'
      return error
    })
  }

  return (
    <div className={classes.container}>
      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="resource-status-dialog-title">
        <DialogTitle id="resource-status-dialog-title" className={classes.dialogTitle}>
          Status report for: {resource.fullName}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            noHtml5Validate
            showErrorList={false}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            widgets={widgets}
            transformErrors={transformErrors}
          >
            <Box mt={2} className={classes.formActions}>
              <Button variant="contained" color="default" className={classes.cancelButton} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Form>
        </DialogContent>
      </Dialog>

      {!metricTypesLoading && !resourcesLoading ? (
        <Resources
          title="Create Trueper Performance Report"
          data={resources}
          options={{
            pagination: false,
          }}
          actionButtons={(resourceId) => (
            <div className={classes.centerActionButtons}>
              <Can
                role={currentUser.role_name}
                perform="resource-performance:create"
                yes={() => (
                  <IconButton
                    className={classes.button}
                    aria-label="Create Trueper Performance Report"
                    onClick={() => {
                      openDialog(resourceId)
                    }}
                  >
                    <NoteAddIcon />
                  </IconButton>
                )}
                no={() => '-'}
              />
            </div>
          )}
        />
      ) : (
        <LinearProgress className={classes.progress} />
      )}
    </div>
  )
}

export default withStyles(styles)(withSnackbar(ResourcePerformanceReport))
