import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'
import { ResponsivePie } from '@nivo/pie'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import { fetchResources } from '../../actions/resourceActions'

const styles = (theme) => ({
  chart: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
  },
})

const HeadCount = ({ classes }) => {
  const dispatch = useDispatch()

  const resources = useSelector((state) => state.resources.items)

  const [members, setMembers] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    const members = []
    const activeUsers = resources.filter((r) => r.active === true)

    const grouped = _.groupBy(activeUsers, 'type')

    Object.keys(grouped).map((groupName) =>
      members.push({
        id: groupName,
        name: groupName,
        value: grouped[groupName].length,
      })
    )

    setTotalCount(activeUsers.length)
    setMembers(members)
  }, [resources])

  useEffect(() => {
    dispatch(fetchResources())
  }, [dispatch])

  return !members.length ? (
    <LinearProgress />
  ) : (
    <>
      <Typography variant="h6" className="headCount__title">
        Active Truepers
      </Typography>
      <Typography variant="body2" className="headCount__subtitle">
        Total Truepers: {totalCount}
      </Typography>
      <div className={classNames(classes.chart, 'headCount__chart')}>
        <ResponsivePie
          data={members}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.75}
          padAngle={0.7}
          cornerRadius={3}
          colors={['#0088FE', '#00C49F', '#FFBB28', '#FF8042']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          radialLabelsSkipAngle={0}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: 'color' }}
          slicesLabelsSkipAngle={10}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          radialLabel={(e) => `${e.id} (${e.value})`}
          slicesLabelsTextColor="#fff"
          tooltip={(e) => (
            <div>
              <Typography variant="subtitle2">
                <span style={{ fontWeight: 'bold', color: e.color }}>{e.name}</span>: {e.value}
              </Typography>
            </div>
          )}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              translateX: 40,
              translateY: 40,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </>
  )
}

export default withStyles(styles)(withSnackbar(HeadCount))
