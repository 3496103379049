/**
 * This file is responsible for checking if the role can perform the requested action
 * Source: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */

import rules from '../../rbac-rules'

export const check = (role, action, data) => {
  role = role ? role.toLowerCase() : ''

  const permissions = rules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }

    return permissionCondition(data)
  }
  return false
}

const Can = props =>
  check(props.role, props.perform, props.data) ? props.yes() : props.no()

Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default Can
