import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Paper, Typography, LinearProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import Cluster from '@urbica/react-map-gl-cluster'
import MapGL, { Marker, NavigationControl, FullscreenControl, Popup } from '@urbica/react-map-gl'

import 'mapbox-gl/dist/mapbox-gl.css'
import '../../styles/ResourcesLocation.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { fetchResources } from '../../actions/resourceActions'

import ClusterMarker from '../../components/Common/ClusterMarker'

// Since environment variables are embdedded during build time, the token is no more secret than just storing it here
// We avoid the overhead of configuring a system to send env vars to react at build time
const accessToken = 'pk.eyJ1IjoibWdyYW5hZGEiLCJhIjoiY2szdGJzcW90MDE4eTNrcG1jN3NoMnc3eiJ9.YdgZAoR-Zm12FfKfyp-oMw'

const styles = (theme) => ({
  fullHeight: {
    height: 'calc(100vh - 100px)',
    padding: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filters: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'right',
  },
  filterTitle: {
    paddingRight: theme.spacing(2),
  },
  chartTitle: {
    [theme.breakpoints.down('sm')]: {
      'font-size': 16,
    },
  },
  chartContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 30px)',
    overflow: 'hidden',
  },
  fullScreen: {
    marginBottom: theme.spacing(),
  },
  popup: {
    maxWidth: 'max-content !important',
    maxHeight: 300,
  },
  popupList: {
    maxHeight: 300,
    paddingLeft: 20,
    paddingBottom: 0,
    marginBottom: 0,
    marginTop: 0,
    overflowY: 'auto',
  },
})

const ResourcesLocation = ({ classes }) => {
  const dispatch = useDispatch()
  const resources = useSelector((state) => state.resources.items)
  const loading = useSelector((state) => state.resources.loading)

  const clusterRef = useRef(null)

  const [mapViewport, setMapViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 1,
  })

  const [clickedMarker, setClickedMarker] = useState({
    lat: 0,
    long: 0,
    resources: [],
  })
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    if (!resources.length) {
      dispatch(fetchResources())
    }
  }, [dispatch, resources])

  const handleClusterClick = (longitude, latitude, clusterId) => {
    const supercluster = clusterRef.current.getCluster()
    const leaves = supercluster.getLeaves(clusterId, Infinity)

    const resources = leaves.map((item) => ({
      name: item.properties.props.dataResource.fullName || 'N/A',
      location: item.properties.props.dataResource.location || 'N/A',
    }))

    setClickedMarker({
      lat: latitude,
      long: longitude,
      resources,
    })

    setPopupOpen(true)
  }

  const handlePopupClose = () => {
    setPopupOpen(false)
    setClickedMarker({
      lat: 0,
      long: 0,
      resources: [],
    })
  }

  const handleMarkerClick = (item) => {
    setClickedMarker({
      lat: item.latitude,
      long: item.longitude,
      resources: [
        {
          name: item.fullName || 'N/A',
          location: item.location || 'N/A',
        },
      ],
    })
    setPopupOpen(true)
  }

  return (
    <Paper className={classNames(classes.fullHeight, 'resourcesLocation')}>
      <div className={classNames(classes.titleRow, 'resourcesLocation__titleRow')}>
        <Typography
          color="textPrimary"
          variant="h6"
          className={classNames(classes.chartTitle, 'resourcesLocation__title')}
        >
          Truepers Location
        </Typography>
      </div>
      <div
        className={classNames(
          classes.chartContainer,
          'resourcesLocation__container',
          (loading || !resources.length) && 'resourcesLocation__container--loading'
        )}
      >
        {loading || !resources.length ? (
          <LinearProgress className={classes.progress} />
        ) : (
          <MapGL
            {...mapViewport}
            style={{ width: '100%', height: '100%' }}
            accessToken={accessToken}
            onViewportChange={(viewport) => setMapViewport(viewport)}
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            dragRotate={false}
            pitchWithRotate={false}
          >
            <Cluster
              radius={40}
              extent={512}
              nodeSize={64}
              component={(cluster) => <ClusterMarker onClick={handleClusterClick} {...cluster} />}
              ref={clusterRef}
            >
              {resources.map((item) =>
                item.longitude && item.latitude ? (
                  <Marker
                    key={item.accountId}
                    longitude={item.longitude}
                    latitude={item.latitude}
                    offsetLeft={0}
                    offsetTop={0}
                    dataResource={item}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      size="lg"
                      color="#00c6c0"
                      onClick={(e) => handleMarkerClick(item)}
                    />
                  </Marker>
                ) : null
              )}
            </Cluster>
            <FullscreenControl className={classes.fullScreen} position="top-left" />
            <NavigationControl showZoom position="top-left" />
            {popupOpen && (
              <Popup
                className={classes.popup}
                longitude={clickedMarker.long}
                latitude={clickedMarker.lat}
                onClose={handlePopupClose}
                closeButton
                closeOnClick
              >
                <ul className={classes.popupList}>
                  {clickedMarker.resources.map((item) => (
                    <li key={`${item.name}`}>
                      {item.name} - {item.location}
                    </li>
                  ))}
                </ul>
              </Popup>
            )}
          </MapGL>
        )}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(ResourcesLocation)
