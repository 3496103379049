/**
 * This file contains the Roles and what each role is allowed to do
 *
 * NOTE: If this file is updated, make sure to update the server file too
 *
 * - static permissions are arrays of names that gives access to a requested action
 * - dynamic permissions are objects with the action as the key and a function to perform the custom action to give access
 */
export default {
  pm: {
    static: [
      'allocation:view',
      'benched:view',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:create',
      'project-status:view',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:create',
      'resource-metric:view',
      'resource-performance:create',
      'resource-performance:view',
      'resources-location:view',
      'resources:edit',
      'resources:view',
      'seniority:edit',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:edit',
      'skills:view',
      'users:view',
    ],
  },
  resource: {
    static: ['dashboard:view'],
  },
  admin: {
    static: [
      'allocation:view',
      'audits:view',
      'benched:view',
      'clients:create',
      'clients:delete',
      'clients:edit',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:create',
      'project-status:view',
      'projects:assign-client',
      'projects:assign-pm',
      'projects:create',
      'projects:delete',
      'projects:edit',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:create',
      'resource-metric:view',
      'resource-performance:create',
      'resource-performance:view',
      'resources-location:view',
      'resources:edit',
      'resources:view',
      'seniority:create',
      'seniority:delete',
      'seniority:edit',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:create',
      'skills:delete',
      'skills:edit',
      'skills:view',
      'users:create',
      'users:edit',
      'users:view',
    ],
  },
  'account manager': {
    static: [
      'allocation:view',
      'benched:view',
      'clients:create',
      'clients:delete',
      'clients:edit',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:view',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:view',
      'resource-performance:view',
      'resources-location:view',
      'resources:view',
    ],
  },
  'sales manager': {
    static: [
      'allocation:view',
      'benched:view',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:view',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:view',
      'resource-performance:view',
      'resources-location:view',
      'resources:view',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:view',
      'users:view',
    ],
  },
  pmo: {
    static: [
      'allocation:view',
      'audits:view',
      'benched:view',
      'clients:create',
      'clients:delete',
      'clients:edit',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:create',
      'project-status:view',
      'projects:assign-client',
      'projects:assign-pm',
      'projects:create',
      'projects:delete',
      'projects:edit',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:create',
      'resource-metric:view',
      'resource-performance:create',
      'resource-performance:view',
      'resources-location:view',
      'resources:edit',
      'resources:view',
      'seniority:create',
      'seniority:delete',
      'seniority:edit',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:create',
      'skills:delete',
      'skills:edit',
      'skills:view',
      'users:create',
      'users:edit',
      'users:view',
    ],
  },
  'program manager': {
    static: [
      'allocation:view',
      'audits:view',
      'benched:view',
      'clients:create',
      'clients:delete',
      'clients:edit',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:create',
      'project-status:view',
      'projects:assign-client',
      'projects:assign-pm',
      'projects:create',
      'projects:delete',
      'projects:edit',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:create',
      'resource-metric:view',
      'resource-performance:create',
      'resource-performance:view',
      'resources-location:view',
      'resources:edit',
      'resources:view',
      'seniority:create',
      'seniority:delete',
      'seniority:edit',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:create',
      'skills:delete',
      'skills:edit',
      'skills:view',
      'users:create',
      'users:edit',
      'users:view',
    ],
  },
  qa: {
    static: [
      'allocation:view',
      'audits:view',
      'benched:view',
      'clients:view',
      'dashboard:view',
      'planned-hours:view',
      'project-status:view',
      'projects:view',
      'reports:view',
      'resource-attrition:view',
      'resource-metric:view',
      'resource-performance:view',
      'resources-location:view',
      'resources:view',
      'seniority:view',
      'skills-and-seniority:view',
      'skills:view',
      'users:view',
    ],
  },
}
