export const schema = {
  type: 'object',
  required: ['name', 'key'],
  properties: {
    name: { type: 'string', title: 'Project Name', default: '', readOnly: true },
    client: { type: 'string', title: 'Client Name', default: '', readOnly: true },
    status: { type: 'string', title: 'Status', default: false, readOnly: true },
    projectType: { type: 'string', title: 'Project Type', default: '', readOnly: true },
    projectManager: { type: 'string', title: 'Project Manager', default: '', readOnly: true },
    key: { type: 'string', title: 'Jira Key', default: '' },
  },
}

export const uiSchema = {}

export const initialState = {
  name: '',
  client: '',
  status: '',
  projectType: '',
  projectManager: '',
  key: '',
}
