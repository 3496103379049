import { errorNotification, successNotification } from './notificationActions'
import apiService from '../services/api'

export const FETCH_PROJECTS_BEGIN = 'FETCH_PROJECTS_BEGIN'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'

export const REMOVE_PROJECT_BEGIN = 'REMOVE_PROJECT_BEGIN'
export const REMOVE_PROJECT_SUCCESS = 'REMOVE_PROJECT_SUCCESS'
export const REMOVE_PROJECT_FAILURE = 'REMOVE_PROJECT_FAILURE'

export const fetchProjectsBegin = () => ({
  type: FETCH_PROJECTS_BEGIN,
})

export const fetchProjectsSuccess = projects => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload: { projects },
})

export const fetchProjectsFailure = error => ({
  type: FETCH_PROJECTS_FAILURE,
  payload: { error },
})

export function fetchProjects() {
  return dispatch => {
    dispatch(fetchProjectsBegin())
    return apiService
      .get(null, 'projects')
      .then(res => {
        dispatch(fetchProjectsSuccess(res.data))
        return res.data
      })
      .catch(error => {
        dispatch(fetchProjectsFailure(error))
        dispatch(
          errorNotification(
            'There was an error retrieving projects, please try again later.'
          )
        )
      })
  }
}

export const removeProjectBegin = () => ({
  type: REMOVE_PROJECT_BEGIN,
})

export const removeProjectSuccess = projectId => ({
  type: REMOVE_PROJECT_SUCCESS,
  payload: projectId,
})

export const removeProjectFailure = error => ({
  type: REMOVE_PROJECT_FAILURE,
  payload: { error },
})

export function removeProject(projectId, projectName) {
  return dispatch => {
    dispatch(removeProjectBegin())
    return apiService
      .remove(projectId, 'projects')
      .then(res => {
        dispatch(removeProjectSuccess(projectId))
        dispatch(successNotification(`Project: "${projectName}" deleted`))
        return res
      })
      .catch(error => {
        dispatch(removeProjectFailure(error))
        dispatch(
          errorNotification(
            `There was an error deleting project: "${projectName}"`
          )
        )
      })
  }
}
