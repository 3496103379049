import apiService from '../services/api'

export const FETCH_ALLOCATION_BEGIN = 'FETCH_ALLOCATION_BEGIN'
export const FETCH_ALLOCATION_SUCCESS = 'FETCH_ALLOCATION_SUCCESS'
export const FETCH_ALLOCATION_FAILURE = 'FETCH_ALLOCATION_FAILURE'

export const fetchAllocationBegin = () => ({
  type: FETCH_ALLOCATION_BEGIN,
})

export const fetchAllocationSuccess = allocation => ({
  type: FETCH_ALLOCATION_SUCCESS,
  payload: { allocation },
})

export const fetchAllocationFailure = error => ({
  type: FETCH_ALLOCATION_FAILURE,
  payload: { error },
})

export function fetchAllocation(options = null) {
  return dispatch => {
    dispatch(fetchAllocationBegin())
    return apiService
      .get(null, 'resources/allocation', options)
      .then(res => {
        dispatch(fetchAllocationSuccess(res.data))
        return res.data
      })
      .catch(error => dispatch(fetchAllocationFailure(error)))
  }
}
