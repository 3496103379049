import {
  FETCH_SKILLS_BEGIN,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAILURE,
  REMOVE_SKILL_BEGIN,
  REMOVE_SKILL_SUCCESS,
  REMOVE_SKILL_FAILURE,
} from '../../actions/skillActions'

import initialState from './initialState'

export default function skillsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SKILLS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.skills,
      }

    case FETCH_SKILLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }
    case REMOVE_SKILL_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case REMOVE_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(skills => skills.id !== action.payload),
      }

    case REMOVE_SKILL_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
