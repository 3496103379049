import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import ResourceAttritionSlice from './ResourceAttritionSlice'

const ResourceAttrition = ({ reportData, optionOverrides }) => {
  const customOptions = {
    margin: { top: 0, bottom: 100, left: 50, right: 32 },
    legends: [
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 50,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    pointLabelYOffset: 25,
    ...optionOverrides,
  }

  const maxValue = Math.max(...reportData.map((type) => type.data.map((val) => val.y || 0)).flat())

  const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: d.data.y ? xScale(d.data.x) : null,
            y: d.data.y ? yScale(d.data.y) : null,
          }))
        )}
        fill="none"
        stroke={color}
        style={{
          strokeDasharray: '12, 6',
          strokeWidth: 2,
        }}
      />
    ))
  }

  return (
    <ResponsiveLine
      data={reportData}
      yScale={{
        type: 'linear',
        stacked: false,
        min: 0,
        max: Math.max(maxValue + (10 * maxValue) / 100, 0),
      }}
      xScale={{ type: 'point' }}
      enableSlices="x"
      animate={false}
      curve="natural"
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        legend: 'Month',
        legendOffset: 40,
        legendPosition: 'middle',
        tickRotation: -35,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      enablePointLabel={false}
      pointLabel={(e) => `${e.x}: ${e.y}`}
      enableGridX={false}
      enableCrosshair={true}
      lineWidth={3}
      colors={['#F47560', '#61CDBB']}
      pointSize={12}
      pointColor="#ffffff"
      pointBorderColor={{ from: 'serieColor' }}
      pointBorderWidth={2}
      layers={['grid', 'markers', 'axes', 'areas', 'crosshair', DashedLine, 'points', 'slices', 'mesh', 'legends']}
      sliceTooltip={({ slice }) => <ResourceAttritionSlice slice={slice} />}
      {...customOptions}
    />
  )
}

export default ResourceAttrition
